import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Segmented, Table } from "antd";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import fixtures from "../../../../services/fixtures";
import { useEdges } from "reactflow";
import { CustomSegmented } from "../Styled";

const EventResult = ({ eventData }) => {
  const [apiData, setApiData] = useState([]);
  const [selectedSection, setSelectedSection] = useState("All");

  let componentRef = useRef(null);

  const medalsOrder = ["Gold", "Silver", "Bronze", "Bronze"];
  let certificateCounter = 1;

  const getEventResult = async (eventId) => {
    try {
      const res = await fixtures.getEventResult(eventId);
      setApiData(res?.data);
      // console.log("res:", res?.data)
    } catch (err) {}
  };

  const generateResult = async (evenId) => {
    try {
      const res = await fixtures?.generateEventResult(evenId);
      getEventResult(evenId);
    } catch (err) {}
  };

  // console.log('result:', apiData);

  let data = [];
  for (let item of apiData || []) {
    // console.log('item:', item);
    const { rank = {}, _id, category, institue } = item;
    // console.log('rank:', rank)
    // console.log('category',category)
    const medals = Object.keys(rank)
      .slice(0, medalsOrder.length)
      .map((rankKey, index) => {
        const player = rank[rankKey];
        // console.log('player',player);


        return {
          medal: medalsOrder[index],
          player: player,
          certificate: `CERT${certificateCounter++}`,
          club: institue[rankKey]?.name,
        };
      });
    // if (
    //   !institue?.first &&
    //   !institue?.second &&
    //   !institue?.third &&
    //   !institue?.fourth
    // )
      data.push({
        key: _id,
        category: `${category?.category?.type}-${category?.category?.name} ${
          category?.category?.gender === "both"
            ? "- ( Boys and Girls )"
            : category?.category?.gender === "male"
            ? "- ( Boys )"
            : "- ( Girls )"
        }`,
        gender: category?.category?.gender,
        medals,
      });      
  }
  // console.log('data', data);


  const expandedData = data
    .map((entry) =>
      entry.medals.map((medalEntry, index) => ({
        key: `${entry.key}-${index}`,
        category: entry.category,
        ...medalEntry,
        categoryRowSpan: index === 0 ? entry.medals.length : 0,
        gender: entry.gender,
      }))
    )
    .flat();

    // console.log('expandedData', expandedData);

  const filteredData = expandedData.filter((entry) => {
    if (selectedSection === "All") {
      return true;
    }
    if (selectedSection === "Boys") {
      return entry?.gender === "male";
    }
    if (selectedSection === "Girls") {
      return entry?.gender === "female";
    }
    return false;
  });

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (value, row) => ({
        children: value,
        props: {
          rowSpan: row.categoryRowSpan,
        },
      }),
    },
    {
      title: "Medal",
      dataIndex: "medal",
      key: "medal",
    },
    {
      title: "Player Name",
      dataIndex: "player",
      key: "player",
    },
    {
      title: "Certificate No.",
      dataIndex: "certificate",
      key: "certificate",
    },
    {
      title: "Club/School",
      dataIndex: "club",
      key: "club",
    },
  ];

  const printTable = () => {
    window.print();
  };

  const handleAfterPrint = React.useCallback(() => {}, []);

  const handleBeforePrint = React.useCallback(() => {
    return Promise.resolve();
  }, []);

  const printFn = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "AwesomeFileName",
    onAfterPrint: handleAfterPrint,
    onBeforePrint: handleBeforePrint,
  });

  const handleSegmentChange = (value) => {
    setSelectedSection(value);
  };

  useEffect(() => {
    if (eventData?._id) getEventResult(eventData?._id);
  }, [eventData]);

  return (
    <>
      <Row>
        <Col span={24} className="d-flex justify-content-between">
          <CustomSegmented>
            <Segmented
              options={["All", "Boys", "Girls"]}
              value={selectedSection}
              onChange={handleSegmentChange}
            />
          </CustomSegmented>
          <div>
            <button
              className="button-29"
              style={{ marginRight: "5px" }}
              onClick={() => generateResult(eventData?._id)}
            >
              Generate Result
            </button>
            <button className="button-29" onClick={printFn}>
              Print Result
            </button>
          </div>
        </Col>
        <Col span={24} style={{ padding: "20px", height: "70vh" }}>
          <div style={{ height: "100%", overflowY: "auto" }}>
            <Table
              ref={componentRef}
              columns={columns}
              dataSource={filteredData}
              bordered
              pagination={false}
              title={() => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src="/mpka.jpg"
                    alt="Logo 1"
                    style={{ width: "60px", height: "60px" }}
                  />
                  <div style={{ textAlign: "center" }}>
                    <h2
                      style={{
                        fontWeight: "bold",
                        fontSize: "24px",
                        margin: 0,
                      }}
                    >
                      {eventData?.name}
                    </h2>

                    <p style={{ fontSize: "16px", margin: "5px 0" }}>
                      {moment(eventData?.startDate).format("Do MMMM, YYYY")}{" "}
                      {" - "}
                      {moment(eventData?.endData).format("Do MMMM, YYYY")}
                    </p>

                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        margin: "5px 0",
                      }}
                    >
                      Result Sheet
                    </p>
                    <p style={{ fontSize: "16px", margin: 0 }}>
                      {/* Date: {moment().format("Do MMMM, YYYY")} */}
                      Date :{" "}
                      {moment(eventData?.endData).format("Do MMMM, YYYY")}
                    </p>
                  </div>

                  <img
                    src="/bdka.jpg"
                    alt="Logo 2"
                    style={{ width: "60px", height: "60px" }}
                  />
                </div>
              )}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EventResult;
