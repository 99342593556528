import { apiDelete, apiGet, apiPost, apiPut } from "../utils/axios";
import { domainUrl } from "../utils/domainUrl";

export default {
    getStateHeads: () => apiGet(domainUrl('/api/v1/user/get-statehead')),
    getMyStudents: (eventId, size=0, currentPage=1, sortingColumn='', sortingOrder='',query="",filterStatus="") => apiGet(domainUrl(`/api/v1/user/get-my-student/${eventId}?size=${size}&page=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}&filterGender=${filterStatus}`)),
    importStudents: () => apiPost(domainUrl('/api/v1/user/import-students')),
    getStateHeadById: (id) => apiGet(domainUrl(`/api/v1/user/${id}`)),
    getStateAdmins: (size=0, currentPage=1, sortingColumn='', sortingOrder='',query="") => apiGet(domainUrl(`/api/v1/user//get-stateadmin?size=${size}&currentPage=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}`)),
    getStateAdminById: (id) => apiGet(domainUrl(`/api/v1/user/${id}`)),
    createUsers: (payload) => apiPost(domainUrl(`/api/v1/user/create-users`),payload),
    updateUsers: (id,payload) => apiPut(domainUrl(`/api/v1/user/update/${id}`),payload),
    getDistricHeads:(size=0, currentPage=1, sortingColumn='', sortingOrder='',query="") => apiGet(domainUrl(`/api/v1/user/get-districthead?size=${size}&currentPage=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}`)),
    getDistricAdmins:(size=0, currentPage=1, sortingColumn='', sortingOrder='',query="") => apiGet(domainUrl(`/api/v1/user/get-districtadmin?size=${size}&currentPage=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}`)),
    getCoachs:(size=0, currentPage=1, sortingColumn='', sortingOrder='',query="") => apiGet(domainUrl(`/api/v1/user/get-coachs?size=${size}&page=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}`)),
    getStudents:(size=0, currentPage=1, sortingColumn='', sortingOrder='',query="", type="", gender="") => apiGet(domainUrl(`/api/v1/user/get-students?size=${size}&page=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}&type=${type}&searchFilter=${gender}`)),
    deleteUsers:(payload) => apiDelete(domainUrl(`/api/v1/user/delete`),payload),
    getModerators:(size=0, currentPage=1, sortingColumn='', sortingOrder='',query="") => apiGet(domainUrl(`/api/v1/user/get-moderator?size=${size}&currentPage=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}`)),
    getJudges :(size=0, currentPage=1, sortingColumn='', sortingOrder='',query="") => apiGet(domainUrl(`/api/v1/user/get-judges?size=${size}&currentPage=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}`)),
    getReferees :(size=0, currentPage=1, sortingColumn='', sortingOrder='',query="") => apiGet(domainUrl(`/api/v1/user/get-referees?size=${size}&currentPage=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}`)),
    createJudgeAndReferees :(payload) => apiPost(domainUrl(`/api/v1/user/create-judge`),payload),
    getJudgesAndRefereesById :(id) => apiGet(domainUrl(`/api/v1/user/get-referees/${id}`)),
    updateAnyJudgesOrReferees: (id,payload) => apiPut(domainUrl(`/api/v1/user/update-referees/${id}`),payload),
    getAnyUserDetailsById: (id) => apiGet(domainUrl(`/api/v1/user/${id}`)),
    getAllStates: () => apiGet(domainUrl(`/api/v1/user/select/state`)),
    getAllDistrict: (stateId) => apiGet(domainUrl(`/api/v1/user/select/district?stateId=${stateId}`)),
}