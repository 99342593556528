import {
  EditOutlined,
  MoreOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Segmented,
  Select,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams,useHistory } from "react-router-dom";
import users from "../../../../services/users";
import { capitalizeFirstLetter, notify } from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";

const { Option } = Select;

const StateUsersDetails = () => {
  const [isEditUser, setIsEditUser] = useState(false);
  const [tab, setTab] = useState("Personal Info");
  const [form] = Form.useForm();
  const { id } = useParams();
  const [user, setUser] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();


  const genders = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const getStateHeadById = async (id) => {
    try {
      let res = await users.getStateHeadById(id);
      setUser(res?.data?.user);
    } catch (err) {}
  };

  useEffect(() => {
    getStateHeadById(id);
  }, [id]);



  function onChange(checked) {

  }

  const card = [
    {
      headerImg:
        "https://www.wkf.net/imagenes/home/campeonatos/campeonato20230222170418.jpg",
      title: "State Junior Championships 2024 ",
      Descriptions:
        "The 13th edition of the World Cadet, Junior & U21 Karate Championships will be held in MP(India) from October 9 to 13. This event stands as the pinnacle for young karatekas, representing the most massive tournament in the sport and a must-watch for enthusiasts worldwide.",
    },
    {
      headerImg:
        "https://www.wkf.net/imagenes/home/campeonatos/campeonato20230222170418.jpg",
      title: "State Senior Championships 2024 ",
      Descriptions:
        "The 18th edition of the World Cadet, Senior & U21 Karate Championships will be held in MP(India) from October 9 to 13. This event stands as the pinnacle for young karatekas, representing the most massive tournament in the sport and a must-watch for enthusiasts worldwide.",
    },
    {
      headerImg:
        "https://www.wkf.net/imagenes/home/campeonatos/campeonato20230222170418.jpg",
      title: "U21 Championships 2024",
      Descriptions:
        "The 18th edition of the World Cadet, Senior & U21 Karate Championships will be held in MP(India) from October 9 to 13. This event stands as the pinnacle for young karatekas, representing the most massive tournament in the sport and a must-watch for enthusiasts worldwide.",
    },
    {
      headerImg:
        "https://www.wkf.net/imagenes/home/campeonatos/campeonato20240228142115.jpg",
      title: "U19 Championships 2024",
      Descriptions:
        "The 18th edition of the World Cadet, Senior & U21 Karate Championships will be held in MP(India) from October 9 to 13. This event stands as the pinnacle for young karatekas, representing the most massive tournament in the sport and a must-watch for enthusiasts worldwide.",
    },
    {
      headerImg:
        "https://www.wkf.net/imagenes/home/campeonatos/campeonato20240228142115.jpg",
      title: "U15 Championships 2024",
      Descriptions:
        "The 18th edition of the World Cadet, Senior & U21 Karate Championships will be held in MP(India) from October 9 to 13. This event stands as the pinnacle for young karatekas, representing the most massive tournament in the sport and a must-watch for enthusiasts worldwide.",
    },
  ];

  const updateUser = async (values) => {

    const payload = {
      ...values,
      dateOfBirth: moment(values?.dateOfBirth).format("YYYY-MM-DD"),
    };
    try {
      let res = await users.updateUsers(id, payload);
      notify("success", "User Updated Successfully.");
      setIsEditUser(false);
      getStateHeadById(id);
    } catch (err) {
    
    }
  };
  const options =
    user?.role === "student" ? ["Personal Info", "Events"] : ["Personal Info"];

  // Function to populate the form fields with user data
  const populateFormFields = () => {
    form.setFieldsValue({
      firstName: user?.firstName,
      lastName: user?.lastName,
      dateOfBirth: user?.dateOfBirth ? moment(user?.dateOfBirth) : null,
      class: user?.class,
      weight: user?.weight,
      state: user?.state,
      district: user?.district,
      city: user?.city,
      pincode: user?.pincode,
      mobile: user?.mobile,
    });
  };

  const convertToTitleCase = (str) => {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (firstChar) => firstChar.toUpperCase());
  };

  // When edit mode is activated, populate the form fields
  useEffect(() => {
    if (isEditUser) {
      populateFormFields();
    }
  }, [isEditUser, user]);

  useEffect(() => {
    if (user)
      dispatch(
        setBreadcrumb({
          title: capitalizeFirstLetter(user?.role === "student" ? "Players" : user?.role),
          subtitle: capitalizeFirstLetter(user?.firstName + " " +user?.lastName),
          url: "",
        })
      );
  }, [user]);

  return (
    <div>
      <Card
        // className="card-profile-head"
        // bodyStyle={{ display: "block" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar
                  size={74}
                  shape="square"
                  src={
                    user?.profilePicture ? (
                      user?.profilePicture
                    ) : (
                      <div style={{ background: "gray"}}>
                        {user?.firstName?.[0]?.toUpperCase() || "-"}
                        {user?.lastName?.[0] ? user?.lastName?.[0]?.toUpperCase() : ""}
                      </div>
                    )
                  }
                />

                <div className="avatar-info" style={{ marginLeft: "10px" }}>
                  <h4 className="font-semibold m-0">
                 {capitalizeFirstLetter(user?.firstName +" "+ user?.lastName)}
                  </h4>
                  <p>{capitalizeFirstLetter(user?.role)}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Segmented
                options={options}
                onChange={(value) => {
                  setTab(value);
                }}
              />
            </Col>
          </Row>
        }
      >
        {tab === "Personal Info" && (
          <>
            <Form form={form} onFinish={updateUser}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <div
                    className="w-100"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h6>Summary</h6>
                    <div className="px-3">
                      {!isEditUser ? (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsEditUser(!isEditUser)}
                        >
                          <EditOutlined color="var(--text-primary)" size={20} />
                        </div>
                      ) : (
                        <div>
                          <Button
                            style={{
                              marginRight: "10px",
                            }}
                            type="primary"
                            onClick={() => setIsEditUser(!isEditUser)}
                            className="font-bold cursor-pointer mx-4"
                          >
                            Cancel
                          </Button>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="font-bold cursor-pointer mx-4"
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col span={24} className="m-2"></Col>
                <Col span={6}>
                  <h6 className="list-header text-m text-muted m-0">
                    First Name
                  </h6>
                  {isEditUser ? (
                    <Form.Item
                      style={{ marginBottom: 10 }}
                      className="username"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "firstname is required!",
                        },
                      ]}
                    >
                      <Input placeholder="Firstname" />
                    </Form.Item>
                  ) : (
                    <div className="font-semibold">{user?.firstName}</div>
                  )}
                </Col>
                <Col span={6}>
                  <h6 className="list-header text-m text-muted m-0">
                    Last Name
                  </h6>
                  {isEditUser ? (
                    <Form.Item
                      style={{ margin: 0 }}
                      className="username"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "lastname is required!",
                        },
                      ]}
                    >
                      <Input placeholder="Lastname" />
                    </Form.Item>
                  ) : (
                    <div className="font-semibold">{user?.lastName}</div>
                  )}
                </Col>

                <Col span={6}>
                  {/* <div className="subHeading">Email</div> */}
                  <h6 className="list-header text-m text-muted m-0">Email</h6>
                  {/* {isEditUser ? (
                    <Form.Item
                      style={{ margin: 0 }}
                      className="email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "email is required!",
                        },
                      ]}
                    >
                      <Input placeholder="email" />
                    </Form.Item> */}
                  {/* ) : ( */}
                  <div className="font-semibold">{user?.email}</div>
                  {/* )} */}
                </Col>

                <Col span={6}>
                  {/* <div className="subHeading">Member since</div> */}
                  <h6 className="list-header text-m text-muted m-0">
                    Member since
                  </h6>
                  <div className="font-semibold">
                    {moment(user?.createdAt).format("DD MMM YYYY")}
                  </div>
                </Col>
                {user?.role === "student" && (
                  <>
                    <Col span={6}>
                      <h6 className="list-header text-m text-muted m-0">
                        D.O.B
                      </h6>
                      {isEditUser ? (
                        <Form.Item
                          style={{ margin: 0 }}
                          className="dateOfBirth"
                          name="dateOfBirth"
                          rules={[
                            {
                              required: true,
                              message: "Date of birth is required!",
                            },
                          ]}
                        >
                          {/* <Input placeholder="Date of birth" /> */}
                          <DatePicker
                            format="DD/MM/YYYY"
                            onChange={(date, dateString) => {
                              const formattedDate =
                                moment(date).format("DD/MM/YYYY");
                              
                            }}
                          />
                        </Form.Item>
                      ) : (
                        <div className="font-semibold">
                          {" "}
                          {moment(user?.dateOfBirth).format("DD MMM YYYY")}
                        </div>
                      )}
                    </Col>

                    
                    <Col span={6}>
                      <h6 className="list-header text-m text-muted m-0">
                        Gender
                      </h6>
                      {isEditUser ? (
                        <Form.Item
                          style={{ margin: 0 }}
                          className="username"
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "gender is required!",
                            },
                          ]}
                        >
                             <Select
                    placeholder="Select gender"
                    // value={role}
                  >
                    {genders.map((gender) => (
                      <Select.Option key={gender.value} value={gender.value}>
                        {gender.label}
                      </Select.Option>
                    ))}
                  </Select>
                        </Form.Item>
                      ) : (
                        <div className="font-semibold"> {user?.gender ? user?.gender :"-"}</div>
                      )}
                    </Col>

                    <Col span={6}>
                      <h6 className="list-header text-m text-muted m-0">
                        Class
                      </h6>
                      {isEditUser ? (
                        <Form.Item
                          style={{ margin: 0 }}
                          className="username"
                          name="class"
                          rules={[
                            {
                              required: true,
                              message: "Class is required!",
                            },
                          ]}
                        >
                          <Input placeholder="Class" />
                        </Form.Item>
                      ) : (
                        <div className="font-semibold"> {user?.class}</div>
                      )}
                    </Col>

                    <Col span={6}>
                      <h6 className="list-header text-m text-muted m-0">
                        Weight
                      </h6>
                      {isEditUser ? (
                        <Form.Item
                          style={{ margin: 0 }}
                          className="username"
                          name="weight"
                          rules={[
                            {
                              required: true,
                              message: "Weight is required!",
                            },
                          ]}
                        >
                          <Input placeholder="Weight" />
                        </Form.Item>
                      ) : (
                        <div className="font-semibold"> {user?.weight} Kg</div>
                      )}
                    </Col>
                  </>
                )}
                <Col span={6}>
                  {/* <div className="subHeading">Email</div> */}
                  <h6 className="list-header text-m text-muted m-0">State</h6>
                  {isEditUser ? (
                    <Form.Item
                      style={{ margin: 0 }}
                      className="state"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "state is required!",
                        },
                      ]}
                    >
                      <Input placeholder="state" />
                    </Form.Item>
                  ) : (
                    <div className="font-semibold">{user?.state}</div>
                  )}
                </Col>
                <Col span={6}>
                  {/* <div className="subHeading">Email</div> */}
                  <h6 className="list-header text-m text-muted m-0">
                    District
                  </h6>
                  {isEditUser ? (
                    <Form.Item
                      style={{ margin: 0 }}
                      className="district"
                      name="district"
                      rules={[
                        {
                          required: true,
                          message: "district is required!",
                        },
                      ]}
                    >
                      <Input placeholder="district" />
                    </Form.Item>
                  ) : (
                    <div className="font-semibold">{user?.district}</div>
                  )}
                </Col>
                <Col span={6}>
                  {/* <div className="subHeading">Email</div> */}
                  <h6 className="list-header text-m text-muted m-0">City</h6>
                  {isEditUser ? (
                    <Form.Item
                      style={{ margin: 0 }}
                      className="city"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: "city is required!",
                        },
                      ]}
                    >
                      <Input placeholder="city" />
                    </Form.Item>
                  ) : (
                    <div className="font-semibold">{user?.city}</div>
                  )}
                </Col>
                <Col span={6}>
                  {/* <div className="subHeading">Email</div> */}
                  <h6 className="list-header text-m text-muted m-0">Pincode</h6>
                  {isEditUser ? (
                    <Form.Item
                      style={{ margin: 0 }}
                      className="pincode"
                      name="pincode"
                      rules={[
                        {
                          required: true,
                          message: "pincode is required!",
                        },
                      ]}
                    >
                      <Input placeholder="pincode" />
                    </Form.Item>
                  ) : (
                    <div className="font-semibold">{user?.pincode}</div>
                  )}
                </Col>
                <Col span={6}>
                  {/* <div className="subHeading">Email</div> */}
                  <h6 className="list-header text-m text-muted m-0">Mobile</h6>
                  {isEditUser ? (
                    <Form.Item
                      style={{ margin: 0 }}
                      className="mobile"
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: "mobile is required!",
                        },
                      ]}
                    >
                      <Input placeholder="mobile" />
                    </Form.Item>
                  ) : (
                    <div className="font-semibold">{user?.mobile}</div>
                  )}
                </Col>
                <Col span={24} className="m-3 text-center">
                {!isEditUser && (
                  <>
                    <Divider />

                    <Button
                      type="primary"
                      className="font-bold cursor-pointer mx-4 p-0"
                      onClick={() => history.goBack()}
                    >
                      Back
                    </Button>
                  </>
                )}
              </Col>
              </Row>
            </Form>
          </>
        )}
        {tab === "Events" && (
          <div style={{ background: "#f7f7f7", padding: "10px" }}>
            <Row style={{}}>
              <Col span={25} lg={16}>
                <h5 className="font-semibold">Your Events</h5>
              </Col>
            </Row>

            <Row
              gutter={[24, 0]}
              className="mt-3"
              style={{ marginTop: "30px" ,height:'60vh',overflow:'scroll'}}
              // style={{border:'1px solid red'}}
            >
              {card.map((card, index) => (
                <Col span={24} lg={8}  className="mb-24" key={index}>
                  <Card
                    bodyStyle={{ paddingTop: "14px" }}
                    bordered={false}
                    className="card-project-2 header-solid"
                    title={[
                      <Row gutter={[24, 24]} key={0}>
                        <Col>
                          <img
                            style={{
                              width: "74px",
                              height: "74px",
                              lineHeight: "74px",
                              borderRadius: "8px",
                              objectFit: "cover",
                            }}
                            src={card.headerImg}
                            alt="event image"
                          />
                        </Col>
                        <Col span={15}>
                          <h6 className="font-semibold mb-10">{card.title}</h6>
                          <Avatar.Group className="avatar-chips">
                            <Avatar size="small" />
                            <Avatar size="small" />
                            <Avatar size="small" />
                            <Avatar size="small" />
                          </Avatar.Group>
                        </Col>
                        <Col span={2} className="ml-auto pt-10">
                          <Link to="#">
                            <MoreOutlined className="text-muted" />
                          </Link>
                        </Col>
                      </Row>,
                    ]}
                    style={{}}
                  >
                    <p>{card.Descriptions}</p>
                    <hr />
                    <Row gutter={[24]}>
                      <Col>
                        <p className="font-semibold text-muted mb-0">
                          Participants
                        </p>
                        <h6 className="font-semibold text-md mb-0">5</h6>
                      </Col>
                      <Col className="ml-auto">
                        <p className="font-semibold text-muted mb-0">
                          Due date
                        </p>
                        <h6 className="font-semibold text-md mb-0">02.10.24</h6>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </Card>
    </div>
  );
};

export default StateUsersDetails;
