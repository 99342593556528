import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Avatar,
  Tree,
  Input,
  Button,
  Skeleton,
  Empty,
} from "antd";
import event from "../../../../services/event";
import moment from "moment";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce, notify, ShowTotal } from "../../../../utils/helper";
import KumiteScoreboard from "./category/KumitiScoreBoard";
import { Link } from "react-router-dom";
import { CustomTable } from "../Styled";
import fixtures from "../../../../services/fixtures";
import KataFinalResultDrawer from "./category/KataFinalResultDrawer";
import { useParams } from "react-router-dom";
import AddCategoriesModal from "./AddCategoriesModal";
import DeleteConfirionModal from "../../../modal/DeleteConfirionModal";
import Filter from "../../../../utils/Filter";

const EventCatagories = ({ eventId }) => {
  const [apiData, setApiData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState([""]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [count, setCount] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState([]);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState("");
  const [openKataResult, setOpenkataResult] = useState(false);
  const [openCategoriesModal, setOpenCategoriesModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [givenPassword, setGivenPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [filterStatus, setFilterStatus] = useState([]);
  const [filterTeam, setFilterTeam] = useState([]);
  const [filterType, setFilterType] = useState([]);

  const query = useParams();

  const getEventCategoryDetailsById = async (eventId, searchText = "") => {
    try {
      setLoading(true);
      const res = await event.getEventCategoryDetailsById(
        eventId,
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText,
        // [...filterStatus,...filterTeam,...filterType].length > 0 ? JSON.stringify([...filterStatus,...filterTeam,...filterType]):"",
        JSON.stringify([...filterStatus, ...filterTeam, ...filterType])
      );
      setApiData(res?.data?.response?.records);
      setCount(res?.data?.response?.totalItems);
      // setTotalData(res?.data?.response?.records);
      setLoading(false);
    } catch (err) { }
  };

  // const handleSearch = (value) => {
  //   getEventCategoryDetailsById(eventId, value);
  // };

  const handleSearch = debounce((value) => {
    getEventCategoryDetailsById(eventId, value);
    setLoading(true);
  }, 500);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
  };

  useEffect(() => {
    getEventCategoryDetailsById(eventId);
  }, [
    eventId,
    pagination,
    sortingColumn,
    sortingOrder,
    filterStatus,
    filterTeam,
    filterType,
  ]);

  const getEventCategoryDetailsByIdTreeData = async (
    eventId,
    searchText = ""
  ) => {
    try {
      const res = await event.getEventCategoryDetailsById(eventId, count,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText,
        // [...filterStatus,...filterTeam,...filterType].length > 0 ? JSON.stringify([...filterStatus,...filterTeam,...filterType]):"",
        JSON.stringify([...filterStatus, ...filterTeam, ...filterType])
      );

      setTotalData(res?.data?.response?.records);
    } catch (err) { }
  };

  useEffect(() => {
    if (count) {
      getEventCategoryDetailsByIdTreeData(eventId);
    }
  }, [count]);

  const openInNewTab = (catId, type) => {
    const url = `/pages/events/all-events/${query?.EventId}/result-sheet/${catId}?type=${type}`;
    window.open(url, "_blank");
  };

  const capitalizeFirstLetter = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleParticipantClick = (record) => {
    setDefaultExpandedKeys([]);
    // Get the parent category (e.g., "kata", "kumite") and specific key  typeToSort
    const parentCategory =
      record?.teamToSort === "Individual"
        ? record?.typeToSort === "kata"
          ? "kata"
          : record?.typeToSort === "kumite"
            ? "kumite"
            : ""
        : record?.teamToSort === "Team"
          ? record?.typeToSort === "kata"
            ? "team kata"
            : record?.typeToSort === "kumite"
              ? "team kumite"
              : ""
          : "";
    const specificKey = `${record.key}-age`; // Ensure this matches your tree key format

    // Find the last child key from the tree data
    const findLastChildKey = (treeNode) => {
      if (!treeNode.children || treeNode.children.length === 0) {
        return treeNode.key; // If no children, return the node's key
      }
      return findLastChildKey(treeNode.children[treeNode.children.length - 1]); // Recurse to the last child
    };

    // Locate the tree node corresponding to the clicked record
    const nodeToExpand = treeData
      .find((node) => node.key === parentCategory)
      ?.children.find((child) => child.key === specificKey);

    if (nodeToExpand) {
      const lastChildKey = findLastChildKey(nodeToExpand);

      // Update expanded keys to include all necessary keys
      const newExpandedKeys = [parentCategory, specificKey, lastChildKey];
      setDefaultExpandedKeys((prevKeys) => [
        ...new Set([...prevKeys, ...newExpandedKeys]),
      ]);
    }
  };

  let data = [];
  for (let item of apiData) {
    data.push({
      key: item?.category?._id,
      name: (
        <div
          style={{
            minWidth: "180px",
            // maxWidth: "180",
            display: "flex",
            gap: "5px",
          }}
        >
          <div
            className="mx-2"
            style={{ minWidth: "160px", display: "flex", alignItems: "center" }}
          >
            <Link
              // to={`/pages/events/all-events/category/${item?._id}/matches`}
              to={{
                pathname: `/pages/events/all-events/${eventId}/category/${item?._id}/matches`,
                state: {
                  name: item?.category?.name,
                  type: item?.category?.type,
                },
              }}
              style={{ cursor: "pointer" }}
              className="ellipsis"
            >
              <div
                className="ellipsis"
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  color: "var(--text-primary)",
                  lineHeight: "15px",
                  letterSpacing: "0.9px",
                }}
              >
                {item?.category?.name || "-"}
              </div>
            </Link>
          </div>
        </div>
      ),
      nameToSort: item?.category?.name || "-",
      type: (
        <div style={{ width: "120px" }}>
          {item?.category?.type
            ? capitalizeFirstLetter(item?.category?.type)
            : "-"}
        </div>
      ),
      typeToSort: item?.category?.type || "-",

      numberOfParticipants: (
        <div style={{ width: "120px" }}>
          {item?.category?.team
            ? item?.totalTeamParticipant?.length || 0
            : item?.totalParticipant?.length || 0}
        </div>
      ),
      description: (
        <div style={{ width: "300px" }}>
          {item?.category?.description ? item?.category?.description : "-"}
        </div>
      ),
      gender: (
        <div style={{ width: "100px" }}>
          {item?.category?.gender
            ? capitalizeFirstLetter(item?.category?.gender)
            : "-"}
        </div>
      ),
      genderToSort: item?.category?.gender ? item?.category?.gender : "-",
      age: (
        <div style={{ width: "150px" }}>
          {item?.category?.age?.min
            ? item?.category?.age?.min +
            " to " +
            item?.category?.age?.max +
            " years"
            : "-"}
        </div>
      ),
      ageToSort: item?.category?.age?.min
        ? item?.category?.age?.min +
        " to " +
        item?.category?.age?.max +
        " years"
        : "-",
      weight: (
        <div style={{ width: "150px" }}>
          {item?.category?.weight?.min && item?.category?.weight?.max
            ? `${item.category.weight.min} - ${item.category.weight.max} kg`
            : item?.category?.weight?.min
              ? `${item.category.weight.min} kg`
              : item?.category?.weight?.max
                ? `${item.category.weight.max} kg`
                : "-"}
        </div>
      ),
      weightToSort:
        item?.category?.weight?.min && item?.category?.weight?.max
          ? `${item.category.weight.min} - ${item.category.weight.max} kg`
          : item?.category?.weight?.min
            ? `${item.category.weight.min} kg`
            : item?.category?.weight?.max
              ? `${item.category.weight.max} kg`
              : "-",
      team: (
        <div style={{ width: "120px" }}>
          {item?.category?.team ? "Team" : "Individual"}
        </div>
      ),
      teamToSort: item?.category?.team ? "Team" : "Individual",
      startDate: (
        <div style={{ width: "200px" }}>
          {moment(item?.category?.startDate).format("DD MMM YYYY hh:mm:ss A") ||
            "-"}
        </div>
      ),
      endDate: (
        <div style={{ width: "200px" }}>
          {moment(item?.category?.endDate).format("DD MMM YYYY hh:mm:ss A") ||
            "-"}
        </div>
      ),
      result: (
        <div>
          <Button
            onClick={() => {
              // setSelectedCategoryItem(item?.category?._id);
              openInNewTab(item?._id, item?.category?.type);
            }}
          >
            Result
          </Button>
        </div>
      ),
    });
  }

  const columns = [
    {
      title: "Category Name",
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      sorter: {
        compare: (a, b) => a.nameToSort.localeCompare(b.nameToSort),
        // multiple: 3,
      },
    },
    {
      title: "Type",
      className: "ant-table-row-cell-break-word",
      dataIndex: "type",
      align: "center",
      sorter: {
        compare: (a, b) => a.typeToSort.localeCompare(b.typeToSort),
        // multiple: 3,
      },
    },
    {
      title: "No Of Participants",
      className: "ant-table-row-cell-break-word",
      dataIndex: "numberOfParticipants",
      align: "center",
      render: (text, record) => (
        <div
          style={{ width: "120px", cursor: "pointer", color: "#1890FF" }}
          onClick={() => handleParticipantClick(record)}
        >
          {record.numberOfParticipants}
        </div>
      ),
      // minWidth:300,
      // sorter: (a, b) => a.numberOfParticipants - b.numberOfParticipants
    },
    {
      title: "Age Limit",
      className: "ant-table-row-cell-break-word",
      dataIndex: "age",
      align: "center",
      sorter: (a, b) => {
        const minAgeA = parseInt(a.ageToSort.split(" to ")[0], 10) || 0;
        const minAgeB = parseInt(b.ageToSort.split(" to ")[0], 10) || 0;
        return minAgeA - minAgeB;
      },
    },
    {
      title: "Weight Range",
      className: "ant-table-row-cell-break-word",
      dataIndex: "weight",
      align: "center",
      sorter: (a, b) => {
        // Extract minimum weight
        const getMinWeight = (weightToSort) => {
          if (weightToSort === "-") return 0; // Default for missing weight
          const match = weightToSort.match(/^(\d+)/); // Match the first number
          return match ? parseInt(match[1], 10) : 0;
        };

        const minWeightA = getMinWeight(a.weightToSort);
        const minWeightB = getMinWeight(b.weightToSort);

        return minWeightA - minWeightB;
      },
    },
    {
      title: "Gender",
      className: "ant-table-row-cell-break-word",
      dataIndex: "gender",
      align: "center",
      sorter: {
        compare: (a, b) => a.genderToSort.localeCompare(b.genderToSort),
        // multiple: 3,
      },
    },
    {
      title: "Team",
      className: "ant-table-row-cell-break-word",
      dataIndex: "team",
      align: "center",
      // sorter: (a, b) => {
      //   const valueA = a?.category?.team ? "Team" : "Individual";
      //   const valueB = b?.category?.team ? "Team" : "Individual";
      //   return valueA.localeCompare(valueB);
      // },
    },
    {
      title: "Result",
      className: "ant-table-row-cell-break-word",
      dataIndex: "result",
      align: "center",
      // fixed: "right"
    },
  ];

  const processParticipants = (participants, category, age, weight) => {
    return participants.map((participant) => {
      const { _id, firstName, lastName, city, email, mobile } = participant;
      const uniqueKey = `${_id}-${category}-${age.min}-${age.max}-${weight?.min ?? 0
        }-${weight?.max ?? 0}`;
      const children = [];

      if (city) {
        children.push({
          title: `City: ${city}`,
          key: `${uniqueKey}-city`,
        });
      }
      if (email) {
        children.push({
          title: `Email: ${email}`,
          key: `${uniqueKey}-email`,
        });
      }
      if (mobile) {
        children.push({
          title: `Mobile: ${mobile}`,
          key: `${uniqueKey}-mobile`,
        });
      }

      return {
        title: `${firstName} ${lastName}`,
        key: `${uniqueKey}-participant`,
        children,
      };
    });
  };

  const processParticipantsTeams = (participants, category, age, weight) => {
    return participants.map((participant) => {
      const { _id, name, students } = participant;
      const uniqueKey = `${_id}-${category}-${age.min}-${age.max}-${weight?.min ?? 0
        }-${weight?.max ?? 0}`;
      const children = [];

      if (students?.length > 0) {
        students.forEach((student, index) =>
          children.push({
            title: `${student?.firstName}`,
            key: `${student?._id + "_" + Math.random()}`,
          })
        );
      }
      // if (email) {
      //   children.push({
      //     title: `Email: ${email}`,
      //     key: `${uniqueKey}-email`,
      //   });
      // }
      // if (mobile) {
      //   children.push({
      //     title: `Mobile: ${mobile}`,
      //     key: `${uniqueKey}-mobile`,
      //   });
      // }

      return {
        title: `${name}`,
        key: `${_id}-participant`,
        children,
      };
    });
  };

  const processTreeData = (data) => {
    const groupedData = {
      kata: [],
      kumite: [],
      "team kata": [],
      "team kumite": [],
    };

    // Group data based on the type, team, and other properties
    data.forEach((item) => {
      const { type, age, team, weight, _id, name, gender } = item?.category;
      const { totalParticipant, totalTeamParticipant } = item;
      let category = type;
      if (team) {
        category = `team ${type}`;
      }

      groupedData[category]?.push({
        title:
          `${name} (${name.toLowerCase().includes("team")
            ? "Total Teams"
            : "Total Participants"
          } ${name.toLowerCase().includes("team")
            ? totalTeamParticipant?.length
            : totalParticipant?.length
          }, Gender: ${gender} , Age: ${age?.min} - ${age?.max} years` +
          (weight?.min !== undefined && weight?.max !== undefined
            ? `, Weight: ${weight?.min} - ${weight?.max} kg )`
            : ""),
        key: `${_id}-age`,
        children: [
          {
            // title: `Participants: ${
            //   !team ? totalParticipant.length : totalTeamParticipant?.length
            // }`,
            title: (
              <span style={{ color: "#1890FF" }}>
                Participants:{" "}
                <span>
                  {!team
                    ? totalParticipant.length
                    : totalTeamParticipant?.length}
                </span>
              </span>
            ),
            key: `${_id}-participants`,
            children: !team
              ? processParticipants(totalParticipant, category, age, weight)
              : processParticipantsTeams(
                totalTeamParticipant,
                category,
                age,
                weight
              ), // Pass category, age, and weight for unique keys
          },
        ],
      });
    });

    return Object.keys(groupedData).map((category) => ({
      title: capitalizeFirstLetter(category),
      key: category,
      children: groupedData[category],
    }));
  };

  useEffect(() => {
    const data = processTreeData(totalData);
    setTreeData(data);

    // const allKeys = data.flatMap((category) => [
    //   category.key,
    //   ...category.children.map((child) => child.key),
    // ]);
    // console.log('allKeys', allKeys);
    // setDefaultExpandedKeys(allKeys);
  }, [totalData]);

  useEffect(() => {
    const baseurl = window.location;
    if (baseurl.pathname.includes("/kata-result")) {
      setOpenkataResult(true);
    }
  }, []);

  const onSelect = (selectedKeys, info) => { };

  const deleteEventCategories = async () => {
    try {
      const res = await event.deleteEventCategories({
        event: [query?.EventId],
        category: selectedRowKeys,
        password: givenPassword,
      });
      setSelectedRowKeys([]);
      notify("success", "Categories Deleted Successfully.");
      getEventCategoryDetailsById(query?.EventId);
      setIsModalOpen(false);
    } catch (err) {
      notify("error", err.data.message);
    }
  };

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 330px)", x: true } : null;

  return (
    <>
      {/* {openKataResult && (
        <KataFinalResultDrawer
          open={openKataResult}
          setOpen={setOpenkataResult}
          selectedCategoryItem={selectedCategoryItem}
        />
      )} */}
      {openCategoriesModal && (
        <AddCategoriesModal
          open={openCategoriesModal}
          setOpen={setOpenCategoriesModal}
          getEventCategoryDetailsById={getEventCategoryDetailsById}
          eventCategoryData={apiData}
        />
      )}
      <DeleteConfirionModal
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => deleteEventCategories()}
        messageText={
          "Please confirm it by entering your password to take the action."
        }
        password={givenPassword}
        setPassword={setGivenPassword}
      />
      <Row gutter={[24, 0]} className="mt-24">
        <Col
          sm={24}
          md={24}
          lg={24}
          className="header-control"
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            {selectedRowKeys.length > 0 && (
              <Button
                type="danger"
                role="button"
                style={{ marginRight: "10px", fontSize: "13px" }}
                onClick={() => setIsModalOpen(true)}
              >
                Delete Categories
              </Button>
            )}

            <button
              class="button-29"
              role="button"
              onClick={() => setOpenCategoriesModal(true)}
            >
              Add Categories
            </button>
          </div>
          <div className="d-flex">
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ width: "300px" }}
              onChange={(e) => {
                handleSearch(e.target.value);
                // setSearchText(e.target.value);
              }}
            // value={searchText}
            />
            <Filter
              show={show}
              setShow={setShow}
              data={[
                {
                  title: ["Gender"],
                  list: ["male", "female", "both"],
                  filterList: filterStatus || [],
                  setFilterList: setFilterStatus || [],
                },
                {
                  title: "Team",
                  list: ["individual", "team"],
                  filterList: filterTeam || [],
                  setFilterList: setFilterTeam || [],
                },
                {
                  title: "Type",
                  list: ["kumite", "kata"],
                  filterList: filterType || [],
                  setFilterList: setFilterType || [],
                },
              ]}
            />
          </div>
        </Col>
        <Col sm={24} md={24} lg={17}>
          <CustomTable>
            <Table
              tableLayout="auto"
              scroll={scroll}
              columns={columns}
              dataSource={data}
              bordered={false}
              rowSelection={rowSelection}
              pagination={{
                defaultPageSize: 10,
                defaultCurrent: 1,
                showSizeChanger: true,
                pageSizeOptions: ["10", "25", "50", "100"],
                total: count,
                onChange: (currentPage, size) => {
                  setPagination({ size, currentPage });
                },
                showTotal: (total, range) => (
                  <ShowTotal
                    selectedRowKeys={selectedRowKeys}
                    total={total}
                    currentPageItem={range[1] - range[0] + 1}
                    ranged={range}
                  />
                ),
              }}
              onChange={handleChangeTable}
              locale={{
                emptyText: loading ? (
                  <Skeleton round active={true} />
                ) : (
                  <Empty />
                ),
              }}
            />
          </CustomTable>
        </Col>

        <Col sm={24} md={24} lg={7}>
          <div style={{ maxHeight: "550px", overflowY: "auto" }}>
            <Tree
              showLine
              switcherIcon={<DownOutlined />}
              onSelect={onSelect}
              treeData={treeData}
              // defaultExpandedKeys={["Kata", "Kumite"]}
              // defaultExpandedKeys={defaultExpandedKeys}
              expandedKeys={defaultExpandedKeys} // Dynamically control expanded keys
              onExpand={(keys) => setDefaultExpandedKeys(keys)} // Update state on manual expand
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EventCatagories;
