import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { useHistory } from "react-router-dom";
import institute from "../../../services/institute";
import { notify } from "../../../utils/helper";
import { useEffect, useState } from "react";
import users from "../../../services/users";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../store/actions/bredcrumb";
const CreateInstitutes = () => {
  const history = useHistory();
  const [coachData, setCoachData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const dispatch = useDispatch();

  const types = [
    { label: "Club", value: "CLUB" },
    { label: "School", value: "SCHOOL" },
    { label: "College", value: "COLLEGE" },
  ];

  const createInstitutes = async (payload) => {
    try {
      const res = await institute.createInstitute(payload);
      notify("success", "Institute Created Successfully.");
      history.goBack();
    } catch (err) {}
  };

  const getCoachs = async () => {
    try {
      //   setLoading(true);
      let res = await users.getCoachs();
      setCoachData(res?.data?.response?.records);
      //   setLoading(false);
    } catch (err) {}
  };

  // const fetchStates = async () => {
  //   const response = await fetch('https://cdn-api.co-vin.in/api/v2/admin/location/states');
  //   const data = await response.json();
  //   setStateData(data.states)
  //   // fetchDistricts(data?.states[0]?.state_name)
  //   // return data.states;
  // };
  const getAllStates = async () => {
    try {
      const response = await users.getAllStates();
      setStateData(response?.data);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getAllStates();
  }, []);

  // const fetchDistricts = async (stateName) => {
  //   const stateId = stateData.filter(state => state.state_name === stateName)[0]?.state_id;

  //   const response = await fetch(`https://cdn-api.co-vin.in/api/v2/admin/location/districts/${stateId}`);
  //   const data = await response.json();
  //   setDistrictData(data.districts);
  //   // return data.districts;
  // };

  const getAllDistrict = async (stateName) => {
    const stateId = stateData.filter((state) => state.name === stateName)[0]
      ?.id;

    try {
      const response = await users.getAllDistrict(stateId);
      setDistrictData(response?.data?.names);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getCoachs();
    // fetchStates();
  }, []);

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: "Create New Institute",
        subtitle: "",
        url: "",
      })
    );
  }, []);

  return (
    <Card title={<h4>Create Institutes</h4>}>
      <Form onFinish={createInstitutes} layout="vertical">
        <Row gutter={[24, 24]}>
          <Col span={24} className="m-2"></Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Name</h4>

            <Form.Item
              style={{ marginBottom: 10 }}
              className="username"
              name="name"
              rules={[
                {
                  required: true,
                  message: "name is required!",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Type</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "type is required!",
                },
              ]}
            >
              <Select
                placeholder="Select type"
                //   value={role}
                //   onChange={(e) => setRole(e)}
              >
                {types.map((org) => (
                  <Select.Option key={org.value} value={org.value}>
                    {org.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Coach</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="coach"
              name="coaches"
              rules={[
                {
                  required: true,
                  message: "coach is required!",
                },
              ]}
            >
              <Select
                placeholder="Select coach"
                 mode="multiple"
                 maxTagCount="responsive"
                
                //   value={role}
                //   onChange={(e) => setRole(e)}
              >
                {coachData.map((org) => (
                  <Select.Option key={org.value} value={org._id}>
                    {org.firstName} {org.lastName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">State</h4>

            <Form.Item
              style={{ marginBottom: 10 }}
              className="username"
              name="state"
              rules={[
                {
                  required: true,
                  message: "state name is required!",
                },
              ]}
            >
              {/* <Input placeholder="State" /> */}
              <Select
                placeholder="Select state"
                //   value={role}
                showSearch
                onChange={(e) => getAllDistrict(e)}
              >
                {stateData.map((org) => (
                  <Select.Option key={org.id} value={org.name}>
                    {org.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">District</h4>

            <Form.Item
              style={{ marginBottom: 10 }}
              className="username"
              name="district"
              rules={[
                {
                  required: true,
                  message: "district is required!",
                },
              ]}
            >
              {/* <Input placeholder="District" /> */}
              <Select showSearch placeholder="Select district">
                {districtData.map((org) => (
                  <Select.Option key={org.id} value={org.name}>
                    {org.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} className="m-3"></Col>
          <Col
            span={24}
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              type="primary"
              className="px-25"
              onClick={() => history.goBack()}
            >
              CANCEL
            </Button>
            <Button type="primary" className="px-25" htmlType="submit">
              CREATE INSTITUTE
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CreateInstitutes;
