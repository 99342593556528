import React, { useState } from "react";
import ModalWrapper from "../../modal/ModalWrapper";
import { Button, Col, Divider, Row, Spin, Upload } from "antd";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { GoPlus } from "react-icons/go";
import { InboxOutlined } from "@ant-design/icons";
import event from "../../../services/event";
import { notify } from "../../../utils/helper";

const UploadEntriesModal = ({ open, setOpen, selectedEvent,getEventById }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const handleDrop = (newFiles) => {
    try {
      setFiles(newFiles?.file);
    } catch (err) {}
  };

  const downloadSampleFile = async () => {
    try {
      //   const res = await report.downloadSample({ filename });
      //   window.open(res?.data);
    } catch (err) {}
  };

  const handleUploadSubmit = async (files) => {
    if (
      !files ||
      (typeof files === "object" && Object.keys(files).length === 0)
    ) {
      notify("info", "Please select a file!");
      return;
    }
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", files);
      const res = await event.ImportEntries(selectedEvent, formData);
      notify("success", "Entries Uploaded Successfully!");
      getEventById("","");
      setOpen(false);
    } catch (err) {
      // setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpload = async () => {
    handleUploadSubmit(files);
  };

  return (
    <ModalWrapper
      title="Upload Entries"
      open={open}
      setOpen={setOpen}
      footer={null}
      width={600}
    >
      <Spin size="large" spinning={isLoading}>
        <Row>
          <Col
            span={24}
            className="d-flex flex-column justify-content-center mb-4"
          >
            <div
              className="d-flex justify-content-center"
              style={{
                textDecoration: "underline",
                letterSpacing: "0.9px",
                margin: "10px 0px 30px 0px",
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={() => downloadSampleFile()}
            >
              Download Sample File
            </div>

            <div
              className="d-flex justify-content-center mt-3 "
              style={{
                border: "1px dashed gray",
                borderRadius: "6px",
                padding: "15px",
                cursor: "pointer",
              }}
            >
              <Upload
                multiple={false}
                beforeUpload={() => false}
                accept=".xls,.xlsx"
                onChange={handleDrop}
              >
                {/* <div className="d-flex justify-content-center">
                <AiOutlineCloudUpload size={25} />
              </div>
              <div className="mb-2" style={{ fontSize: "12px" }}>
                Browse and choose the files you want to upload from your
                computer.
              </div> */}
                <p className="ant-upload-drag-icon d-flex justify-content-center">
                  <InboxOutlined style={{ fontSize: "27px", color: "blue" }} />
                </p>
                <p className="ant-upload-text d-flex justify-content-center">
                  Click or drag file to this area to upload
                </p>
              </Upload>
            </div>
          </Col>

          <Col span={24}>
            <Divider />
          </Col>

          <Col span={12} className="text-right mb-3 px-2">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Col>
          <Col span={12} className="text-left mb-3 px-2">
            <Button className="button-29" onClick={handleUpload}>
              Upload
            </Button>
          </Col>
        </Row>
      </Spin>
    </ModalWrapper>
  );
};

export default UploadEntriesModal;
