import { Avatar, Button, Col, Empty, Input, Row, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import institute from "../../../services/institute";
import { path } from "../../../utils/path";
import moment from "moment";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce, notify, ShowTotal } from "../../../utils/helper";
import { setBreadcrumb } from "../../../store/actions/bredcrumb";
import { useDispatch } from "react-redux";
import DeleteConfirionModal from "../../modal/DeleteConfirionModal";
import Popover from "antd/es/popover";
const Institute = () => {
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [count, setCount] = useState("");
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [searchText, setSearchText] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [givenPassword, setGivenPassword] = useState("")

  const getInstitutes = async (searchText = "") => {
    try {
      setLoading(true);
      let res = await institute.getInstitutes(
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText
      );
      setApiData(res?.data?.response?.records);
      setCount(res?.data?.response?.totalItems);
      setLoading(false);
    } catch (err) {}
  };

  const handleSearch = debounce((value) => {
    getInstitutes(value);
  },500);

  useEffect(() => {
    getInstitutes();
  }, [
    pagination?.size,
    pagination?.currentPage,
    sortingColumn,
    sortingOrder,
    searchText,
  ]);

  let data = [];
  for (let item of apiData) {
    data.push({
      key: item?._id,
      name: (
        <div
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          <div>
            <Avatar
              shape="square"
              size={40}
              style={{
                // backgroundColor: getRandomColor(),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
              }}
              src={item?.profilePicture}
            >
              <div>{item?.name?.[0]?.toUpperCase() || "-"}</div>
            </Avatar>
          </div>

          <div
            className="mx-2"
            style={{ width: "150px", display: "flex", alignItems: "center" }}
          >
            <Link
              to={path().STUDENTS_INSTITUTE_DETAILS(item?._id)}
              style={{ cursor: "pointer" }}
              className="ellipsis"
            >
              <div
                className="ellipsis"
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  color: "var(--text-primary)",
                  lineHeight: "15px",
                  letterSpacing: "0.9px",
                }}
              >
                {item?.name || "-"}
              </div>
            </Link>
          </div>
        </div>
      ),
      nameToSort: item?.name || "-",
      type: item?.type ? item?.type : "-",
      state: item?.state ? item?.state : "-",
      district: item?.district ? item?.district : "-",
      coachToSort: item?.coaches.length > 0
        ? `${item?.coach?.firstName} ${item?.coach?.lastName}`
        : "-",
      coach: (
        <Popover
          content={
            item?.coaches.length > 0 ? (
              <div>
                {item?.coaches.map((cat, index) => (
                  <p
                    style={{
                      margin: "5px 0px",
                      backgroundColor: "#1890ff",
                      padding: "2px 6px",
                      borderRadius: "4px",
                      color: "#fff",
                    }}
                    key={index}
                  >
                    {cat?.firstName + " " + cat.lastName}
                  </p>
                ))}
              </div>
            ) : (
              <p>No Coach Available</p>
            )
          }
          trigger="hover"
        >
          <>
            {item?.coaches !== undefined && item?.coaches?.length > 0 ? (
              <div
                className="cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // setAssignedCategories(item?.category);
                  // // setShowCatagories(true);
                  // setRingId(item?._id);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {item?.coaches?.length > 0 ? (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div
                        style={{
                          padding: "2px 6px",
                          borderRadius: "4px",
                          color: "#fff",
                          backgroundColor: "#1890ff",
                          maxWidth: "100px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {" "}
                        {item?.coaches[0]?.firstName + " " + item?.coaches[0]?.lastName}
                      </div>
                      {item?.coaches?.length > 1 ? (
                        <div
                          style={{
                            padding: "2px 6px",
                            borderRadius: "4px",
                            color: "#fff",
                            backgroundColor: "#1890ff",
                          }}
                        >
                          {" "}
                          + {item?.coaches?.length  - 1} ..
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <span style={{ color: "var(--text-primary)" }}> - </span>
                  )}
                </div>
              </div>
            ) : (
              <div style={{ width: "200px" }}>-</div>
            )}
          </>
        </Popover>
      ),
      createdAt: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.createdAt).format("DD MMM YYYY") || "-"}
        </div>
      ),

      updatedAt: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.updatedAt).format("DD MMM YYYY") || "-"}
        </div>
      ),
    });
  }

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      sorter: {
        compare: (a, b) => a.nameToSort.localeCompare(b.nameToSort),
        multiple: 3,
      },
      width: 200,
    },
    {
      title: "TYPE",
      className: "ant-table-row-cell-break-word",
      dataIndex: "type",
      align: "center",
      sorter: {
        compare: (a, b) => a.type.localeCompare(b.type),
        multiple: 2,
      },
    },
    {
      title: "STATE",
      className: "ant-table-row-cell-break-word",
      dataIndex: "state",
      align: "center",
      // sorter: {
      //   compare: (a, b) => a.type - b.type,
      //   multiple: 2,
      // },
      // width: 200,
    },
    {
      title: "DISTRICT",
      className: "ant-table-row-cell-break-word",
      dataIndex: "district",
      align: "center",
      sorter: {
        compare: (a, b) => a.district.localeCompare(b.district),
        multiple: 2,
      },
    },
    {
      title: "COACH",
      className: "ant-table-row-cell-break-word",
      dataIndex: "coach",
      align: "center",
      sorter: {
        compare: (a, b) => a.coachToSort.localeCompare(b.coachToSort),
        multiple: 2,
      },
    },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      align: "center",
      className: "ant-table-row-cell-break-word",
      // width: 150,
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      align: "center",
      className: "ant-table-row-cell-break-word",
      // width: 150,
    },
  ];

  const handleClick = () => {
    history.push(`/students/institute/create-institute`);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      let res = await institute.deleteInstitute({ institute: selectedRowKeys, password: givenPassword });
      setLoading(true);
      setSelectedRowKeys([]);
      notify("success", "Institute Deleted Successfully.");
      getInstitutes();
      setIsModalOpen(false)
      setLoading(false);
    } catch (err) {
      notify("error", err.data.message)
    }
  };

  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
  };

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 330px)", x: true } : null;

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: "All Institutes",
        subtitle: "",
        url: "",
      })
    );
  }, []);
  return (
    <Row>
      <Col
        span={24}
        className="header-control"
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {selectedRowKeys?.length <= 0 ? (
          <Button type="primary" className="px-25" onClick={handleClick}>
            CREATE NEW INSTITUTE
          </Button>
        ) : (
          <div className="px-2">
            <Button
              type="danger"
              class="button-29"
              role="button"
              onClick={() => setIsModalOpen(true)}
            >
              DELETE INSTITUTES
            </Button>
          </div>
        )}
        <Input
          className="header-search"
          placeholder="Search here..."
          prefix={<SearchOutlined />}
          style={{ width: "300px" }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={data}
          scroll={scroll}
          rowSelection={rowSelection}
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <DeleteConfirionModal
                isOpen={isModalOpen}
                setOpen={setIsModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={() => handleDelete()}
                messageText={
                  "Please confirm it by entering your password to take the action."
                }
                password={givenPassword}
                setPassword={setGivenPassword}
              />
            </div>
          )}
          locale={{
            emptyText: loading ? <Skeleton round active={true} /> : <Empty />,
          }}
          pagination={{
            defaultPageSize: 10,
            defaultCurrent: 1,
            showSizeChanger: true,
            pageSizeOptions: ["25", "50", "100"],
            total: count,
            onChange: (currentPage, size) => {
              setPagination({ size, currentPage });
            },
            showTotal: (total, range) => (
              <ShowTotal
                selectedRowKeys={selectedRowKeys}
                total={total}
                currentPageItem={range[1] - range[0] + 1}
                ranged={range}
              />
            ),
          }}
          onChange={handleChangeTable}
        />
      </Col>
    </Row>
  );
};

export default Institute;
