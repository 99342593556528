import { notification } from "antd";
/**
 *
 * @param {*} type error|warn|success
 * @param {*} msg payload to show
 */
export const notify = (type, msg) => {
    switch (true) {
        case type === "error":
            // message.error(msg);
            notification.error({
                message: `Error`,
                description: msg,
                placement: "topRight",
                // duration: 0,
            });
            break;
        case type === 'Access Denied':
            // message.error(msg);
            notification.error({
                message: 'Access Denied',
                description: msg,
                placement: "topRight",
                // duration: 0,
            });
            break;
        case type === "warn":
            notification.info({
                message: `Warning`,
                description: msg,
                placement: "topRight",
                // duration: 0,
            });
            break;
        case type === "info":
            notification.info({
                message: `Info`,
                description: msg,
                placement: "topRight",
                // duration: 0,
            });
            break;
        case type === "success":
        default:
            notification.success({
                message: `Success`,
                description: msg,
                placement: "topRight",
                // duration: 0,
            });
            break;
    }
};

export const ShowTotal = ({
    selectedRowKeys,
    total,
    currentPageItem,
    ranged,
  }) => {
    let range;
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      range = `${selectedRowKeys.length} of ${total}`;
    } else {
      range = `${ranged?.[0]} - ${ranged?.[1]} of ${total}`;
    }
    return (
      <div>
        <div>{range}</div>
      </div>
    );
  };

  export function capitalizeFirstLetter(str) {
    if (!str) return str; // handle empty or undefined input
    return str.charAt(0).toUpperCase() + str.slice(1);
  }



  export const checkStrongPassword = (password) => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})(?=.*[!@#$%^&*])"
    );
    return strongRegex.test(password);
  };
  
export const filterTagColor = (title) => {
  let filterStyle = {
    background: "rgb(37, 37, 37)",
    color: "#333333",
    text: title,
  };
}
export function formatFee(num) {
  let str = num?.toString();
  let lastThreeDigits = str?.slice(-3);
  let otherDigits = str?.slice(0, -3);
  
  if (otherDigits !== '') {
    lastThreeDigits = ',' + lastThreeDigits;
  }
  
  // Insert commas after every two digits in the other digits
  let result = otherDigits?.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThreeDigits;
  return result;
}


export const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};