import { apiDelete, apiGet, apiPost, apiPut } from "../utils/axios";
import { domainUrl } from "../utils/domainUrl";

export default {
    getInstitutes: (size=0, currentPage=1, sortingColumn='', sortingOrder='',query="") => apiGet(domainUrl(`/api/v1/institute/list?size=${size}&page=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}`)),
    createInstitute: (payload) => apiPost(domainUrl(`/api/v1/institute/create`),payload),
    getInstituteById: (id) => apiGet(domainUrl(`/api/v1/institute/${id}`)),
    updateInstitute: (id, payload) => apiPut(domainUrl(`/api/v1/institute/update/${id}`), payload),
    deleteInstitute: (payload) => apiDelete(domainUrl(`/api/v1/institute/delete`),payload),
    getInstituteByType: (instituteByType) => apiGet(domainUrl(`/api/v1/institute/type?type=${instituteByType}`))

}