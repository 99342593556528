import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Empty,
  Input,
  Popover,
  Row,
  Segmented,
  Skeleton,
  Table,
  Tooltip,
} from "antd";
import { extractToken } from "../../../../utils/localStorage";
import { capitalizeFirstLetter, ShowTotal } from "../../../../utils/helper";
import { CustomTable } from "../Styled";
import { SearchOutlined } from "@ant-design/icons";

const EvenTeamParticipantsTable = ({
  eventTeamParticipants,
  selectedSection,
  setSelectedSection,
  calculateAge,
  setPagination,
  selectedRowKeys,
  handleChangeTable,
  handleSearchTeamByName,
}) => {
  const user = extractToken();

  const columns = [
    {
      title: (
        <div>
          {/* <div style={{}} className="heading mid">
              Teams Name
            </div> */}
          <div
            className="heading mid"
            style={{ textAlign: "start", width: "100%" }}
          >
            Teams Name
          </div>

          {/* <div
              className="header-control"
              onClick={(e) => e.stopPropagation()}
              style={{ justifyContent: "center" }}
            >
              <Input
                className="header-search"
                placeholder="Search here..."
                prefix={<SearchOutlined />}
                style={{ height: "30px", fontSize: "12px" }}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div> */}
        </div>
      ),
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      // width: 200,
      align: "start",
      sorter: {
        compare: (a, b) => a.nameSort.localeCompare(b.nameSort),
        multiple: 3,
      },
    },

    // {
    //   title: (
    //     <div>
    //       <div style={{ textAlign: "center" }} className="heading mid">
    //         Participants
    //       </div>
    //     </div>
    //   ),
    //   dataIndex: "participants",
    //   className: "ant-table-row-cell-break-word",
    //   // width: 200,
    //   align: "center",

    // },


    {
      // title: "Coach",
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Coach
          </div>

          {/* <div
              className="header-control"
              onClick={(e) => e.stopPropagation()}
              style={{ justifyContent: "center" }}
            >
              <Input
                className="header-search"
                placeholder="Search here..."
                prefix={<SearchOutlined />}
                style={{ height: "30px", fontSize: "12px" }}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div> */}
        </div>
      ),
      className: "ant-table-row-cell-break-word",
      // width: 200,
      dataIndex: "coach",
      align: "center",
      hidden: ![
        "stateHead",
        "stateAdmin",
        "districtHead",
        "districtAdmin",
      ].includes(user?.role),
    },
    // {
    //   title: "Belt Level",
    //   dataIndex: "belt",
    //   align: "center",
    //   className: "ant-table-row-cell-break-word",
    // },
    {
      // title: "Category",
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Category
          </div>
          {/* 
            <div
              className="header-control"
              onClick={(e) => e.stopPropagation()}
              style={{ justifyContent: "center" }}
            >
              <Input
                className="header-search"
                placeholder="Search here..."
                prefix={<SearchOutlined />}
                style={{ height: "30px", fontSize: "12px" }}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div> */}
        </div>
      ),
      dataIndex: "category",
      align: "center",
      className: "ant-table-row-cell-break-word",
    },
  ].filter((item) => !item.hidden);

  let coachNames = [];
  let instituteNames = [];
  let data = [];

  for (let item of eventTeamParticipants || []) {
    const coachName = item?.coach?.firstName + " " + item?.coach?.lastName;

    const instituteName = item?.instituteId?.name;

    if (coachName) {
      coachNames.push(coachName);
      instituteNames.push(instituteName);
    }
    data.push({
      key: item?._id,

      // name: (
      //   <Row style={{ width: "400px" }}>
      //     <Col span={24} style={{ border: "1px solid red" }}>
      //       {item?.name ? capitalizeFirstLetter(item?.name) : "-"}
      //     </Col>
      //     <Col span={24} style={{ border: "1px solid red" }}>
      //       <Row style={{ border: "1px solid pink" }}>
      //         {item?.students?.length > 0 &&
      //           item?.students?.map((student) => (
      //             <Col
      //               style={{ border: "1px solid pink", fontSize: "12px" }}
      //               span={8}
      //             >
      //               <div>
      //                 {capitalizeFirstLetter(student?.firstName) +
      //                   " " +
      //                   capitalizeFirstLetter(student?.lastName)}
      //               </div>
      //             </Col>
      //           ))}
      //       </Row>
      //     </Col>
      //   </Row>
      // ),
      name: (
        <Row
          style={{ alignItems: "center", gap: "10px", justifyContent: "start" }}
        >
          <div> {item?.name ? capitalizeFirstLetter(item?.name) : "-"}</div>
          {/*        
            <Avatar.Group
              maxCount={3}
              size={28}
              maxStyle={{
                color: "#fff",
                backgroundColor: "gray",
                border: "2px solid var(--dashboard-primay)",
                fontSize: "12px",
                lineHeight: "26px",
              }}
            >
              {item?.students?.length > 0 &&
                item?.students?.map((student, userIndex) => (
                  <Tooltip
                    title={
                      capitalizeFirstLetter(student?.firstName) +
                      " " +
                      capitalizeFirstLetter(student?.lastName)
                    }
                    placement="top"
                  >
                    <Avatar
                      style={{
                        backgroundColor: "#1890ff",
                        // border: "2px solid #1890ff",
                        fontSize: "13px",
                      }}
                      //   src={user?.profilePicture}
                      key={userIndex}
                      size={28}
                    >
                      {capitalizeFirstLetter(student?.firstName[0])}
                    </Avatar>
                  </Tooltip>
                ))}
            </Avatar.Group> */}
        </Row>
      ),
      nameSort: item.name ? item.name : "-",
      age: (
        <Row style={{}}>
          <Avatar.Group
            maxCount={3}
            size={28}
            maxStyle={{
              color: "#fff",
              backgroundColor: "gray",
              border: "2px solid var(--dashboard-primay)",
              fontSize: "12px",
              lineHeight: "26px",
            }}
          >
            {item?.students?.length > 0 &&
              item?.students?.map((student, userIndex) => (
                // <Col
                //   style={{ border: "1px solid pink", fontSize: "12px" }}
                //   span={24}
                // >
                //   <div>
                //     {capitalizeFirstLetter(student?.firstName)}{" "}
                //     {capitalizeFirstLetter(student?.lastName)} ({" "}
                //     {calculateAge(student?.dateOfBirth)})
                //   </div>
                // </Col>
                <Tooltip
                  title={
                    <div>
                      {capitalizeFirstLetter(student?.firstName)} ({" "}
                      {calculateAge(student?.dateOfBirth)})
                    </div>
                  }
                  placement="top"
                >
                  <Avatar
                    style={{
                      backgroundColor: "#1890ff",
                      // border: "2px solid #1890ff",
                      fontSize: "13px",
                    }}
                    //   src={user?.profilePicture}
                    key={userIndex}
                    size={28}
                  >
                    {capitalizeFirstLetter(student?.firstName[0])}
                  </Avatar>
                </Tooltip>
              ))}
          </Avatar.Group>
        </Row>
      ),
      participants: (
        // <Row style={{ width: "300px" }}>
        //   {item?.students?.length > 0 &&
        //     item?.students?.map((student) => (
        //       <Col
        //         style={{ border: "1px solid pink", fontSize: "12px" }}
        //         span={24}
        //       >
        //         <div>
        //           {capitalizeFirstLetter(student?.firstName)}{" "}
        //           {capitalizeFirstLetter(student?.lastName)} ({student?.gender})
        //         </div>
        //       </Col>
        //     ))}
        // </Row>
        <Popover
          content={
            <>
              <div style={{}}>
                {item?.students?.length > 0
                  ? item?.students?.map((student, index) => (
                      <div
                        key={index}
                        style={{
                          padding: "2px 6px",
                          marginBottom: "4px",
                          borderRadius: "4px",
                          color: "#fff",
                          backgroundColor: "#1890ff",
                        }}
                      >
                        <span>
                          {capitalizeFirstLetter(student?.firstName)}{" "}
                          {capitalizeFirstLetter(student?.lastName)} (
                          {calculateAge(student?.dateOfBirth)}) -{" "}
                          {student?.gender}
                        </span>
                      </div>
                    ))
                  : "-"}
              </div>
            </>
          }
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {item?.students?.length > 0 ? (
              <div style={{ display: "flex", gap: "10px" }}>
                <div
                  style={{
                    padding: "2px 6px",
                    borderRadius: "4px",
                    color: "#fff",
                    backgroundColor: "#1890ff",
                  }}
                >
                  {" "}
                  {item?.students[0]?.firstName}
                </div>
                {item?.students?.length - 1 ? (
                  <div
                    style={{
                      padding: "2px 6px",
                      borderRadius: "4px",
                      color: "#fff",
                      backgroundColor: "#1890ff",
                    }}
                  >
                    {" "}
                    + {item?.students?.length - 1} ..
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <span style={{ color: "var(--text-primary)" }}> - </span>
            )}
          </div>
        </Popover>
      ),
      coach: item?.coach
        ? `${
            capitalizeFirstLetter(item?.coach?.firstName) +
            " " +
            capitalizeFirstLetter(item?.coach?.lastName)
          }`
        : "-",

      institute: item?.institute?.name,
      category: item?.category?.name,
      students: item?.students,
    });
  }

  const expandColumns = [
    {
      title: "Team members",
      dataIndex: "teamMembers",
      key: "members",
      align: "start",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      align: "center",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      align: "center",
    },
  ];

  const expandedRowRender = (record) => {
    const expandDataSource = record?.students?.map((student) => ({
      key: student?._id,
      teamMembers: student?.firstName
        ? student?.firstName + " " + student?.lastName
        : "-",
      gender: student?.gender,
      age: calculateAge(student.dateOfBirth),
    }));

    return (
      <Table
        columns={expandColumns}
        dataSource={expandDataSource}
        pagination={false}
        size="small"
      />
    );
  };

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 325px)", x: true } : null;

  return (
    <Col span={24}>
      <CustomTable>
        <Table
          tableLayout="auto"
          scroll={scroll}
          columns={columns}
          dataSource={data}
          bordered={false}
          title={() => (
            <Row gutter={[0, 0]} className="">
              <Col
                span={16}
                style={{ justifyContent: "start" }}
                className="d-flex align-items-center px-2 header-control"
              >
                {/* <Segmented
                    options={["Individuals", "Team"]}
                    defaultValue={selectedSection}
                    onChange={(value) => {
                      setSelectedSection(value);
                    }}
                  /> */}
              </Col>

              <Col
                span={8}
                className="d-flex justify-content-end header-control"
              >
                <div>
                  <button className="button-29">Print All</button>
                </div>
                <Input
                  className="header-search"
                  placeholder="Search here..."
                  prefix={<SearchOutlined />}
                  style={{ width: "300px" }}
                  onChange={(e) => handleSearchTeamByName(e.target.value)}
                />
              </Col>
            </Row>
          )}
          pagination={{
            defaultPageSize: 10,
            defaultCurrent: 1,
            showSizeChanger: true,
            pageSizeOptions: ["25", "50", "100"],
            total: eventTeamParticipants?.length,
            onChange: (currentPage, size) => {
              setPagination({ size, currentPage });
            },
            showTotal: (total, range) => (
              <ShowTotal
                selectedRowKeys={selectedRowKeys}
                total={total}
                currentPageItem={range[1] - range[0] + 1}
                ranged={range}
              />
            ),
          }}
          onChange={handleChangeTable}
          expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: ["0"],
          }}
        />
      </CustomTable>
    </Col>
  );
};

export default EvenTeamParticipantsTable;
