import { Modal } from "antd";
import {React} from "react";

      const ModalWrapper = ({ open, setOpen, title, children, className ,width}) => {
  return (
    <Modal
      title={title}
      open={open}
      onCancel={()=>setOpen(false)}
      footer={null}
      width={width}
      closable={true}
      className={className}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;
