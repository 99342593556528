import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import users from "../../../services/users";
import { notify } from "../../../utils/helper";
import { extractToken } from "../../../utils/localStorage";
import institute from "../../../services/institute";
import moment from "moment";
import { setBreadcrumb } from "../../../store/actions/bredcrumb";
import { useDispatch } from "react-redux";

const CreateUsers = () => {
  const [role, setRole] = useState("");
  const [instituteData, setInstituteData] = useState("");
  const [instituteBasedOnType, setInstituteBasedOnType] = useState([]);
  const [coachId, setCoachId] = useState("");
  const [instituteName, setInstituteName] = useState("");
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [coaches, setCoaches] = useState([]);

  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  let userLogined = extractToken();

  const types = [
    { label: "Club", value: "CLUB" },
    { label: "School", value: "SCHOOL" },
    { label: "College", value: "COLLEGE" },
  ];

  const genders = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userRole = params.get("role");

  //  get institutes
  const getInstitutes = async () => {
    try {
      let res = await institute.getInstitutes();
      setInstituteData(res?.data?.response?.records);
    } catch (err) {}
  };

  useEffect(() => {
    getInstitutes();
  }, []);

  const findInstitutes = async (e) => {
    // const ints = instituteData.filter((inst) => inst?.type === e);
    // setInstituteBasedOnType(ints);

    try {
      let res = await institute.getInstituteByType(e);
      setInstituteBasedOnType(res?.data);
      form.setFieldsValue({
        instituteId: " ",
      });
    } catch (err) {}
  };

  const getInstituteById = async (id) => {
    try {
      let res = await institute.getInstituteById(id);
      setCoaches(res?.data?.institute?.coaches);
    } catch (err) {}
  };

  const getCoachFromInstituteSelected = (instituteSelected) => {
    getInstituteById(instituteSelected);
    const inst = instituteBasedOnType.filter(
      (inst) => inst?._id === instituteSelected
    )[0];

    setCoachId(inst?.coach?._id);
    setInstituteName(inst?.name);
  };

  const createUser = async (values) => {
    const payload = {
      ...values,
      role: userRole,
      dateOfBirth: moment(values?.dateOfBirth).format("YYYY-MM-DD"),
    };
    delete payload.type;

    try {
      const res = await users.createUsers(payload);

      notify("success", "User Created Successfully.");
      history.goBack();
    } catch (err) {
      notify("warn", err.data.error);
    }
  };

  const getAllStates = async () => {
    try {
      const response = await users.getAllStates();
      setStateData(response?.data);
    } catch (err) {
      // console.log(err);
    }
  };

  const getAllDistrict = async (stateName) => {
    const stateId = stateData.filter((state) => state.name === stateName)[0]
      ?.id;

    try {
      const response = await users.getAllDistrict(stateId);
      setDistrictData(response?.data?.names);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    // fetchStates();
    getAllStates();
  }, []);

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: "Players",
        subtitle: "Create New Players",
        url: "",
      })
    );
  }, []);

  return (
    <Card title={<h4>Create {userRole}</h4>}>
      <Form form={form} onFinish={createUser} layout="vertical">
        <Row gutter={[24, 24]}>
          <Col span={24} className="m-2"></Col>

          {userRole === "student" &&
            userLogined?.role !== "coach" &&
            userLogined?.role !== "student" && (
              <>
                {/* <Col sm={24} md={24} lg={12} span={12}>
                  <h4 className="list-header text-m text-muted m-0">Coach</h4>
                  <Form.Item
                    style={{ margin: 0 }}
                    className="coach"
                    name="coach"
                    rules={[
                      {
                        required: true,
                        message: "coach is required!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select coach"
                      value={role}
                      onChange={(e) => setRole(e)}
                    >
                      {organization.map((org) => (
                        <Select.Option key={org.value} value={org.value}>
                          {org.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
              </>
            )}

          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">First Name</h4>

            <Form.Item
              style={{ marginBottom: 10 }}
              className="username"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "firstname is required!",
                },
              ]}
            >
              <Input placeholder="Firstname" />
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Last Name</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="username"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "lastname is required!",
                },
              ]}
            >
              <Input placeholder="Lastname" />
            </Form.Item>
          </Col>
          {userRole === "student" && (
            <>
              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">
                  Institute Type
                </h4>

                <Form.Item
                  style={{ margin: 0 }}
                  className="type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "type is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select institute type"
                    value={role}
                    onChange={(e) => findInstitutes(e)}
                  >
                    {types.map((org) => (
                      <Select.Option key={org.value} value={org.value}>
                        {org.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Institute</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  className="institute"
                  name="instituteId"
                  rules={[
                    {
                      required: true,
                      message: "institute is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select an institute"
                    value={role}
                    onChange={(e) => getCoachFromInstituteSelected(e)}
                  >
                    {instituteBasedOnType.map((org) => (
                      <Select.Option key={org._id} value={org._id}>
                        {org.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Coach</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  className="coach"
                  name="coach"
                  rules={[
                    {
                      required: true,
                      message: "coach is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select coach"
                    // mode="multiple"
                    maxTagCount="responsive"

                    //   value={role}
                    //   onChange={(e) => setRole(e)}
                  >
                    {coaches.map((org) => (
                      <Select.Option key={org.value} value={org._id}>
                        {org.firstName} {org.lastName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Email</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "email is required!",
                },
              ]}
            >
              <Input placeholder="email" type="email" />
            </Form.Item>
          </Col>
          {/* <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Password</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "password is required!",
                },
              ]}
            >
              <Input placeholder="password" type="password" />
            </Form.Item>
          </Col> */}

          {userRole === "student" && (
            <>
              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Class</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  className="class"
                  name="class"
                  rules={[
                    {
                      required: true,
                      message: "class is required!",
                    },
                  ]}
                >
                  <Input type="number" placeholder="class" />
                </Form.Item>
              </Col>
              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">
                  Date Of Birth
                </h4>

                <Form.Item
                  style={{ margin: 0 }}
                  className="dateOfBirth"
                  //   name="age"
                  name="dateOfBirth"
                  rules={[
                    {
                      required: true,
                      message: "date of birthday is required!",
                    },
                  ]}
                >
                  {/* <Input type="number" placeholder="age" /> */}
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(date, dateString) => {
                      const formattedDate = moment(date).format("DD/MM/YYYY");
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Gender</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Gender is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select gender"
                    // value={role}
                    onChange={(e) => {}}
                  >
                    {genders.map((gender) => (
                      <Select.Option key={gender.value} value={gender.value}>
                        {gender.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Weight</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  className="weight"
                  name="weight"
                  rules={[
                    {
                      required: true,
                      message: "weight is required!",
                    },
                  ]}
                >
                  <Input type="number" placeholder="weight" />
                </Form.Item>
              </Col>
            </>
          )}

          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">State</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="state"
              name="state"
              rules={[
                {
                  required: true,
                  message: "state is required!",
                },
              ]}
            >
              {/* <Input placeholder="state" /> */}
              <Select
                placeholder="Select state"
                //   value={role}
                showSearch
                onChange={(e) => getAllDistrict(e)}
              >
                {stateData.map((org) => (
                  <Select.Option key={org.id} value={org.name}>
                    {org.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">District</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="district"
              name="district"
              rules={[
                {
                  required: true,
                  message: "district is required!",
                },
              ]}
            >
              <Select placeholder="Select state" showSearch>
                {districtData.map((org) => (
                  <Select.Option key={org.id} value={org.name}>
                    {org.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">City</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="city"
              name="city"
              rules={[
                {
                  required: true,
                  message: "city is required!",
                },
              ]}
            >
              <Input placeholder="city" />
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Pincode</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="pincode"
              name="pincode"
              rules={[
                {
                  required: true,
                  message: "pincode is required!",
                },
              ]}
            >
              <Input placeholder="pincode" />
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Mobile</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="mobile"
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "mobile is required!",
                },
              ]}
            >
              <Input placeholder="mobile" />
            </Form.Item>
          </Col>
          <Col span={24} className="m-3"></Col>
          <Col
            span={24}
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              type="primary"
              className="px-25"
              onClick={() => history.goBack()}
            >
              CANCEL
            </Button>
            <Button type="primary" className="px-25" htmlType="submit">
              CREATE {userRole.toUpperCase()}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CreateUsers;
