import { Drawer, Row, Col, Typography, Button } from "antd";
import React, { useEffect, useState } from "react";
import {useParams} from 'react-router-dom'
import { io } from "socket.io-client";
import { domain } from "../../../../../utils/domainUrl";
import fixtures from "../../../../../services/fixtures";
import { useLocalStorage } from "../../../../../context/LocalStorageContext";


const socket = io(domain);

const KataScoreDrawer = () => {
  const [currentMatchData, setCurrentMatchData] = useState({});
  const query = useParams()
  const { kataDisplayValue, setKataDisplayValue } = useLocalStorage();


  const formatTime = (seconds) => {
    if (!seconds) return "00:00";
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };



  const fetchScoreBoard = async() => {
  try {
    if(kataDisplayValue) {

      const res = await fixtures.getKataMatchById(kataDisplayValue)
      setCurrentMatchData(res?.data)
    }else {
      setCurrentMatchData({})
    }
  } 
  catch(err) {

  }
  }

  useEffect(() => {
    fetchScoreBoard()
  },[kataDisplayValue])

  // useEffect(() => {
  //   setCurrentMatchData(matchData);
  // }, [matchData]);


  useEffect(() => {
    if (currentMatchData?._id) {
      try {
        
        socket.on(`kata-scorepannel-${currentMatchData._id}`, (payload) => {
         
          setCurrentMatchData(payload?.match);
        });

        return () => {
        
          socket.off(`kata-scorepannel-${currentMatchData._id}`);
        };
      } catch (err) {
        // console.error("Socket error:", err);
      }
    }
  }, [currentMatchData]);

  const { scoreSheet, participant1, subEvent } = currentMatchData || {};


  return (
    <Drawer
      title=""
      placement="bottom"
      // onClose={() => setOpen(false)}
      open={true}
      width="100%"
      height="100%"
      closable={false}
      style={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}
      keyboard={false}
    >
      <div className="scoreboard-container" >
        <Row
          justify="center"
          align="middle"
          className="full-height"
          style={{ position: "relative", height: currentMatchData?.scoreSheet?.kataName ? "60%" : "75%"}}

        >
          <div
            className="team-text"
            style={{ 
              position: "absolute",
              zIndex: 999,
              top: "5%",
              overflowWrap:"break-word",
              overflow:"hidden",
              whiteSpace:"normal",
              width: subEvent?.category?.name ? "25%":"30%",
              textAlign:"center"
            }}
          >
            {subEvent?.category?.name || "Kata Category"}
          </div>

          <Col span={12} className="player-section d-flex justify-content-center align-items-center" style={{height:'100%',backgroundColor:'#000080'}}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="player-name-score-drawer" style={{fontSize:"68px", textAlign:"center"}}>
              {currentMatchData?._id?  <>
                {participant1?.firstName || participant1?.name} {participant1?.lastName}
                </> : 'match is not available at this moment.'}
              </div>
            </div>
          </Col>

          <Col
            span={12}
            style={{textAlign: "center" ,height:'100%',flexDirection: "column",backgroundColor:'#800000'}}

            className="d-flex justify-content-center align-items-center"
          >
            <div style={{fontSize:'40px',fontWeight:700}}>Total Score</div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="score-circle" style={{width:"380px", height:"48vh", paddingRight:"20px", margin:"5px 0px"}}>
                <div className="score-text" style={{margin:"-40px 0px -30px 0px"}}>
                  <span style={{
                    display:"flex",
                    fontSize: scoreSheet?.totalScore % 1 === 0 ? "250px" : "160px"
                    }}>
                      {scoreSheet?.totalScore || 0}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="penalties-section d-flex justify-content-around align-items-center" style={{height:"25%"}}>
          {["judge1", "judge2", "judge3", "judge4", "judge5"].map(
            (judge, index) => (
              <div style={{ border: "1px solid white", width: "200px" }}>
                <div
                  className="judge-score-block"
                  style={{ fontSize: "25px", textAlign: "center", fontWeight:'bold',paddingBottom:"5px" }}
                  key={index}
                >
                  {/* Judge {index + 1}: {scoreSheet?.[judge]} */}
                  Judge {index + 1}
                </div>
                <div
                  style={{
                    fontSize: "40px",
                    textAlign: "center",
                    backgroundColor: "blue",
                    fontWeight:'bold'
                  }}
                >
                  {scoreSheet?.[judge]}
                </div>
              </div>
            )
          )}
        </Row>
        {currentMatchData?.scoreSheet?.kataName ? (
        <Row style={{ height: "15%" }}>
          <Col
            span={24}
            className="d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "#000" }}
          >
            <div className="team-text">
              {currentMatchData?.scoreSheet?.kataName}
            </div>
          </Col>
        </Row>
        ):(null)}
      </div>
    </Drawer>
  );
};

export default KataScoreDrawer;
