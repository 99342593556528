import React from "react";
import ModalWrapper from "../../../../modal/ModalWrapper";
import { Button, Divider, Tag } from "antd";
import { VscArrowSwap } from "react-icons/vsc";

const SwapConfirmationModal = ({
  isOpen,
  setOpen,
  title,
  message,
  participant1,
  participant2,
  onCancle,
  onConfirm,
}) => {
  return (
    <ModalWrapper
      open={isOpen}
      setOpen={setOpen}
      title={title}
      className="custom-modal"
    >
      <div>
        <div style={{ textAlign: "center" }}>
          <div>
            <h2>{message}</h2>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "25px",
              marginLeft: "11px",
            }}
          >
            <Tag
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "23vw",
                whiteSpace: "break-spaces",
                background: "#f5f3f4",
                height: "47px",
              }}
            >
              <strong style={{ color: "blue", fontSize: "20px" }}>
                {participant1 ? participant1 : "N/A"}
              </strong>
            </Tag>
            <VscArrowSwap style={{ fontSize: "30px", marginRight: "8px" }} />
            <Tag
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "23vw",
                whiteSpace: "break-spaces",
                background: "#f5f3f4",
                height: "47px",
              }}
            >
              <strong style={{ color: "red", fontSize: "20px" }}>
                {participant2 ? participant2 : "N/A"}
              </strong>
            </Tag>
          </div>
        </div>
        <Divider />
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          <Button onClick={onCancle}>Cancel</Button>
          <Button type="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SwapConfirmationModal;
