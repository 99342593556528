import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Empty,
  Input,
  Popover,
  Row,
  Segmented,
  Skeleton,
  Table,
  Tooltip,
} from "antd";
import { GiBlackBelt } from "react-icons/gi";
import {
  CustomTable,
  CustomTableEventParticipant,
  SearchInputContainer,
} from "../Styled";
import { MdSearch } from "react-icons/md";
import Filter from "../../../../utils/Filter";
import { extractToken } from "../../../../utils/localStorage";
import {
  capitalizeFirstLetter,
  notify,
  ShowTotal,
} from "../../../../utils/helper";
import { SearchOutlined } from "@ant-design/icons";
import AddEntriesModal from "../AddEntriesModal";
import UploadEntriesModal from "../UploadEntriesModal";
import event from "../../../../services/event";
import { useParams } from "react-router-dom";
import DeleteConfirionModal from "../../../modal/DeleteConfirionModal";
import EventIndividualParticipantsList from "./EventIndividualParticipantsList";
import EvenTeamParticipantsTable from "./EventTeamParticipantsTable";

const EventParticipantsList = ({
  eventData,
  eventTeamParticipants,
  count,
  setPagination,
  handleChangeTable,
  handleSearch,
  handleSearchTeamByName,
  setFilterStatus,
  setFilterInstituteId,
  setFilterCoachId,
  type,
  setType,
  getEventById,
  searchText,
  // handleMapCategoreis,
  setSearchText,
  eventId,
}) => {
  const [show, setShow] = useState(false);
  const [filterCoach, setFilterCoach] = useState([]);
  const [filterInstitute, setFilterInstitute] = useState([]);
  const [selectedSection, setSelectedSection] = useState("Individuals");
  const [allEvents, setAllEvents] = useState([]);
  const [openEntriesModal, setOpenEntriesModal] = useState(false);
  const [openUploadEntriesModal, setOpenUploadEntriesModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = extractToken();
  const { EventId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [givenPassword, setGivenPassword] = useState("");


  let coachNames = [];
  let instituteNames = [];

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    if (isNaN(birthDate.getTime())) {
      return "Invalid Date";
    }

    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();

    if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
      years--;
      months += 12;
    }

    if (today.getDate() < birthDate.getDate()) {
      months--;
      if (months < 0) {
        months = 11;
        years--;
      }
    }

    return `${years}.${months} Years`;
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
    handleChange: (a, b, c) => {},
  };

  const columns = [
    {
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Participants Name
          </div>

          <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search participants..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px", width: "150px" }}
              name="participants"
              onChange={(e) => {
                setType("student");
                handleSearch(e);
                // setSearchText(e.target.value);
              }}
              value={searchText.participants}
            />
          </div>
        </div>
      ),
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      width: 150,
      align: "start",
      sorter: {
        compare: (a, b) => {
          a.namesort.localeCompare(b.namesort.locale);
        },
        multiple: 3,
      },
    },
    {
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Age
          </div>

          {/* <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
        </div>
      ),
      className: "ant-table-row-cell-break-word",
      width: 150,
      dataIndex: "age",
      align: "center",
      sorter: {
        compare: (a, b) => {
          const convertAgeToMonths = (ageStr) => {
            const ageMatch = ageStr.match(/(\d+)\s*years?\s*(\d*)\s*months?/i);
            const years = parseInt(ageMatch?.[1] || 0, 10);
            const months = ageMatch?.[2] ? parseInt(ageMatch[2], 10) : 0;
            return years * 12 + months;
          };

          const ageA = convertAgeToMonths(a.age);
          const ageB = convertAgeToMonths(b.age);

          return ageA - ageB;
        },
        multiple: 2,
      },
    },
    {
      title: <div>Weight</div>,
      dataIndex: "weight",
      align: "center",
      width: 120,
      sorter: (a, b) => {
        const weightA = parseFloat(a.weight);
        const weightB = parseFloat(b.weight);
        return weightA - weightB;
      },
    },
    {
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Gender
          </div>

          {/* <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
        </div>
      ),
      className: "ant-table-row-cell-break-word",
      width: 100,
      dataIndex: "gender",
      align: "center",
    },
    {
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Institute
          </div>

          <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search institute..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px", width: "150px" }}
              name="institute"
              onChange={(e) => {
                setType("institute");
                handleSearch(e);
              }}
              value={searchText.institute}
            />
          </div>
        </div>
      ),
      className: "ant-table-row-cell-break-word",
      width: 150,
      dataIndex: "institute",
      align: "center",
    },
    {
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Coach
          </div>

          <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search coach..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px", width: "150px" }}
              name="coach"
              onChange={(e) => {
                setType("coach");
                handleSearch(e);
              }}
              value={searchText.coach}
            />
          </div>
        </div>
      ),
      className: "ant-table-row-cell-break-word",
      width: 200,
      dataIndex: "coach",
      align: "center",
      hidden: ![
        "stateHead",
        "stateAdmin",
        "districtHead",
        "districtAdmin",
      ].includes(user?.role),
    },
    {
      title: (
        <div>
          <div className="heading mid">Category</div>
          {/* 
          <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
        </div>
      ),
      dataIndex: "category",
      align: "center",
      width: 150,
      className: "ant-table-row-cell-break-word",
    },
  ].filter((item) => !item.hidden);

  let data = [];
  for (let item of eventData || []) {
    const coachName =
      item?.instituteId?.coach?.firstName +
      " " +
      item?.instituteId?.coach?.lastName;

    const instituteName = item?.instituteId?.name;

    if (coachName) {
      coachNames.push(coachName);
      instituteNames.push(instituteName);
    }

    data.push({
      key: item?._id,
      name: item?.firstName ? (
        <div
          style={{ width: "200px" }}
          className="ellipsis"
        >{`${item?.firstName} ${item?.lastName}`}</div>
      ) : (
        <div style={{ width: "200px" }}>-</div>
      ),
      namesort: item?.firstName && `${item?.firstName} ${item?.lastName}`,
      age: calculateAge(item?.dateOfBirth),
      weight: item?.weight ? (
        <div style={{ width: "120px" }}>{`${item?.weight} kg`}</div>
      ) : (
        <div style={{ width: "120px" }}>-</div>
      ),
      gender: item?.gender ? capitalizeFirstLetter(item?.gender) : "-",
      coach:
        item?.instituteId?.coach?.firstName +
        " " +
        item?.instituteId?.coach?.lastName,
      institute: item?.instituteId?.name,
      category: <CategoriesDisplay categories={item?.category} />,
    });
  }

  function CategoriesDisplay({ categories }) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: categories?.map((cat) => cat.name).join(",<br />"),
        }}
      />
    );
  }

  useEffect(() => {
    setFilterInstituteId([...filterInstitute]);
    setFilterCoachId([...filterCoach]);
  }, [filterCoach, filterInstitute]);

  let uniqueCoachNames = [...new Set(coachNames)];
  let uniqueInstituteNames = [...new Set(instituteNames)];

  const getActiveEvent = async () => {
    try {
      const res = await event.getActiveEvent();
      setAllEvents(res?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getActiveEvent();
  }, []);

  const handleDeleteSelectedParticipants = async (eventId, payload) => {
    try {
      const res = await event.deleteEventParticipants(eventId, payload);

      setSelectedRowKeys([]);
      notify("success", res?.data?.message);
      getEventById();
      setIsModalOpen(false);
    } catch (error) {
      notify("error", error.data.message);
    }
  };

  const childRef = useRef();

  const handleMapCategoreis = async (eventId) => {

    try{
      const res = await event.mapCategories(eventId);
      notify("success", res?.data?.message);
      if (childRef.current) {
        childRef.current.callChildFunction();
    }
    }catch (error) {}
    
  };

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 425px)", x: true } : null;

  return (
    <>
      {openEntriesModal && (
        <AddEntriesModal
          open={openEntriesModal}
          setOpen={setOpenEntriesModal}
          getActiveEvent={getActiveEvent}
          selectedEvent={[EventId]}
        />
      )}
      {openUploadEntriesModal && (
        <UploadEntriesModal
          open={openUploadEntriesModal}
          setOpen={setOpenUploadEntriesModal}
          selectedEvent={[EventId]}
        />
      )}

      <DeleteConfirionModal
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() =>
          handleDeleteSelectedParticipants(EventId, {
            participants: selectedRowKeys,
            password: givenPassword,
          })
        }
        messageText={
          "Please confirm it by entering your password to take the action."
        }
        password={givenPassword}
        setPassword={setGivenPassword}
      />
      <Row>
        <Col span={24} className="d-flex justify-content-between mb-2">
          <div>
            <Segmented
              options={["Individuals", "Team"]}
              defaultValue={selectedSection}
              onChange={(value) => {
                setSelectedSection(value);
              }}
            />
          </div>
          <div className="d-flex">
            {[
              "stateHead",
              "stateAdmin",
              "districtHead",
              "districtAdmin",
              "coach",
            ].includes(user?.role) && (
              <>
                {/* {selectedRowKeys?.length ?  (
                  <div className="px-2">
                    <Button
                      style={{ fontSize: "13px" }}
                      type="danger"
                      role="button"
                      onClick={() => setIsModalOpen(true)}
                    >
                      Delete Selected Participant
                    </Button>
                  </div>
                ) : ''} */}
                {/* {selectedRowKeys?.length === 1 && ( */}
                {/* <div style={{ marginRight: "7px" }}>
                  <button
                    class="button-29"
                    role="button"
                    onClick={() => handleMapCategoreis(EventId)}
                  >
                    Map Categories
                  </button>
                </div>
                <div>
                  <button
                    class="button-29"
                    role="button"
                    onClick={() => setOpenEntriesModal(true)}
                  >
                    Add Entries
                  </button>
                </div> */}
                {/* )} */}
                {/* {selectedRowKeys?.length === 1 && ( */}
                {/* <div className="px-2">
                  <button
                    class="button-29"
                    type="danger"
                    role="button"
                    onClick={() => setOpenUploadEntriesModal(true)}
                  >
                    Import Entries
                  </button>
                </div> */}
                {/* )} */}
              </>
            )}
          </div>
        </Col>
        {selectedSection === "Individuals" && (
          <Col span={24}>
            <CustomTableEventParticipant>
              <EventIndividualParticipantsList
                eventId={eventId}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                handleMapCategoreis={handleMapCategoreis}
                ref={childRef}
              />
            </CustomTableEventParticipant>
          </Col>
        )}
        {selectedSection === "Team" && (
          <EvenTeamParticipantsTable
            eventTeamParticipants={eventTeamParticipants}
            columns={columns}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            calculateAge={calculateAge}
            setPagination={setPagination}
            selectedRowKeys={selectedRowKeys}
            handleChangeTable={handleChangeTable}
            handleSearchTeamByName={handleSearchTeamByName}
          />
        )}
      </Row>
    </>
  );
};

export default EventParticipantsList;


