import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import { saveAs } from "file-saver";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { notify, ShowTotal, debounce } from "../../../../utils/helper";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";

// import { FiDownload } from "react-icons/fi";
import fixtures from "../../../../services/fixtures";
import EventMatchFixture from "./EventMatchFixture";

// import fixtures from "../../../../services/fixtures";
import { path } from "../../../../utils/path";
import KumitiScoreSheet from "./category/KumitiScoreSheet";
import { FiDownload, FiEye, FiEyeOff } from "react-icons/fi";
import { EditOutlined, SaveOutlined, SearchOutlined } from "@ant-design/icons";
import event from "../../../../services/event";
import { kataListName } from "../../../../utils/kataNameList";
import { extractToken } from "../../../../utils/localStorage";
import { CustomTable, KataCustomTable } from "../Styled";
import { MdOutlineRefresh } from "react-icons/md";
// import KataScoreDrawer from "./category/KataScoreDrawer";
import { FaCheck, FaCheckDouble, FaMedal } from "react-icons/fa";

const { Option } = Select

const RenderPrintSection = ({
  printSectionRef,
  selectedRowData,
  catDetails,
}) => {
  return (
    <div style={{ display: "none" }}>
      <div ref={printSectionRef}>
        <Col span={24}>
          <div className="d-flex justify-content-center mb-1 font-bold h4">
            {catDetails?.name}
          </div>
        </Col>
        {selectedRowData.map((matchData, index) => (
          <KumitiScoreSheet key={index} matchData={matchData} />
        ))}
      </div>
    </div>
  );
};

const EventCategoryMatch = () => {
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [modelOpen, setModalOpen] = useState(false);
  const [matches, setMatches] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [mount, setMount] = useState(false);

  const componentRef = useRef();
  const printSectionRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = useParams();

  const [groupMatches, setGroupMatches] = useState([]);
  const [groupMatchesRound1, setGroupMatchesRound1] = useState([]);

  const [catDetails, setCatDetails] = useState({});
  const [openkataScorePanel, setOpenkataScorePanel] = useState(false);

  const [editableRowKey, setEditableRowKey] = useState(null);
  const [editablePlayer, setEditablePlayer] = useState(null);
  const [selectedPlayers, setSelectedPlayers] = useState({
    player1: "",
    player2: "",
  });
  const [playerOptions, setPlayerOptions] = useState([]);
  const [type, setType] = useState("");
  const [searchText, setSearchText] = useState("");


  const stateData = location?.state;
  const categoryName = stateData?.name;

  const getMatches = async () => {
    try {
      const res = await fixtures.getMatches(query?.categoryId);
      setMatches(res?.data);
      setMount(true);
    } catch (err) {
      notify("error", "something went wrong");
    }
  };

  useEffect(() => {
    getMatchesByGroupName(query?.categoryId, selectedGroup);
  }, [])
  
   const handleSearch = debounce((value) => {
      getMatchesByGroupName(query?.categoryId, selectedGroup, value);
    },500);

  const getGroups = async (req, res) => {
    try {
      const res = await fixtures.getGroups(query?.categoryId);
      setGroups(res?.data?.group);
    } catch (err) {
      notify("error", "something went wrong");
    }
  };

  const getMatchesByGroupName = async (categoryId, groupName,searchText="") => {
    try {
      const res = await fixtures.getMatchesByGroupName(categoryId, groupName, searchText);
      setGroupMatches(res?.data);
      // console.log('result', res)
    } catch (err) {
      notify("error", "something went wrong");
    }
  };

  // console.log("selectedGroup",selectedGroup);

  const getEventParticipants = async (catId,selectedGroup) => {
    // console.log("groupName",selectedGroup)
    try {
      const res = await event.getEventParticipants(catId,selectedGroup);
      setPlayerOptions(res?.data?.assignedParticipantIds);
    } catch (err) {
      notify("error", "something went wrong");
    }
  };

  const getCategoryById = async () => {
    try {
      const res = await event.getCategoryById(query?.categoryId);
      setCatDetails(res?.data);
      setType(res?.data?.category?.type);
    } catch (err) {
      notify("error", "something went wrong");
    }
  };

  const editFixtureParticipants = async (fixtureId, payload) => {
    try {
      const res = await fixtures.editFixtureParticipants(fixtureId, payload);
      notify("success", "Participants updated successfully");
      getMatchesByGroupName(query?.categoryId, selectedGroup);
    } catch (err) {
      notify("error", err);
    }
  };

  // const handleEdit = (key) => {
  //   setEditableRowKey(key);
  //   // const row = data.find((item) => item.key === key);
  //   // setSelectedPlayers({ player1: row.player1, player2: row.player2 });
  // };

  const handleEdit = (key, player) => {
    setEditableRowKey(key);
    setEditablePlayer(player);
  };

  const handleSave = (fixtureId,player) => {
 
    if (player==="player2" && selectedPlayers?.player2 ) {
      editFixtureParticipants(fixtureId, {
        participant: selectedPlayers?.player2 ,
        selectedParticipant: "participant2",
      });
    }
    if (player==="player1" && selectedPlayers?.player1) {
      editFixtureParticipants(fixtureId, {
        participant: selectedPlayers?.player1 ,
        selectedParticipant: "participant1",
      });
    }

    setEditableRowKey(null);
    setEditablePlayer(null);
    setSelectedPlayers({});  
  };

  // console.log('selectedPlayers', selectedPlayers);


  const columns = [
    // {
    //   title: "Player 1",
    //   dataIndex: "player1",
    //   key: "player1",
    //   align: "start",
    //   render: (text, record) =>
    //     editableRowKey === record.key && text !== "bye" ? (
    //       <Select
    //         placeholder="Select a player"
    //         //   value={role}
    //         showSearch
    //         onChange={(e) => {
    //           setSelectedPlayers((prev) => ({ ...prev, player1: e }));
    //         }}
    //       >
    //         {playerOptions.map((player) => (
    //           <Select.Option key={player._id} value={player._id}>
    //             {player.firstName + " " + player.lastName}
    //           </Select.Option>
    //         ))}
    //       </Select>
    //     ) : (
    //       text
    //     ),
    // },
    // {
    //   title: "Player 2",
    //   dataIndex: "player2",
    //   key: "player2",
    //   align: "center",
    //   render: (text, record) =>
    //     editableRowKey === record.key && text !== "bye" ? (
    //       <Select
    //         placeholder="Select a player"
    //         //   value={role}
    //         showSearch
    //         onChange={(e) => {
    //           setSelectedPlayers((prev) => ({ ...prev, player2: e }));
    //         }}
    //       >
    //         {playerOptions.map((player) => (
    //           <Select.Option key={player._id} value={player._id}>
    //             {player.firstName + " " + player.lastName}
    //           </Select.Option>
    //         ))}
    //       </Select>
    //     ) : (
    //       text
    //     ),
    // },
    {
      title: "Player 1",
      dataIndex: "player1",
      key: "player1",
      align: "start",
      render: (text, record) => {
       
        return (
          editableRowKey === record.key &&
          editablePlayer === "player1" &&
          (record?.matchType == "bye" && record.status == "Completed" && record?.player1!=="bye" || record?.matchType == "match" && record.status == "Draft" && record?.player1!=="bye" ) ? (
            <>
              <Select
                placeholder="Select a player"
                style={{width:'120px'}}
                showSearch
                onChange={(e) => {

                  setSelectedPlayers((prev) => ({ ...prev, player1: e }));
                }}
                value={selectedPlayers.player1 || record.player1}
              >
                {playerOptions.map((player) => (
                  <Select.Option key={player.id} value={player.id}>
                    {player.firstName ? player.firstName + " " + player.lastName :player?.name}
                  </Select.Option>
                ))}
              </Select>
              <FaCheck
                color="blue"
                onClick={() => handleSave(record?.fixtureId,editablePlayer)}
                style={{ marginLeft: 8, cursor: "pointer" }}
              />
            </>
          ) : (
            <span>
              {text}
               
              { (record?.matchType == "bye" && record.status == "Completed" && record?.player1!=="bye" || record?.matchType == "match" && record.status == "Draft" && record?.player1!=="bye") && 
              <EditOutlined
                onClick={() => handleEdit(record.key, "player1")}
                style={{ marginLeft: 8, cursor: "pointer", color: "blue" }}
              />
            }
            </span>
          )
        );
      }
      
     
    },
    {
      title: "Player 2",
      dataIndex: "player2",
      key: "player2",
      align: "center",
      render: (text, record) => {
        return (
          editableRowKey === record.key &&
          editablePlayer === "player2" &&
          (record?.matchType == "bye" && record.status == "Completed" && record?.player2!=="bye" || record?.matchType == "match" && record.status == "Draft" && record?.player2!=="bye" ) ? (
            <>
              <Select
                placeholder="Select a player"
                showSearch
                style={{width:'120px'}}
                onChange={(e) => {
                  setSelectedPlayers((prev) => ({ ...prev, player2: e }));
                }}
                value={selectedPlayers?.player2 || record?.player2}
              >
                {playerOptions.map((player) => (
                  <Select.Option key={player.id} value={player.id}>
                     {player.firstName ? player.firstName + " " + player.lastName :player?.name}
                  </Select.Option>
                ))}
              </Select>
              <FaCheck
                color="blue"
                onClick={() => handleSave(record?.fixtureId,editablePlayer)}
                style={{ marginLeft: 8, cursor: "pointer" }}
                
              />
            </>
          ) : (
            <span>
              {text}
              { (record?.matchType == "bye" && record.status == "Completed" && record?.player2!=="bye" || record?.matchType == "match" && record.status == "Draft" && record?.player2!=="bye") && 
              <EditOutlined
                onClick={() => handleEdit(record.key, "player2")}
                style={{ marginLeft: 8, cursor: "pointer", color: "blue" }}
              />
      }
            </span>
          )
        );
      }
      
    },
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
    },
    {
      title: "Type",
      dataIndex: "matchType",
      key: "matchType",
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      width: 120,
      align: "center",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (status) => {
        if (!status) return "-";
        let color = "orange";
        if (status === "Completed") {
          color = "green";
        } else if (status === "Ongoing") {
          color = "blue";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Winner",
      key: "winner",
      dataIndex: "winner",
      width: 120,
      align: "center",
    },

    {
      title: "Score Panel",
      key: "scorePanel",
      dataIndex: "scorePanel",
      align: "center",
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   align: "center",
    //   render: (_, record) =>
    //     editableRowKey === record.key ? (
    //       <Button type="link" onClick={() => handleSave(record)}>
    //         Save
    //       </Button>
    //     ) : (
    //       <Button type="link" onClick={() => handleEdit(record.key)}>
    //         Edit
    //       </Button>
    //     ),
    // },
  ];

  let data = [];

  let index = 0;

  for (let item of groupMatches) {
    if (item?.participant1?.name) {
      item.participant1.firstName = item?.participant1?.name || "";
      item.participant1.lastName = "";
    }
    if (item?.participant2?.name) {
      item.participant2.firstName = item?.participant2?.name || "";
      item.participant2.lastName = "";
    }
    if (item?.winner?.name) {
      item.winner.firstName = item?.winner?.name || "";
      item.winner.lastName = "";
    }
    if (item?.round !== 5)
      data?.push({
        key: index,
        player1: item?.participant1?.firstName
          ? `${item?.participant1?.firstName} ${item?.participant1?.lastName}`
          : item?.participant1?.firstName == undefined &&
            item?.participant2?.firstName &&
            (item?.type == "bye" || item?.type == null)
            ? "bye"
            : "-",
        player2: item?.participant2?.firstName
          ? `${item?.participant2?.firstName} ${item?.participant2?.lastName}`
          : item?.participant2?.firstName == undefined &&
            item?.participant1?.firstName &&
            (item?.type == "bye" || item?.type == null)
            ? "bye"
            : "-",
        winner: item?.winner?._id
          ? `${item?.winner?.firstName} ${item?.winner?.lastName}`
          : "-",
        status: item?.winner?._id ? "Completed" : "Draft",
        group: item?.groupName,
        matchType: item?.type || "bye",

        scorePanel: (
          <Button
            onClick={() =>
              history.push(
                path().KUMITI_SCORE_PANEL(query?.categoryId, item?._id)
              )
            }
          >
            {item?.winner?._id ? "Result Sheet" : "Score Panel"}
          </Button>
        ),
        firstStrike: item?.firstStrike,
        scoreSheet: item?.scoreSheet,
        participant1: item?.participant1,
        participant2: item?.participant2,
        fixtureId: item?._id,
        // winner: item?.winner,
      });
    index++;
  }

  const rowSelection = {
    selectedRowKeys,
    selectedRowData,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
      setSelectedRowData(selectedRows);
    },
  };

  const generateFixtures = async () => {
    try {
      const res = await fixtures.generateFixture({ catId: query?.categoryId });
      if (res?.data?.isTournamentRunning) {
        notify(
          "success",
          "Due to the active tournament, a shuffle is not possible at this moment."
        );
      }
      getGroups();
    } catch (err) { }
  };

  const eventDataOverviewdata = useSelector(
    (state) => state?.breadcrumb?.breadcrumbItems
  );

  const printFn = useReactToPrint({
    contentRef: printSectionRef,
    documentTitle: "MatchScoreSheets",
  });

  const handleSyncParticipants = async () => {
    try {
      const res = await event.syncParticipant(query?.categoryId);
      notify("success", res?.data?.message);
    } catch (err) { }
  };

  const onDeleteKumiteMatches = async () => {
    try {
      const res = await fixtures.deletekumiteMatches({
        matchIds: selectedRowKeys,
      });
      notify("success", "Selected Match Deleted.");
    } catch (error) { }
  };

  useEffect(() => {
    if (!eventDataOverviewdata?.subtitle.includes("Matches")) {
      dispatch(
        setBreadcrumb({
          title: `${eventDataOverviewdata?.title}`,
          subtitle: `${eventDataOverviewdata?.subtitle
            .split("/")[0]
            .trim()} / ${categoryName} / Matches`,
          url: "",
        })
      );
    }
    const baseurl = window.location;
    if (baseurl.pathname.includes("/kata-panel")) {
      setOpenkataScorePanel(true);
    }

    getGroups();
    getCategoryById();
    if(selectedGroup){
      getEventParticipants(query?.categoryId,selectedGroup);
    }
  }, []);

  useEffect(() => {
    if (groups.length > 0) {
      setSelectedGroup(groups[0]);
      getMatchesByGroupName(query?.categoryId, groups[0]);
    }
  }, [groups]);
  
  useEffect(() => {
    if (selectedGroup) {
      getEventParticipants(query?.categoryId,selectedGroup);
    }
  }, [selectedGroup]);


  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 325px)", x: true } : null;

  return (
    <>
      {type === "kumite" && (
        <Row>
          {modelOpen && (
            <GenerateFixtureModal
              groups={groups}
              catId={query?.categoryId}
              modelOpen={modelOpen}
              setModalOpen={setModalOpen}
            />
          )}
          <RenderPrintSection
            catDetails={catDetails}
            printSectionRef={printSectionRef}
            selectedRowData={selectedRowData}
          />
          <Col
            span={24}
            className="header-control"
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              {selectedRowKeys.length === 0 && (
                <>
                  <Select
                    placeholder="Select group"
                    value={selectedGroup}
                    onChange={(e) => {
                      // fetchOfficial(e);
                      // form.resetFields(["team"]);
                      setSelectedGroup(e);
                      getMatchesByGroupName(query?.categoryId, e);
                    }}
                    className="list-header"
                    style={{ marginRight: "6px", width: "150px" }}
                  >
                    {groups.map((group, index) => (
                      <Select.Option key={index} value={group}>
                        {group}
                      </Select.Option>
                    ))}
                  </Select>
                  <Button
                    style={{ marginLeft: 3, marginRight: 3 }}
                    type="primary"
                    onClick={handleSyncParticipants}
                  >
                    Sync
                  </Button>
                  <Button
                    style={{ marginLeft: 3, marginRight: 3 }}
                    type="primary"
                    onClick={() => generateFixtures()}
                  >
                    {data?.length ? "Shuffle Fixtures" : "Generate  Fixtures"}
                  </Button>
                  {data?.length ? (
                    <Button
                      style={{ marginLeft: 3, marginRight: 3 }}
                      type="primary"
                      onClick={() => setModalOpen(true)}
                    >
                      Download Fixtures
                    </Button>
                  ) : (
                    ""
                  )}
                </>
              )}
              {selectedRowKeys.length > 0 && (
                <Button
                  onClick={printFn}
                  // className="button-29"
                  type="primary"
                  style={{ marginLeft: 3, marginRight: 3 }}
                >
                  Print Selected Matches
                </Button>
              )}
              {selectedRowKeys.length > 0 && (
                <Button
                  onClick={onDeleteKumiteMatches}
                  type="primary"
                  style={{ marginLeft: 3, marginRight: 3 }}
                >
                  Delete Selected Matches
                </Button>
              )}
            </div>

            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ width: "300px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Col>
          <Col span={24}>
            <CustomTable>
              <Table
                columns={columns}
                dataSource={data}
                tableLayout="auto"
                scroll={scroll}
                pagination={{
                  defaultPageSize: 10,
                  defaultCurrent: 1,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100"],
                  total: data.length, //count
                  onChange: (currentPage, size) => {
                    setPagination({ size, currentPage });
                  },
                  showTotal: (total, range) => (
                    <ShowTotal
                      selectedRowKeys={selectedRowKeys}
                      total={total}
                      currentPageItem={range[1] - range[0] + 1}
                      ranged={range}
                    />
                  ),
                }}
                rowSelection={rowSelection}
              />
            </CustomTable>
          </Col>
          <Col>
            <EventMatchFixture
              groupMatches={groupMatches}
              groupMatchesRound1={groupMatchesRound1}
              catId={query?.categoryId}
              getMatchesByGroupName={getMatchesByGroupName}
              selectedGroup={selectedGroup}
            />
          </Col>
        </Row>
      )}
      {type === "kata" && (
        <Row>
          <Col span={24}>
            <KataScoreTable
              catDetails={catDetails}
              catId={query?.categoryId}
              openkataScorePanel={openkataScorePanel}
              setOpenkataScorePanel={setOpenkataScorePanel}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default EventCategoryMatch;

const GenerateFixtureModal = ({ modelOpen, setModalOpen, groups, catId }) => {
  const downloadFixturePdf = async (name) => {
    try {
      const res = await fixtures.downloadFixturePdf(catId, {
        groupName: name,
      });

      const blob = new Blob([res.data], { type: "application/pdf" });
      saveAs(blob, `${name}.pdf`);
    } catch (err) {
      notify("error", "something went wrong");
    }
  };

  return (
    <Modal
      open={modelOpen}
      centered
      title=<h4>Generate Fixture</h4>
      onCancel={() => setModalOpen(false)}
      footer={false}
    >
      <Col
        className="ml-10"
        style={{ maxHeight: "200px", overflowY: "scroll", overflowX: "hidden" }}
      >
        <Row>
          {groups.map((group) => (
            <Col
              span={11}
              className="d-flex"
              style={{
                // backgroundColor: "#1890ff",

                margin: "6px",
                padding: "0px 0px 0px 8px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "6px",
                //   border:"1px solid #1890ff",
                gap: "10px",
              }}
              key={group}
            >
              <h4
                className="list-header m-0"
                style={{
                  padding: "10px 12px",
                  width: "55%",
                  backgroundColor: "#e9ecef",
                  textAlign: "center",
                }}
              >
                {" "}
                {group}
              </h4>{" "}
              <div
                style={{
                  cursor: "pointer",
                  backgroundColor: "#e9ecef",
                  padding: "6px 12px",
                  borderRadius: "0px 0px",
                }}
              >
                <FiDownload
                  onClick={() => downloadFixturePdf(group)}
                  size={22}
                />{" "}
              </div>
            </Col>
          ))}
        </Row>
      </Col>
      <Divider />
      <Row style={{ justifyContent: "center" }}>
        <Col className="text-center" span={12}>
          <button
            key="submit"
            type="primary"
            onClick={() => {
              setModalOpen(false);
            }}
            className="px-30 ml-10"
          >
            Cancel
          </button>
        </Col>
      </Row>
    </Modal>
  );
};

const KataScoreTable = ({
  catId,
  catDetails,
  openkataScorePanel,
  setOpenkataScorePanel,
}) => {
  const [apiData, setApiData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [selectedMatchData, setSelectedMatchData] = useState({});
  const [showJudgeNames, setshowJudgeNames] = useState(false);
  const kataScoreRef = useRef(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [groupMatches, setGroupMatches] = useState([]);

  const [selectedGroupValue, setSelectedGroupValue] = useState("group 1");

  const [groups, setGroups] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const query = useParams();

  const openInNewTab = (id) => {
    // const url = `${window.location}/kata-panel`;
    // window.open(url, "_blank");
    const url = `/pages/events/all-events/category/${catId}/matches/kata-panel`;
    window.open(url, "_blank");
    // setOpenkataScorePanel(true);
  };


  const openRankInNewTab = () => {
    // const url = `${window.location}/kata-panel`;
    // window.open(url, "_blank");
    const url = `/pages/events/all-events/category/${query?.eventId}/${catId}/${selectedGroupValue}/matches/kata-rank-panel`;
    window.open(url, "_blank");
    // setOpenkataScorePanel(true);
  };

  const getKataScoreData = async (catId) => {
    try {
      const res = await fixtures.getKataScoreSheetData(catId);
      setApiData(res?.data);
    } catch (e) { }
  };

  let data = [];

  useEffect(() => {
    for (let item of groupMatches) {
      const formattedTotal = item?.scoreSheet?.totalScore;
      data?.push({
        key: item?._id,
        _id: item?._id,
        playerName:
          item?.participant1?.name ||
          item?.participant1?.firstName + " " + item?.participant1?.lastName,
        school:
          item?.participant1?.instituteId?.name ||
          item?.participant1?.institute?.name,
        kataName: item?.scoreSheet?.kataName || "",
        judge1: item?.scoreSheet?.judge1,
        judge2: item?.scoreSheet?.judge2,
        judge3: item?.scoreSheet?.judge3,
        judge4: item?.scoreSheet?.judge4,
        judge5: item?.scoreSheet?.judge5,
        total: formattedTotal ? formattedTotal.toFixed(2) : 0.0,
        scorePanel: (
          // <Link
          // to={{
          //   pathname: `/pages/events/all-events/category/${catId}/matches/kata-panel`,
          //   state: {matchData:item},
          // }}
          // >
          <Button
            type="primary"
            onClick={() => {
              setSelectedMatchData(item);
              openInNewTab(item?._id);
              // setOpenkataScorePanel(true);
            }}
          >
            Score Panel
          </Button>
          // </Link>
        ),
        minPoints: item?.subEvent?.pointLimit?.min,
        maxPoints: item?.subEvent?.pointLimit?.max,
        rank: item?.rank || null,
        isEditing: false,
        isActive: item?.active,
        showJudges: false,
        judgesDetails: item?.scoreSheet?.judgeDetails,
        judgeName1: item?.scoreSheet?.judgeDetails?.judge1
          ? item?.scoreSheet?.judgeDetails?.judge1?.firstName +
          " " +
          item?.scoreSheet?.judgeDetails?.judge1?.lastName
          : "-",
        judgeName2: item?.scoreSheet?.judgeDetails?.judge2
          ? item?.scoreSheet?.judgeDetails?.judge2?.firstName +
          " " +
          item?.scoreSheet?.judgeDetails?.judge2?.lastName
          : "-",
        judgeName3: item?.scoreSheet?.judgeDetails?.judge3
          ? item?.scoreSheet?.judgeDetails?.judge3?.firstName +
          " " +
          item?.scoreSheet?.judgeDetails?.judge3?.lastName
          : "-",
        judgeName4: item?.scoreSheet?.judgeDetails?.judge4
          ? item?.scoreSheet?.judgeDetails?.judge4?.firstName +
          " " +
          item?.scoreSheet?.judgeDetails?.judge4?.lastName
          : "-",
        judgeName5: item?.scoreSheet?.judgeDetails?.judge5
          ? item?.scoreSheet?.judgeDetails?.judge5?.firstName +
          " " +
          item?.scoreSheet?.judgeDetails?.judge5?.lastName
          : "-",
      });
    }
    setDataSource(data);
  }, [groupMatches]);

  const handleEditPosition = (key, value) => {
    setDataSource((prevData) =>
      prevData.map((item) =>
        item.key === key ? { ...item, rank: value } : item
      )
    );
  };

  // const handleEdit = (record) => {
  //   const newData = dataSource.map((item) => ({
  //     ...item,
  //     isEditing: item._id === record._id,
  //     showJudges: item._id === record._id,
  //   }));
  //   setEditingKey(record._id);
  //   setDataSource(newData);
  // };

  const handleEdit = (record) => {
    setDataSource((prevData) =>
      prevData.map((item) =>
        item.key === record.key ? { ...item, isEditing: !item.isEditing } : item
      )
    );
  };

  const handleSave = (record) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => item.key === record.key);

    newData[index].total =
      newData[index].judge1 +
      newData[index].judge2 +
      newData[index].judge3 +
      newData[index].judge4 +
      newData[index].judge5;

    newData[index].isEditing = false;
    newData[index].showJudges = false;
    setDataSource(newData);
    setEditingKey("");

    const payload = {
      // _id: newData[index]._id, // match Id
      judge1: newData[index].judge1 || 0,
      judge2: newData[index].judge2 || 0,
      judge3: newData[index].judge3 || 0,
      judge4: newData[index].judge4 || 0,
      judge5: newData[index].judge5 || 0,
      kataName: newData[index].kataName || 0,
      rank: newData[index].rank || null,
    };

    onUpdateKateScore(newData[index].key, payload);
  };

  const onUpdateKateScore = async (id, payload) => {
    try {
      const res = await fixtures.updateKataScore(id, payload);
      getMatchesByGroupName(catId, selectedGroupValue);
    } catch (err) {
      notify("error", err?.data?.message);
    }
  };

  const handleInputChange = (e, id, field, minPoints, maxPoints) => {
    let value = parseFloat(e.target.value);
    if (value < minPoints) {
      value = minPoints;
    } else if (value > maxPoints) {
      value = maxPoints;
    }
    const newData = [...dataSource];
    const index = newData.findIndex((item) => item._id === id);
    newData[index][field] = value || 0;

    setDataSource(newData);
  };

  const handleSelectInputChange = (e, id, field) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => item._id === id);
    newData[index][field] = e;
    setDataSource(newData);
  };

  const getMaxAndMinIndices = (scores) => {
    const max = Math.max(...scores);
    const min = Math.min(...scores);
    const maxIndex = scores.findIndex((score) => score === max);
    const minIndex = scores.findIndex((score) => score === min);
    return { maxIndex, minIndex };
  };

  const renderEditableCell = (text, record, field, judgeName) => {
    const scores = [
      record.judge1,
      record.judge2,
      record.judge3,
      record.judge4,
      record.judge5,
    ];

    const { maxIndex, minIndex } = getMaxAndMinIndices(scores);
    const fieldIndex = parseInt(field.replace("judge", ""), 10) - 1;

    const getCircleStyle = () => {
      const baseStyle = {
        display: "inline-block",
        width: "30px",
        height: "30px",
        lineHeight: "30px",
        borderRadius: "50%",
        textAlign: "center",
        verticalAlign: "middle",
      };

      if (fieldIndex === maxIndex) {
        return { ...baseStyle, backgroundColor: "red", color: "white" };
      }
      if (fieldIndex === minIndex) {
        return { ...baseStyle, backgroundColor: "yellow", color: "black" };
      }
      return {};
    };

    const circleStyle = getCircleStyle();

    return record.isEditing ? (
      <Input
        value={text}
        onChange={(e) =>
          handleInputChange(
            e,
            record._id,
            field,
            record.minPoints,
            record.maxPoints
          )
        }
        type="number"
        step="0.1"
        min={record?.minPoints}
        max={record?.maxPoints}
      />
    ) : (
      <div style={{ width: "80px", textAlign: "center" }}>
        <div style={circleStyle}>{text}</div>
        {showJudgeNames && <div>{judgeName}</div>}
      </div>
    );
  };

  const renderSelectEditableCell = (text, record, field) => {
    const isValidValue = kataListName?.some((item) => item?.value.toLowerCase() === text.toLowerCase());
    return record.isEditing ? (
      <Select
        style={{ width: "150px" }}
        value={isValidValue ? text : undefined}
        onChange={(e) => handleSelectInputChange(e, record._id, field)}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children?.toLowerCase().includes(input.toLowerCase())
        }
        placeholder="Select an Option"
      >
        {kataListName?.map((item) => (
          <Select.Option value={item?.value} key={item?.label}>
            {item?.value}
          </Select.Option>
        ))}
      </Select>
    ) : (
      <div style={{ width: "80px", textAlign: "center" }}>{text ? text : "-"}</div>
    );
  };

  const handleHideJudgeName = (record) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => item._id === record._id);
    newData[index].showJudges = false;
    setDataSource(newData);
  };

  const handleShowJudgeName = (record) => {
    const newData = dataSource.map((item) => ({
      ...item,
      showJudges: item._id === record._id,
    }));

    setDataSource(newData);
  };

  const updateKataMatchStatus = async (matchId, status) => {
    try {
      const res = await fixtures.updateKataMatchStatus(matchId, {
        active: status,
      });
      if (status === true) localStorage.setItem("kata-match-id", matchId);
      else {
        localStorage.removeItem("kata-match-id");
      }

      getMatchesByGroupName(catId, selectedGroupValue);
      // getKataMatchStatus(matchId)
    } catch (err) {
      // notify("error", err);
    }
  };

  const getKataMatchStatus = async (matchId) => {
    try {
      const res = await fixtures.getKataMatchStatus(matchId);

      // setMatchStatus(res.active);
    } catch (err) { }
  };

  const columns = [
    {
      title: "Name of Player",
      dataIndex: "playerName",
      key: "playerName",
      render: (text, record) => <div style={{ width: "150px" }}>{text}</div>,
      width: 200,
    },
    {
      title: "School/Club",
      dataIndex: "school",
      key: "school",
      render: (text, record) => <div style={{ width: "150px" }}>{text?text:"-"}</div>,
      width: 150,
      align: "center",
    },
    {
      title: "Kata Name",
      dataIndex: "kataName",
      key: "kataName",
      width: 150,
      render: (text, record) =>
        renderSelectEditableCell(text, record, "kataName"),
      align: "center",
    },
    {
      title: "Judge 1",
      dataIndex: "judge1",
      key: "judge1",
      width: 120,
      render: (text, record) =>
        renderEditableCell(text, record, "judge1", record?.judgeName1),
      align: "center",
    },
    {
      title: "Judge 2",
      dataIndex: "judge2",
      key: "judge2",
      width: 120,
      render: (text, record) =>
        renderEditableCell(text, record, "judge2", record?.judgeName2),
      align: "center",
    },
    {
      title: "Judge 3",
      dataIndex: "judge3",
      key: "judge3",
      width: 120,
      render: (text, record) =>
        renderEditableCell(text, record, "judge3", record?.judgeName3),
      align: "center",
    },
    {
      title: "Judge 4",
      dataIndex: "judge4",
      key: "judge4",
      width: 120,
      render: (text, record) =>
        renderEditableCell(text, record, "judge4", record?.judgeName4),
      align: "center",
    },
    {
      title: "Judge 5",
      dataIndex: "judge5",
      key: "judge5",
      width: 120,
      render: (text, record) =>
        renderEditableCell(text, record, "judge5", record?.judgeName5),
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: 120,
      render: (text, record) => <div style={{ width: "80px" }}>{text}</div>,
      align: "center",
      sorter: (a, b) => parseFloat(a.total) - parseFloat(b.total),
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      width: 120,
      render: (text, record) => {
        let medalIcon, rankText;
        switch (record.rank) {
          case 1:
            medalIcon = (
              <FaMedal style={{ color: "gold", fontSize: "1.5em" }} />
            );
            rankText = "1st";
            break;
          case 2:
            medalIcon = (
              <FaMedal style={{ color: "silver", fontSize: "1.5em" }} />
            );
            rankText = "2nd";
            break;
          case 3:
            medalIcon = (
              <FaMedal style={{ color: "brown", fontSize: "1.5em" }} />
            );
            rankText = "3rd";
            break;
          case 4:
            medalIcon = (
              <FaMedal style={{ color: "brown", fontSize: "1.5em" }} />
            );
            rankText = "4th";
            break;
          default:
            medalIcon = null;
            rankText = record.rank;
        }

        return (
          <div style={{ width: "100px" }}>
            {record.isEditing ? (
              <Select
                value={record.rank}
                onChange={(value) => handleEditPosition(record.key, value)}
                style={{ width: "100px" }}
              >
                <Option value={1}>1st</Option>
                <Option value={2}>2nd</Option>
                <Option value={3}>3rd</Option>
                <Option value={4}>4th</Option>
              </Select>
            ) : (
              <>
                {rankText} {medalIcon}
              </>
            )}
          </div>
        );
      },
      align: "center",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "active",
      width: 120,
      render: (text, record) => (
        <div style={{ width: "80px" }}>{text ? "Yes" : "No"}</div>
      ),
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (text, record) => (
        <div style={{ width: "150px" }}>
          {record.isEditing ? (
            <Button
              style={{ margin: "2px 2px" }}
              type="primary"
              onClick={() => handleSave(record)}
            >
              Save
            </Button>
          ) : (
            <Button
              style={{ margin: "2px 2px" }}
              type="primary"
              onClick={() => handleEdit(record)}
            >
              Edit
            </Button>
          )}
          {!record?.isActive ? (
            <Button
              style={{ margin: "2px 2px" }}
              type="primary"
              onClick={() => {
                updateKataMatchStatus(record.key, true);
              }}
            >
              Start
            </Button>
          ) : (
            <Button
              style={{ margin: "2px 2px" }}
              type="danger"
              onClick={() => {
                updateKataMatchStatus(record.key, false);
              }}
            >
              Stop
            </Button>
          )}
        </div>
      ),
      align: "center",
    },
    // {
    //   title: "Score Panel",
    //   dataIndex: "scorePanel",
    //   key: "scorePanel",
    // },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => { },
    onSelectAll: (selected, selectedRows, changeRows) => { },
    handleChange: (a, b, c) => { },
  };

  const onGenerateFixture = async () => {
    try {
      const res = await fixtures.generateKataMatchFixture(catId);
      getMatchesByGroupName(catId, selectedGroupValue);
    } catch (err) {
      notify("error", "something went wrong");
    }
  };
  const user = extractToken();
  const hasData = dataSource.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 400px)", x: true } : null;

  const printFn = useReactToPrint({
    contentRef: kataScoreRef,
    documentTitle: "MatchScoreSheets",
  });

  const onDeleteKataMatches = async () => {
    try {
      const res = await fixtures.deletekataMatches({
        matchIds: selectedRowKeys,
      });
      notify("success", "Selected Match Deleted.");
    } catch (error) { }
  };

  const getGroups = async (req, res) => {
    try {
      const res = await fixtures.getFixturekataGroup(query?.categoryId);
      setGroups(res?.data?.group);
    } catch (err) { }
  };

  const getMatchesByGroupName = async (categoryId, groupName) => {
    try {
      const res = await fixtures.getKataMatchesByGroupName(
        categoryId,
        groupName
      );
      setGroupMatches(res?.data);
    } catch (err) { }
  };

  const moveToFinalGroup = async () => {
    try {
      const res = await fixtures.kataMoveToFinalGroup(query?.categoryId, {
        matchIds: selectedRowKeys,
      });
      notify("success", "Selected Matches Moved to Final Group.");
    } catch (error) { }
  };

  useEffect(() => {
    if (groups?.length > 0) {
      getMatchesByGroupName(query?.categoryId, "group 1");
    }
  }, [groups]);






  useEffect(() => {
    getMatchesByGroupName(catId, selectedGroupValue);
    const baseurl = window.location;
    if (baseurl.pathname.includes("/kata-panel")) {
      setOpenkataScorePanel(true);
    }
    getGroups();
  }, []);


  return (
    <>
      {/* {openkataScorePanel && (
        <KataScoreDrawer
          open={openkataScorePanel}
          setOpen={setOpenkataScorePanel}
          matchData={selectedMatchData}
        />
      )} */}
      <Col span={24} style={{ textAlign: "end", marginBottom: 4 }}>
        {selectedRowKeys?.length === 0 && (
          <>
            <Select
              placeholder="Select Group"
              value={selectedGroupValue}
              onChange={(e) => {
                setSelectedGroupValue(e);
                getMatchesByGroupName(query?.categoryId, e);
              }}
              className="list-header"
              style={{ marginRight: "10px", width: "150px" }}
            >
              {groups.map((group, index) => (
                <Select.Option key={index} value={group}>
                  {group}
                </Select.Option>
              ))}
            </Select>

            <Button type="primary" onClick={onGenerateFixture}>
              Generate Fixture
            </Button>

            {/* <Button
              type="primary"
              onClick={printFn}
              style={{ marginLeft: "10px" }}
            >
              Print Score Sheet
            </Button> */}
          </>
        )}

        {selectedRowKeys?.length > 0 && (
          <Button
            type="primary"
            onClick={onDeleteKataMatches}
            style={{ marginLeft: "10px" }}
          >
            Delete Matches
          </Button>
        )}

        {selectedRowKeys?.length > 0 && groups?.length > 1 &&
          selectedGroupValue !== "final group" && (
            <Button
              type="primary"
              onClick={moveToFinalGroup}
              style={{ marginLeft: "10px" }}
            >
              Move to Final Group
            </Button>
          )}

        <Button
          type="primary"
          onClick={printFn}
          style={{ marginLeft: "10px", }}
        >
          Print Score Sheet
        </Button>

        {selectedRowKeys?.length === 0 && (
          <>
            <Button
              style={{ marginLeft: "10px", marginRight: '10px' }}
              type="danger"
              onClick={() => {
                openInNewTab();
              }}
            >
              Score Panel
            </Button>

            <Button

              //  className="mx-2"
              type="danger"
              onClick={() => {
                openRankInNewTab();
              }}
            >
              Rank Score Panel
            </Button>
          </>
        )}
      </Col>

      <Col span={24} style={{ marginBottom: "20px" }}>
        <KataCustomTable>
          <Table
            tableLayout="auto"
            scroll={scroll}
            dataSource={dataSource}
            columns={columns}
            // scroll={true}
            title={() => (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                    // border: "1px solid",
                  }}
                >
                  <img
                    src="/mpka.jpg"
                    alt="Logo 1"
                    style={{ width: "70px", height: "70px" }}
                  />
                  <div style={{ textAlign: "center" }}>
                    <h2
                      style={{
                        fontWeight: "bold",
                        fontSize: "24px",
                        margin: 0,
                      }}
                    >
                      {catDetails?.name}
                    </h2>

                    <p style={{ fontSize: "16px", margin: "5px 0" }}>
                      {moment(catDetails?.event?.startDate).format(
                        "Do MMMM YYYY"
                      )}{" "}
                      {" - "}
                      {moment(catDetails?.event?.endDate).format(
                        "Do MMMM YYYY"
                      )}
                    </p>

                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        margin: "5px 0",
                      }}
                    >
                      KATA FIXTURES
                    </p>
                    <p style={{ fontSize: "16px", margin: 0 }}>
                      Date: {moment().format("Do MMMM YYYY")}
                    </p>
                  </div>

                  <img
                    src="/bdka.jpg"
                    alt="Logo 2"
                    style={{ width: "70px", height: "70px" }}
                  />
                </div>
                {user?.role === "stateHead" && (
                  <div
                    style={{
                      // border: "1px solid",
                      padding: "0px 40px",
                      textAlign: "end",
                      cursor: "pointer",
                    }}
                  >
                    {showJudgeNames && (
                      <FiEye
                        size={24}
                        onClick={() => {
                          setshowJudgeNames(false);
                        }}
                      />
                    )}
                    {!showJudgeNames && (
                      <FiEyeOff
                        size={24}
                        onClick={() => {
                          setshowJudgeNames(true);
                        }}
                      />
                    )}
                  </div>
                )}
              </>
            )}
            pagination={false}
            rowSelection={rowSelection}
          />
        </KataCustomTable>
      </Col>

      <PrintKataTable
        kataScoreRef={kataScoreRef}
        catDetails={catDetails}
        columns={columns}
        dataSource={dataSource}
      />
    </>
  );
};

const PrintKataTable = ({ catDetails, kataScoreRef, columns, dataSource }) => {
  let newColumn = columns;
  newColumn.pop();
  newColumn.pop();
  newColumn.pop();
  return (
    <div style={{ display: "none" }}>
      <Col span={24} ref={kataScoreRef}>
        <Table
          dataSource={dataSource}
          columns={newColumn}
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <img
                src="/mpka.jpg"
                alt="Logo 1"
                style={{ width: "70px", height: "70px" }}
              />
              <div style={{ textAlign: "center" }}>
                <h2
                  style={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    margin: 0,
                  }}
                >
                  {catDetails?.name}
                </h2>

                <p style={{ fontSize: "16px", margin: "5px 0" }}>
                  {moment(catDetails?.event?.startDate).format("Do MMMM YYYY")}{" "}
                  {" - "}
                  {moment(catDetails?.event?.endData).format("Do MMMM YYYY")}
                </p>

                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    margin: "5px 0",
                  }}
                >
                  KATA FIXTURES
                </p>
                <p style={{ fontSize: "16px", margin: 0 }}>
                  Date: {moment().format("Do MMMM YYYY")}
                </p>
              </div>

              <img
                src="/bdka.jpg"
                alt="Logo 2"
                style={{ width: "70px", height: "70px" }}
              />
            </div>
          )}
          pagination={false}
        // rowSelection={rowSelection}
        />
      </Col>
    </div>
  );
};
