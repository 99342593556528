import React, { useEffect, useRef, useState } from "react";
import { KumitiScorePanelWrapper } from "../../Styled";
import { Col, Divider, Row } from "antd";
import { calculateTotalPoints } from "./KumitiScoreBoard";

const KumitiScoreSheet = ({ matchData }) => {
  const [playerWarnings, setPlayerWarnings] = useState([]);

  

  const penaltyHierarchy = {
    general: { C1: 1, C2: 2, C3: 3, HC: 4, H: 5 },
  };

  function getHighlightedButtons(penalties) {
    const maxPenaltyIndex = Math.max(
      ...penalties.map((p) => penaltyHierarchy.general[p])
    );
    return Object.keys(penaltyHierarchy.general).filter(
      (penalty) => penaltyHierarchy.general[penalty] <= maxPenaltyIndex
    );
  }

  useEffect(() => {
    if (matchData?.scoreSheet) {
      const warnings = matchData.scoreSheet.filter(
        (entry) => entry.type === "warning"
      );
      setPlayerWarnings(warnings);
    }
  }, [matchData]);

  const aoPenalties = playerWarnings
    .filter((w) => w.player === matchData?.participant1?._id)
    .map((w) => w.warningType.toUpperCase());
  const akaPenalties = playerWarnings
    .filter((w) => w.player === matchData?.participant2?._id)
    .map((w) => w.warningType.toUpperCase());

  const highlightedAoButtons = getHighlightedButtons(aoPenalties);
  const highlightedAkaButtons = getHighlightedButtons(akaPenalties);

  const handleWarningClick=()=>{}

  return (
    <KumitiScorePanelWrapper style={{ marginTop: "20px" }}>
      <Row gutter={[6, 0]}>
        <Col span={24}>
          <Divider />
        </Col>
        <Col span={24} className="d-flex justify-content-center">
          <div className="player-name">
            {matchData?.player1
              ? matchData?.player1
              : matchData?.participant1?.firstName +
                matchData?.participant1?.lastName}
          </div>
          <div style={{ margin: "0px 10px" }}>VS</div>
          <div className="player-name">
            {matchData?.player2
              ? matchData?.player2
              : matchData?.participant2?.firstName +
                matchData?.participant1?.lastName}
          </div>
        </Col>
        <Col span={12}>
          <div className="score-panel">
            <h2 className="team-name-blue">AO</h2>
            <div
              className="player-name d-flex justify-content-between align-items-center"
              style={{ padding: "8px 15px" }}
            >
              <div>
                {matchData?.participant1?.firstName +
                  " " +
                  matchData?.participant1?.lastName}
              </div>
              {matchData?.firstStrike &&
                (matchData?.firstStrike?.player ===
                  matchData?.participant1?._id ||
                  matchData?.firstStrike?.player1 ===
                    matchData?.participant1?._id) && (
                  <div className="first-strike">S</div>
                )}
            </div>
            <div className="grid-container-print">
              {[...Array(20).keys()].map((i) => (
                <div key={i + 1} className="number-block-print">
                  {i + 1}
                </div>
              ))}

              {(() => {
                let skip = 0;
                let totalPoints = 0;
                return matchData?.scoreSheet?.map((item, index) => {
                  if (
                    item?.type === "score" &&
                    item?.player === matchData?.participant1?._id
                  ) {
                    totalPoints += item?.point;

                    if (totalPoints > 20) {
                      return null;
                    }

                    const className =
                      item?.point === 1
                        ? "horizontal-line-print"
                        : item?.point === 2
                        ? "diagonal-line-print"
                        : "reverse-diagonal-line-print";

                    const lineWidth = item?.point * 19;
                    const lineLeft = skip * 19;

                    skip += item?.point;

                    return (
                      <div
                        key={index}
                        className={className}
                        style={{
                          width: `${lineWidth}px`,
                          left: `${lineLeft}px`,
                        }}
                      />
                    );
                  }
                  return null;
                });
              })()}
            </div>

            <div className="d-flex justify-content-center">
              <div className="total-score-box">
                Total Score:{" "}
                {calculateTotalPoints(matchData, matchData?.participant1?._id)}
              </div>
              {matchData?.winner?.length ? (
                matchData?.winner === matchData?.player1 ? (
                  <div className="total-score-box winner">Result: Winner</div>
                ) : (
                  <div className="total-score-box lose">Result: Lose</div>
                )
              ) : (
                <div></div>
              )}
              {matchData?.winner?._id ? (
                matchData?.winner?.firstName === matchData?.player1 ? (
                  <div className="total-score-box winner">Result: Winner</div>
                ) : (
                  <div className="total-score-box lose">Result: Lose</div>
                )
              ) : (
                <div></div>
              )}
            </div>

            <div
              className="d-flex justify-content-between"
              style={{ padding: "0 15px" }}
            >
              <div className="warnings-section">
                {["C1", "C2", "C3", "HC", "H"].map((type) => (
                  <div
                    key={type}
                    className={
                      highlightedAoButtons.includes(type)
                        ? "highlighted warning-block"
                        : "warning-block"
                    }
                    onClick={() =>
                      handleWarningClick(
                        matchData?.participant1?._id,
                        type.toLowerCase()
                      )
                    }
                  >
                    {type}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="score-panel">
            <h2 className="team-name-red">AKA</h2>

            <div
              className="player-name d-flex justify-content-between align-items-center"
              style={{ padding: "8px 15px" }}
            >
              <div>
                {matchData?.participant2?.firstName +
                  " " +
                  matchData?.participant2?.lastName}
              </div>
              {matchData?.firstStrike &&
                (matchData?.firstStrike?.player ===
                  matchData?.participant2?._id ||
                  matchData?.firstStrike?.player1 ===
                    matchData?.participant2?._id) && (
                  <div className="first-strike">S</div>
                )}
            </div>

            <div className="grid-container-print">
              {[...Array(20).keys()].map((i) => (
                <div key={i + 1} className="number-block-print">
                  {i + 1}
                </div>
              ))}

              {(() => {
                let skip = 0;
                let totalPoints = 0;
                return matchData?.scoreSheet?.map((item, index) => {
                  if (
                    item?.type === "score" &&
                    item?.player === matchData?.participant2?._id
                  ) {
                    totalPoints += item?.point;

                    if (totalPoints > 20) {
                      return null;
                    }

                    const className =
                      item?.point === 1
                        ? "horizontal-line-print"
                        : item?.point === 2
                        ? "diagonal-line-print"
                        : "reverse-diagonal-line-print";

                    const lineWidth = item?.point * 19;
                    const lineLeft = skip * 19;

                    skip += item?.point;

                    return (
                      <div
                        key={index}
                        className={className}
                        style={{
                          width: `${lineWidth}px`,
                          left: `${lineLeft}px`,
                        }}
                      />
                    );
                  }
                  return null;
                });
              })()}
            </div>

            <div className="d-flex justify-content-center">
              <div className="total-score-box">
                Total Score:{" "}
                {calculateTotalPoints(matchData, matchData?.participant2?._id)}
              </div>
              {matchData?.winner?.length ? (
                matchData?.winner === matchData?.player2 ? (
                  <div className="total-score-box winner">Result: Winner</div>
                ) : (
                  <div className="total-score-box lose">Result: Lose</div>
                )
              ) : (
                <div></div>
              )}
            </div>

            <div
              className="d-flex justify-content-between"
              style={{ padding: "0 15px" }}
            >
              <div className="warnings-section">
                {["C1", "C2", "C3", "HC", "H"].map((type) => (
                  <div
                    key={type}
                    className={
                      highlightedAkaButtons.includes(type)
                        ? "highlighted warning-block"
                        : "warning-block"
                    }
                    onClick={() =>
                      handleWarningClick(
                        matchData?.participant1?._id,
                        type.toLowerCase()
                      )
                    }
                  >
                    {type}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </KumitiScorePanelWrapper>
  );
};

export default KumitiScoreSheet;
