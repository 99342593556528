import { Table, Avatar, Col, Row, Button, Skeleton, Empty, Input } from "antd";
import { Link, useHistory } from "react-router-dom";
import { path } from "../../../../utils/path";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  capitalizeFirstLetter,
  debounce,
  notify,
  ShowTotal,
} from "../../../../utils/helper";
import event from "../../../../services/event";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
import DeleteConfirionModal from "../../../modal/DeleteConfirionModal";
import { CustomTable } from "../Styled";

const Categories = () => {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [count, setCount] = useState("");
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [searchText, setSearchText] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [givenPassword, setGivenPassword] = useState("")

  const getCategories = async (searchText = "") => {
    try {
      setLoading(true);
      let res = await event.getCategories(
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText
      );

      setApiData(res?.data?.response?.records);
      setCount(res?.data?.response?.totalItems);
      setLoading(false);
    } catch (err) {}
  };

  const handleSearch = debounce((value) => {
    getCategories(value);
  },500);

  useEffect(() => {
    getCategories();
  }, [
    pagination?.size,
    pagination?.currentPage,
    sortingColumn,
    sortingOrder,
    searchText,
  ]);

  let data = [];
  for (let item of apiData) {
    data.push({
      key: item?._id,
      name: (
        <div
          style={{
            minWidth: "180px",
            // maxWidth: "180",
            display: "flex",
            gap: "5px",
          }}
        >
          {/* <div>
            <Avatar
              shape="square"
              size={40}
              style={{
                // backgroundColor: getRandomColor(),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
              }}
              src={item?.profilePicture}
            >
              <div>{item?.name?.[0]?.toUpperCase() || "-"}</div>
            </Avatar>
          </div> */}

          <div
            className="mx-2"
            style={{ minWidth: "160px", display: "flex", alignItems: "center" }}
          >
            <Link
              // to={`/pages/events/categories/${item?._id}/category-details`}
              to={{
                pathname: `/pages/events/categories/${item?._id}/category-details`,
                state: { type: item?.type },
              }}
              style={{ cursor: "pointer" }}
              className="ellipsis"
            >
              <div
                className="ellipsis"
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  color: "var(--text-primary)",
                  lineHeight: "15px",
                  letterSpacing: "0.9px",
                }}
              >
                {item?.name || "-"}
              </div>
            </Link>
          </div>
        </div>
      ),
      nameToSort: item?.name || "-",
      type: (
        <div style={{}}>
          {item?.type ? capitalizeFirstLetter(item?.type) : "-"}
        </div>
      ),
      description: (
        <div
          style={{
            width: "300px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {item?.description ? capitalizeFirstLetter(item?.description) : "-"}
        </div>
      ),

      gender: (
        <div style={{}}>
          {item?.gender ? capitalizeFirstLetter(item?.gender) : "-"}
        </div>
      ),
      age: (
        <div style={{ width: "150px" }}>
          {item?.age?.min
            ? item?.age?.min + " to " + item?.age?.max + " years"
            : "-"}
        </div>
      ),

      team: <div style={{}}>{item?.team ? "Team" : "Individual"}</div>,

      startDate: (
        <div style={{ width: "200px" }}>
          {moment(item?.startDate).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),

      endDate: (
        <div style={{ width: "200px" }}>
          {moment(item?.endDate).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),
    });
  }

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      // className: "ant-table-row-cell-break-word",
      width: 120,
      sorter: {
        compare: (a, b) => a.nameToSort.localeCompare(b.nameToSort),
        // multiple: 3,
      },
    },
    {
      title: "TYPE",
      dataIndex: "type",
      // className: "ant-table-row-cell-break-word",
      width: 120,
      align: "center",
    },
    // {
    //   title: "DESCRIPTION",
    //   // className: "ant-table-row-cell-break-word",
    //   dataIndex: "description",
    //   width: 120,
    //   align: "center",
    //   //   sorter: {
    //   //     compare: (a, b) => a.email - b.email,
    //   //     multiple: 2,
    //   //   },
    // },

    // {
    //   title: "START DATE",
    //   dataIndex: "startDate",
    //   align: "center",
    //   width: 150,
    //   // className: "ant-table-row-cell-break-word",
    // },
    // {
    //   title: "END DATE",
    //   dataIndex: "endDate",
    //   width: 150,
    //   align: "center",
    //   // className: "ant-table-row-cell-break-word",
    // },
    // {
    //   title: "AGE LIMIT",
    //   dataIndex: "age",
    //   width: 150,
    //   align: "center",
    //   // className: "ant-table-row-cell-break-word",
    // },
    {
      title: "GENDER",
      dataIndex: "gender",
      width: 120,
      align: "center",
      // className: "ant-table-row-cell-break-word",
    },
    {
      title: "TEAM",
      dataIndex: "team",
      width: 150,
      align: "center",
      // className: "ant-table-row-cell-break-word",
    },
  ];

  const handleClick = () => {
    history.push(`/pages/events/categories/create-category`);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
  };

  const deleteCategory = async () => {
    try {
      setLoading(true);
      const res = await event.deleteCategories({
        subEventIds: selectedRowKeys,
        password: givenPassword
      });
      setLoading(true);
      setSelectedRowKeys([]);
      notify("success", "User Deleted Successfully.");
      getCategories();
      setIsModalOpen(false)
      setLoading(false);
    } catch (err) {
      notify("error", err.data.message)
    }
  };

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 330px)", x: true } : null;

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: "All Categories",
        subtitle: "",
        url: "",
      })
    );
  }, []);

  return (
    <Row>
      <Col
        span={24}
        className="header-control"
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {selectedRowKeys?.length <= 0 ? (
          <Button type="primary" className="px-25" onClick={handleClick}>
            CREATE NEW CATEGORY
          </Button>
        ) : (
          <div className="px-2">
            <Button
              type="danger"
              class="button-29"
              role="button"
              onClick={() => setIsModalOpen(true)}
            >
              DELETE CATEGORY
            </Button>
          </div>
        )}
        <Input
          className="header-search"
          placeholder="Search here..."
          prefix={<SearchOutlined />}
          style={{ width: "300px" }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Col>
      <Col span={24}>
      <CustomTable>
        <Table
          tableLayout="auto"
          columns={columns}
          dataSource={data}
          scroll={scroll}
          rowSelection={rowSelection}
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <DeleteConfirionModal
                isOpen={isModalOpen}
                setOpen={setIsModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={deleteCategory}
                messageText={
                  "Please confirm it by entering your password to take the action."
                }
                password={givenPassword}
                setPassword={setGivenPassword}
              />
            </div>
          )}
          locale={{
            emptyText: loading ? <Skeleton round active={true} /> : <Empty />,
          }}
          pagination={{
            defaultPageSize: 10,
            defaultCurrent: 1,
            showSizeChanger: true,
            pageSizeOptions: ["10","25", "50", "100"],
            total: count,
            onChange: (currentPage, size) => {
              setPagination({ size, currentPage });
            },
            showTotal: (total, range) => (
              <ShowTotal
                selectedRowKeys={selectedRowKeys}
                total={total}
                currentPageItem={range[1] - range[0] + 1}
                ranged={range}
              />
            ),
          }}
          onChange={handleChangeTable}
        />
        </CustomTable>
      </Col>
    </Row>
  );
};

export default Categories;
