import parseJwt from './parseJwt';
import { jwtDecode } from "jwt-decode";

export const setItemFromLocalStrage = (itemName, item) => localStorage.setItem(itemName, item);

export const getItemFromLocalStorage = (itemName) => localStorage.getItem(itemName);

export const clearLocalStorage = () => localStorage.clear();

export const removeItemfromLocalStorage = (itemName) => localStorage.removeItem(itemName);

export const extractToken = () => {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) return {};
  return parseJwt(accessToken);
};

export const isLogin =() => {
    const user = extractToken();
    if(user.id) return true;
    else return false;
    // const token = localStorage.getItem("accessToken");

    // if (!token) return false;

    // try {
    //   const decodedToken = jwtDecode(token);
    //   const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    //   console.log(' decodedToken.exp', decodedToken.exp);
    //   // Check if the token has expired
    //   if (decodedToken.exp && decodedToken.exp < currentTime) {
    //     localStorage.removeItem("accessToken"); // Clear expired token
    //     return false;
    //   }
  
    //   return !!decodedToken.id; // Ensure the token contains a valid user ID
    // } catch (error) {
    //   // Token is invalid or corrupted
    //   localStorage.removeItem("accessToken");
    //   return false;
    // }
}