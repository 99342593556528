import { React } from "react";
import { Button, Input, Form } from "antd";
import ModalWrapper from "./ModalWrapper";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const DeleteConfirionModal = ({
  isOpen,
  setOpen,
  onConfirm,
  onClose,
  messageText,
  password,
  setPassword,
}) => {
  if (!isOpen) return null;

  const handleDelete = () => {
    onConfirm();
    setPassword("");
  };

  return (
    <ModalWrapper
      open={isOpen}
      setOpen={setOpen}
      title={"Verify Password"}
      className="custom-modal"
    >
      <div className="modal-overview">
        <Form layout="vertical" onFinish={handleDelete}>
          <div className="modal">
            <div class="verification-text">
              <p style={{ fontSize: "18px" }}>{messageText}</p>
            </div>

            <div style={{ fontSize: "20px", marginBottom: "5px" }}>
              <span style={{ color: "red" }}>*</span>Please provide your
              password.
            </div>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password is required to proceed.",
                },
              ]}
            >
              <Input.Password
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                width={"auto"}
              />
            </Form.Item>
            <Form.Item>
              <div className="modal-buttons">
                <Button onClick={onClose}>Cancel</Button>
                <Button htmlType="submit" key="submit" type="danger">
                  Delete
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
    </ModalWrapper>
  );
};

export default DeleteConfirionModal;
