import { apiDelete, apiGet, apiPatch, apiPost, apiPostWithMultiPart } from "../utils/axios";
import { domainUrl } from "../utils/domainUrl";

export default {

    getAllEvent: (size=0, currentPage=1, sortingColumn='', sortingOrder='',query="",filterStatus=[])=> apiGet(domainUrl(`/api/v1/event?size=${size}&page=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}&registrationStatus=${filterStatus.length===0 ? "":filterStatus}`)),
    getActiveEvent: ()=> apiGet(domainUrl(`/api/v1/event/active/events`)),
    getParticipantByEventId: (id,size=0, currentPage=1, sortingColumn='', sortingOrder='',query="",coachName="",instituteName="",type="")=> apiGet(domainUrl(`/api/v1/event/participant/${id}?size=${size}&currentPage=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}&type=${type}&coachName=${coachName}&instituteName=${instituteName}`)),
    getEventById: (id,size=0, currentPage=1, sortingColumn='', sortingOrder='',query="",coachName="",instituteName="")=> apiGet(domainUrl(`/api/v1/event/${id}?size=${size}&currentPage=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}&coachName=${coachName}&instituteName=${instituteName}`)),
    // getSubCategory: (id)=> apiGet(domainUrl(`/api/v1/event/${id}/sub-category`)),
    getSubCategory: (id)=> apiGet(domainUrl(`/api/v1/event/${id}/sub-category-for-team`)),
    getEventTeamParticipants: (id,size=0, currentPage=1, sortingColumn='', sortingOrder='',query="",)=> apiGet(domainUrl(`/api/v1/event/team-participant/${id}?size=${size}&currentPage=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}`)),

    getEventByStatus: (registrationStatus)=> apiGet(domainUrl(`/api/v1/event/${registrationStatus}`)),
    deleteEventParticipants: (eventId,payload)=> apiPost(domainUrl(`/api/v1/event/remove-participant/${eventId}`), payload),


    createEvent: (payload)=> apiPost(domainUrl(`/api/v1/event`), payload),
    participateEvent: (payload)=> apiPost(domainUrl(`/api/v1/event/participate-event`), payload),
    uploadBanner: (id, payload)=> apiPostWithMultiPart(domainUrl(`/api/v1/event/upload-banner/${id}`), payload),
    downloadBanner: (id, payload)=> apiPost(domainUrl(`/api/v1/event/download-banner/${id}`), payload),
    geStudentCategory: (payload)=> apiPost(domainUrl(`/api/v1/event/student-category`), payload),
    ImportEntries: (id, payload)=> apiPostWithMultiPart(domainUrl(`/api/v1/event/upload/participants-entries/${id}`), payload),



    updateEvent: (id, paylaod)=> apiPatch(domainUrl(`/api/v1/event/${id}`), paylaod),
    statusChange: (id, paylaod)=> apiPatch(domainUrl(`/api/v1/event/status-change/${id}`), paylaod),



    deleteEvent: (payload)=> apiDelete(domainUrl(`/api/v1/event`), payload),

    getCategories: (size=0, currentPage=1, sortingColumn='', sortingOrder='',query="") => apiGet(domainUrl(`/api/v1/event/sub-events?size=${size}&page=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${encodeURIComponent(query)}`)),
    createCategory : (id,payload)=> apiPost(domainUrl(`/api/v1/event/${id}/sub-events`), payload),
    getCategoryById: (categoryId)=> apiGet(domainUrl(`/api/v1/event/sub-event/${categoryId}`)),
    // : (categoryId)=> apiGet(domainUrl(`/api/v1/event/sub-event/${categoryId}`)),:subId
    getGloablCategoryById: (categoryId)=> apiGet(domainUrl(`/api/v1/event/global-cat/${categoryId}`)),
    // updateCategory: (id,categoryId, payload)=> apiPatch(domainUrl(`/api/v1/event/${id}/sub-event/${categoryId}`), payload),
    updateCategory: (categoryId, payload)=> apiPatch(domainUrl(`/api/v1/event/sub-event/${categoryId}`), payload),
    deleteCategories: (payload)=> apiDelete(domainUrl(`/api/v1/event/sub-event`),payload),

    getTatamies : (size=0, currentPage=1, sortingColumn='', sortingOrder='',query="") => apiGet(domainUrl(`/api/v1/event/ring?size=${size}&currentPage=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${query}`)),
    createTatami : (payload)=> apiPost(domainUrl(`/api/v1/event/ring`), payload),
    assignOfficials : (payload)=> apiPatch(domainUrl(`/api/v1/event/assign-ring`), payload),
    unassignOfficials : (payload)=> apiPatch(domainUrl(`/api/v1/event/un-assign-ring`), payload),
    assignCategories : (payload)=> apiPatch(domainUrl(`/api/v1/event/assign/category/to-ring`), payload),
    unassignCategories : (payload)=> apiPatch(domainUrl(`/api/v1/event/unassign/category/from-ring`), payload),
    getTatamiById : (ringId)=> apiGet(domainUrl(`/api/v1/event/ring/${ringId}`)),

    getEventCategoryDetailsById : (eventId,size=0, currentPage=1, sortingColumn='', sortingOrder='',query="",searchFilter="")=> apiGet(domainUrl(`/api/v1/event/${eventId}/sub-category?size=${size}&page=${currentPage}&column=${sortingColumn}&order=${sortingOrder}&query=${encodeURIComponent(query)}&searchFilter=${searchFilter}`)),

    deleteTatami : (payload)=> apiDelete(domainUrl(`/api/v1/event/ring`),payload),
    updateTatami : (ringId,payload)=> apiPatch(domainUrl(`/api/v1/event/ring/${ringId}`),payload),
    startOrStopTatami : (ringId,payload)=> apiPatch(domainUrl(`/api/v1/event/play-ring/${ringId}`),payload),
    addOrRemoveCategoryInTatami : (categoryId,payload)=> apiPatch(domainUrl(`/api/v1/event/play-category/${categoryId}`),payload),
    getTatamiesOfEvent : (eventId,searchText)=> apiGet(domainUrl(`/api/v1/event/tatami/${eventId}?query=${searchText}`),),

    getEventParticipants: (catId,groupName)=> apiGet(domainUrl(`/api/v1/event/total-participant/${catId}?groupName=${groupName}`)),

    syncParticipant : (catId)=> apiGet(domainUrl(`/api/v1/event/sync-participant/${catId}`)),

    getEventByStatus: (status) => apiGet(domainUrl(`/api/v1/event/?registrationStatus=${status}`)),
    // /api/v1/event/sync-participant/67162c0ebe4dd63718dba434
    addCategoriesInEvent : (payload)=> apiPost(domainUrl(`/api/v1/event-category/create`), payload),
    deleteEventCategories: (payload)=> apiDelete(domainUrl(`/api/v1/event-category/delete`), payload),
    mapCategories : (eventId)=> apiPost(domainUrl(`/api/v1/event-category/map-student/${eventId}`)),
    getEventCategoriesByEventId: (eventId)=> apiGet(domainUrl(`/api/v1/event/event-categories/${eventId}`)),
}