import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Select,
  Checkbox,
  Button,
  Table,
  Input,
  Tabs,
  Divider,
  Skeleton,
  Empty,
  Spin,
  Col,
} from "antd";
import ModalWrapper from "../../modal/ModalWrapper";
import users from "../../../services/users";
import moment from "moment";
import event from "../../../services/event";
import teamService from "../../../services/teams";
import { debounce, notify } from "../../../utils/helper";
import { RedoOutlined, SearchOutlined } from "@ant-design/icons";
import Filter from "../../../utils/Filter";

const { Option } = Select;
const { TabPane } = Tabs;

const AddEntriesModal = ({ open, setOpen, getActiveEvent, selectedEvent,getEventById }) => {
  const [activeTab, setActiveTab] = useState("individual");
  const [selectedPlayer, setSelectedPlayer] = useState([]);
  const [categories, setCategories] = useState({
    kata: false,
    kumite: false,
  });
  const [entries, setEntries] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [selectedTeamCategory, setSelectedTeamCategory] = useState(null);
  const [teams, setTeams] = useState([]);
  const [allStudents, setAllStudents] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [studentsData, setStudentsData] = useState([]);
  const [teamSize, setTeamSize] = useState(0);
  const players = [{ id: 1, name: "Player 1" }];
  const [loading, setLoading] = useState(false);
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const teamCategories = ["Category"];
  const tableRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [maxPageSize, setMaxPageSize] = useState(1);
  const [dataOnPageIncreament, setDataOnPageIncreament] = useState(false);
  const [isSeacrching, setIsSeacrching] = useState(false);
  const [show, setShow] = useState(false);
  const [filterStatus, setFilterStatus] = useState([]);
  const [count, setCount] = useState("");
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const StudentsData = [];


  useEffect(() => {
    if (count % 10 === 0) {
      setMaxPageSize(count / 10);
    }
    if (count % 10 !== 0) {
      setMaxPageSize(Math.floor(count / 10 + 1));
    }
  }, [count]);

  const getMyStudents = async (newSearchText = searchText) => {
    setLoading(true);
    try {
      const res = await users.getMyStudents(
        selectedEvent[0],
        size,
        page,
        sortingColumn,
        sortingOrder,
        newSearchText,
        filterStatus.length ==1 ? filterStatus[0]==="Male"?filterStatus[0]:filterStatus[0] ==="Female"?filterStatus[0] :'' :"",
      );
      const newRecords = res?.data?.records || [];
      const totalItems = res?.data?.totalItems || 0;
      setCount(totalItems);
      // setAllStudents(res?.data?.students);

      if (page == 1 && !dataOnPageIncreament) {
        setAllStudents(newRecords);
      } else if (page <= maxPageSize && dataOnPageIncreament) {
        // setAllStudents((prevData) => [...prevData, ...newRecords]);
        setAllStudents((prevData) => {
          const uniqueIds = new Set(prevData.map((item) => item._id));
          const filteredNewRecords = newRecords.filter((item) => !uniqueIds.has(item._id));
          return [...prevData, ...filteredNewRecords];
        });
      } else if (page == 1 && isSeacrching) {
        setAllStudents(newRecords);
      }
      setHasMore(searchText !== "" ? false : newRecords.length > 0);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMyStudents();
  }, [pagination?.size, page, sortingColumn, sortingOrder,]);
  useEffect(() => {
    setAllStudents([]);
    setLoading(true);
    getMyStudents();
  }, [filterStatus.length]);

  const getSubCategory = async () => {
    try {
      const res = await event.getSubCategory(selectedEvent[0]);
      setCategoryData(res?.data?.response?.records);
    } catch (error) {}
  };

  const geStudentCategory = async () => {
    try {
      const res = await event.geStudentCategory({
        categoryId: selectedTeamCategory,
      });
      setStudentsData(res?.data?.students);
    } catch (error) {}
  };

  const handlePlayerSelect = (value) => {
    setSelectedPlayer(value);
  };

  const handleCategoryChange = (category, checked) => {
    setCategories((prev) => ({
      ...prev,
      [category]: checked,
    }));
  };

  const handleAddTeam = async () => {
    if (teamName && selectedTeamCategory) {
      const newTeam = {
        name: teamName,
        students: selectedPlayer,
        eventId: selectedEvent[0],
        categoryId: selectedTeamCategory,
      };
      const res = await teamService.createTeam(newTeam);
      notify("success", "Team Assigned Successfully.");
      setTeams([...teams, newTeam]);
      setTeamName("");
      setStudentsData([]);
      setSelectedTeamCategory(null);
      setOpen(false);
    }
  };

  const handleAddEntry = async () => {
    try {
      const studentsPayload = selectedRowKeys.map((id) => ({
        id: id,
        kata: categories.kata,
        kumite: categories.kumite,
      }));

      const res = await event.participateEvent({
        eventId: selectedEvent[0],
        students: studentsPayload,
      });

      notify("success", "Players assigned successfuylly.");
      setOpen(false);
      // getActiveEvent();
      getEventById("","");
    } catch (error) {}
  };

  const handleSearch = debounce((value) => {
    setAllStudents([]);
    getMyStudents(value);
    setIsSeacrching(true);
    setPage(1);
  }, 500); 


  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
    handleChange: (a, b, c) => {},
  };

  const individualColumns = [
    {
      title: "Player Name",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 3,
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      sorter: {
        compare: (a, b) => a.gender.localeCompare(b.gender),
        multiple: 3,
      },
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      sorter: {
        compare: (a, b) => new Date(a.age) - new Date(b.age),
        multiple: 3,
      },
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      sorter: {
        compare: (a, b) => a.weight - b.weight,
        multiple: 3,
      },
    },
    {
      title: "Institute",
      dataIndex: "institute",
      key: "institute",
      sorter: {
        compare: (a, b) => a.institute.localeCompare(b.institute),
        multiple: 3,
      },
    },
    {
      title: "coach",
      dataIndex: "coach",
      key: "coach",
      sorter: {
        compare: (a, b) => a.coach.localeCompare(b.coach),
        multiple: 3,
      },
    },
  ];

  const teamColumns = [
    {
      title: "Team Name",
      dataIndex: "teamName",
      key: "teamName",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Kata",
      dataIndex: "kata",
      key: "kata",
    },
    {
      title: "Kumite",
      dataIndex: "kumite",
      key: "kumite",
    },
  ];



  for (let item of allStudents || []) {
    StudentsData.push({
      key: item?._id,
      name: item?.firstName ? item?.firstName + " " + item?.lastName :"-",
      gender: item?.gender ? item?.gender :"-",
      age: moment(item?.dateOfBirth).format("DD MMM YYYY"),
      weight: item?.weight ?item?.weight+" Kg":"-",
      institute: item?.instituteId?.name,
      coach: item?.coach?.firstName ? item?.coach?.firstName + " " + item?.coach?.lastName :"-"
    });
  }

  useEffect(() => {
    // getMyStudents();
    getSubCategory();
  }, []);

  useEffect(() => {
    if (selectedTeamCategory?.length) geStudentCategory();
  }, [selectedTeamCategory]);

  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
    setAllStudents([]);
    setPage(1);
    setDataOnPageIncreament(false);
  };

  useEffect(() => {
    const tableBody = tableRef.current?.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore]);

  const handleScroll = (e) => {
    if (allStudents.length === count) {
      setHasMore(false);
      return;
    }
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 20 && !loading && hasMore) {
      if (page <= maxPageSize) {
        setDataOnPageIncreament(true);
        setIsSeacrching(false);
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  const components = {
    body: {
      wrapper: ({ children, ...restProps }) => (
        <tbody {...restProps}>
          {children}
          {hasMore && (
            <tr>
              <td
                colSpan={individualColumns.length}
                style={{ textAlign: "center" }}
              >
                <Spin
                  indicator={<RedoOutlined style={{ fontSize: 32 }} spin />}
                />
              </td>
            </tr>
          )}
        </tbody>
      ),
    },
  };

  return (
    <ModalWrapper
      title="Add Entries"
      open={open}
      setOpen={setOpen}
      footer={null}
      width={750}
    >
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Individual" key="individual">
          <div style={{ marginBottom: 16 }}>
            <Checkbox
              checked={categories.kata}
              onChange={(e) => handleCategoryChange("kata", e.target.checked)}
            >
              Kata
            </Checkbox>
            <Checkbox
              checked={categories.kumite}
              onChange={(e) => handleCategoryChange("kumite", e.target.checked)}
              style={{ marginLeft: 16 }}
            >
              Kumite
            </Checkbox>
          </div>
          <div className="d-flex" >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{
                width: "200px",
                padding: "0px 10px",
                borderRadius: "6px",
                marginRight:"10px",
              }}
              onChange={(e) => {
                handleSearch(e.target.value);
                setSearchText(e.target.value);  
              }}
              // value={searchText}
            />
            <Filter
              show={show}
              setShow={setShow}
              data={[
                {
                  title: "Gender",
                  list: ["Male", "Female"],
                  filterList: filterStatus,
                  setFilterList: setFilterStatus,
                },
              ]}
            />
          </div>

          {/* <Input
            placeholder="Search students"
            value={searchText}
            onChange={handleSearch}
            style={{ margin: 16, 0 }}
          /> */}

          <Divider />
          
        <Col span={24}>
          <div ref={tableRef}>
            <Table
              tableLayout="auto"
              components={components}
              columns={individualColumns}
              dataSource={StudentsData}
              rowSelection={rowSelection}
              onChange={handleChangeTable}
              locale={{
                emptyText: loading ? (
                  <Skeleton round active={true} />
                ) : (
                  <Empty />
                ),
              }}
              pagination={false}
              scroll={{ y: 300 }}
              // style={{ marginTop: 24 }}
            />
          </div>
          </Col>
          <Divider />
          <div>
            <Button
              type="primary"
              onClick={handleAddEntry}
              // disabled={
              //   selectedPlayer?.length === 0 || !categories.kumite
              // }
            >
              Add Player
            </Button>
          </div>
        </TabPane>

        <TabPane tab="Team" key="team">
          <Input
            placeholder="Enter team name"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            style={{ marginBottom: 16 }}
          />

          <Select
            placeholder="Select Category"
            value={selectedTeamCategory}
            onChange={(value) => {
              setSelectedTeamCategory(value);
              setSelectedPlayer([]);
              const selectedCategory = categoryData.find(
                (cat) => cat._id === value
              );
              if (selectedCategory) {
                setTeamSize(selectedCategory?.category?.teamSize);
              }
            }}
            style={{ width: "100%", marginBottom: 16 }}
          >
            {categoryData?.map((category) => (
              <Option key={category?._id} value={category?._id}>
                {category?.category?.name}
              </Option>
            ))}
          </Select>

          <Select
            placeholder="Select Players"
            value={selectedPlayer}
            mode="multiple"
            onChange={handlePlayerSelect}
            style={{ width: "100%", marginBottom: 16 }}
            maxTagCount="responsive" // Adjusts the display of selected tags
          >
            {studentsData?.map((player) => (
              <Option key={player?._id} value={player?._id}>
                {player?.firstName + " " + player?.lastName}
              </Option>
            ))}
          </Select>

          {/* Displaying selected count */}
          <div style={{ marginBottom: 16 }}>
            {selectedPlayer.length} out of {teamSize} players selected
          </div>

          <Divider />
          <div>
            <Button
              type="primary"
              onClick={handleAddTeam}
              disabled={selectedPlayer.length !== teamSize} // Enable only when count matches
            >
              Add Team
            </Button>
          </div>
        </TabPane>
      </Tabs>
    </ModalWrapper>
  );
};

export default AddEntriesModal;
