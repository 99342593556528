import {
  EditOutlined,
  MoreOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Popover,
  Row,
  Segmented,
  Select,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import institute from "../../../services/institute";
import { notify } from "../../../utils/helper";
import { setBreadcrumb } from "../../../store/actions/bredcrumb";
import { useDispatch } from "react-redux";
import Breadcrumb from "../../layout/breadcrumb";
import users from "../../../services/users";

const InstituteDetails = () => {
  const [isEditUser, setIsEditUser] = useState(false);
  const { id } = useParams();
  const [instituteData, setInstituteData] = useState({});
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const types = [
    { label: "Club", value: "CLUB" },
    { label: "School", value: "SCHOOL" },
    { label: "College", value: "COLLEGE" },
  ];
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [coachData, setCoachData] = useState([]);

  const getInstituteById = async (id) => {
    try {
      let res = await institute.getInstituteById(id);
      setInstituteData(res?.data?.institute);
    } catch (err) {}
  };

  useEffect(() => {
    getInstituteById(id);
  }, [id]);

  const getAllStates = async () => {
    try {
      const response = await users.getAllStates();
      setStateData(response?.data);
    } catch (err) {
      // console.log(err);
    }
  };
  useEffect(() => {
    getAllStates();
  }, []);

  const getAllDistrict = async (stateName) => {
    const stateId = stateData.filter((state) => state.name === stateName)[0]
      ?.id;

    try {
      const response = await users.getAllDistrict(stateId);
      setDistrictData(response?.data?.names);
    } catch (err) {
      // console.log(err);
    }
  };

  const updateInstitute = async (payload) => {
    try {
      let res = await institute.updateInstitute(id, payload);
      notify("success", "Institute Updated Successfully.");
      getInstituteById(id);
      setIsEditUser(false);
    } catch (err) {}
  };

  const populateFormFields = () => {
    form.setFieldsValue({
      name: instituteData?.name,
      type: instituteData?.type,
      state: instituteData?.state,
      district: instituteData?.district,
      coaches: instituteData?.coaches.map((coach) => (
        <Select.Option key={coach._id} value={coach._id}>
          {`${coach.firstName} ${coach.lastName}`}
        </Select.Option>
      )),
    });
  };

  useEffect(() => {
    if (isEditUser) {
      populateFormFields();
    }
  }, [isEditUser]);

  useEffect(() => {
    if (instituteData?.name)
      dispatch(
        setBreadcrumb({
          title: "Institute",
          subtitle: instituteData?.name,
          url: "",
        })
      );
  }, [instituteData]);

  const getCoachs = async () => {
    try {
      //   setLoading(true);
      let res = await users.getCoachs();
      setCoachData(res?.data?.response?.records);
      //   setLoading(false);
    } catch (err) {}
  };
  useEffect(() => {
    getCoachs();
  }, []);


  return (
    <Card
      title={
        <Row justify="space-between" align="middle" gutter={[24, 0]}>
          <Col span={24} md={12} className="col-info">
            <Avatar.Group>
              <Avatar
                size={74}
                shape="square"
                src={
                  instituteData?.profilePicture ? (
                    instituteData?.profilePicture
                  ) : (
                    <div style={{ background: "gray" }}>
                      {instituteData?.name?.trim()?.[0]?.toUpperCase() || "-"}
                    </div>
                  )
                }
              />

              <div className="avatar-info" style={{ marginLeft: "10px" }}>
                <h4 className="font-semibold m-0">{instituteData?.name}</h4>
              </div>
            </Avatar.Group>
          </Col>
        </Row>
      }
    >
      <>
        <Form form={form} onFinish={updateInstitute}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div
                className="w-100"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h6>Summary</h6>
                <div className="px-3">
                  {!isEditUser ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsEditUser(!isEditUser)}
                    >
                      <EditOutlined color="var(--text-primary)" size={20} />
                    </div>
                  ) : (
                    <div>
                      <Button
                        style={{
                          marginRight: "10px",
                        }}
                        type="primary"
                        onClick={() => setIsEditUser(!isEditUser)}
                        className="font-bold cursor-pointer mx-4"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="font-bold cursor-pointer mx-4"
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={24} className="m-2"></Col>
            <Col span={6}>
              {/* <div className="subHeading">First Name</div> */}
              <h6 className="list-header text-m text-muted m-0">Name</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ marginBottom: 10 }}
                  className="username"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required!",
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              ) : (
                <div className="font-semibold">{instituteData?.name}</div>
              )}
            </Col>
            <Col span={6}>
              {/* <div className="subHeading">Last Name</div> */}
              <h6 className="list-header text-m text-muted m-0">Type</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ margin: 0 }}
                  className="username"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Type is required!",
                    },
                  ]}
                >
                  <Select placeholder="Select type">
                    {types.map((org) => (
                      <Select.Option key={org.value} value={org.value}>
                        {org.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <div className="font-semibold">{instituteData?.type}</div>
              )}
            </Col>
            <Col span={6}>
              <h6 className="list-header text-m text-muted m-0">State</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ margin: 0 }}
                  className="username"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "state is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select state"
                    //   value={role}
                    showSearch
                    onChange={(e) => getAllDistrict(e)}
                  >
                    {stateData.map((org) => (
                      <Select.Option key={org.id} value={org.name}>
                        {org.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <div className="font-semibold">{instituteData?.state}</div>
              )}
            </Col>
            <Col span={6}>
              <h6 className="list-header text-m text-muted m-0">District</h6>
              {/* <div className="font-semibold">{instituteData?.district}</div> */}
              {isEditUser ? (
                <Form.Item
                  style={{ margin: 0 }}
                  className="username"
                  name="district"
                  rules={[
                    {
                      required: true,
                      message: "district is required!",
                    },
                  ]}
                >
                  <Select showSearch placeholder="Select district">
                    {districtData.map((org) => (
                      <Select.Option key={org.id} value={org.name}>
                        {org.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <div className="font-semibold">{instituteData?.district}</div>
              )}
            </Col>
            <Col span={6}>
              <h6 className="list-header text-m text-muted m-0">Coach</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ margin: 0 }}
                  className="username"
                  name="coaches"
                  rules={[
                    {
                      required: true,
                      message: "Coach is required!",
                    },
                  ]}
                >
                  <Select mode="multiple" showSearch maxTagCount="responsive" placeholder="Select coach">
                    {coachData.map((coach) => (
                      <Select.Option key={coach._id} value={coach._id}>
                        {`${coach.firstName} ${coach.lastName}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <div className="font-semibold">
                  {/* {`${instituteData?.coaches?.firstName} ${instituteData?.coaches?.lastName}`} */}
                  <Popover
                    content={
                      instituteData?.coaches?.length > 0 ? (
                        <div>
                          {instituteData?.coaches.map((cat, index) => (
                            <p
                              style={{
                                margin: "5px 0px",
                                backgroundColor: "#1890ff",
                                padding: "2px 6px",
                                borderRadius: "4px",
                                color: "#fff",
                              }}
                              key={index}
                            >
                              {cat?.firstName + " " + cat.lastName}
                            </p>
                          ))}
                        </div>
                      ) : (
                        <p>No Coach Available</p>
                      )
                    }
                    trigger="hover"
                  >
                    <>
                      {instituteData?.coaches !== undefined &&
                      instituteData?.coaches?.length > 0 ? (
                        <div
                          className="cursor-pointer"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // setAssignedCategories(item?.category);
                            // // setShowCatagories(true);
                            // setRingId(item?._id);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                            }}
                          >
                            {instituteData?.coaches?.length > 0 ? (
                              <div style={{ display: "flex", gap: "10px" }}>
                                <div
                                  style={{
                                    padding: "2px 6px",
                                    borderRadius: "4px",
                                    color: "#fff",
                                    backgroundColor: "#1890ff",
                                    maxWidth: "100px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {" "}
                                  {instituteData?.coaches[0]?.firstName +
                                    " " +
                                    instituteData?.coaches[0]?.lastName}
                                </div>
                                {instituteData?.coaches?.length > 1 ? (
                                  <div
                                    style={{
                                      padding: "2px 6px",
                                      borderRadius: "4px",
                                      color: "#fff",
                                      backgroundColor: "#1890ff",
                                    }}
                                  >
                                    {" "}
                                    + {instituteData?.coaches?.length - 1} ..
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              <span style={{ color: "var(--text-primary)" }}>
                                {" "}
                                -{" "}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div style={{ width: "200px" }}>-</div>
                      )}
                    </>
                  </Popover>
                </div>
              )}
              {/* <div className="font-semibold">{`${instituteData?.coach?.firstName} ${instituteData?.coach?.lastName}`}</div> */}
            </Col>
            <Col span={6}>
              {/* <div className="subHeading">Member since</div> */}
              <h6 className="list-header text-m text-muted m-0">Created on</h6>
              <div className="font-semibold">
                {moment(instituteData?.createdAt).format("DD MMM YYYY")}
              </div>
            </Col>
            <Col span={6}>
              {/* <div className="subHeading">Member since</div> */}
              <h6 className="list-header text-m text-muted m-0">Updated on</h6>
              <div className="font-semibold">
                {moment(instituteData?.updatedAt).format("DD MMM YYYY")}
              </div>
            </Col>

            <Col span={24} className="m-3 text-center">
              {!isEditUser && (
                <>
                  <Divider />

                  <Button
                    type="primary"
                    className="font-bold cursor-pointer mx-4 p-0"
                    onClick={() => history.goBack()}
                  >
                    Back
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Form>
      </>
    </Card>
  );
};

export default InstituteDetails;
