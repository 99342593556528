import React, { useState } from "react";
import { Button, Col, Divider, Row, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import ModalWrapper from "../../../modal/ModalWrapper";
import users from "../../../../services/users";
import { notify } from "../../../../utils/helper";

const UploadStudentsModal = ({ open, setOpen }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState([]);


  const handleDrop = (newFiles) => {
    try {
      setFiles(newFiles?.file);
    } catch (err) {}
  };

  const downloadSampleFile = async () => {
    try {
        // window.open(res?.data);
    } catch (err) {}
  };


  const handleUploadSubmit = async (files) => {
    if(
      !files || 
      (typeof files === "object" && Object.keys(files).length === 0)
    )  {
      notify("info", "Please select a file!");
      return;
    }
    try {
      if (!files) return notify("error", "Please select a file");
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", files);
      const res = await users.importStudents(formData);
      notify("success", "Players Uploaded Successfully!");
      setOpen(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleUpload = async () => {
    handleUploadSubmit(files);
  };

  return (
    <ModalWrapper
      title="Upload Players"
      open={open}
      setOpen={setOpen}
      footer={null}
      width={600}
    >
      <Row>
        <Col
          span={24}
          className="d-flex flex-column justify-content-center mb-4"
        >
          <div
            className="d-flex justify-content-center"
            style={{
              textDecoration: "underline",
              letterSpacing: "0.9px",
              margin: "10px 0px 30px 0px",
              fontSize: "14px",
              cursor:"pointer"
            }}
            onClick={() => downloadSampleFile()}
          >
            Download Sample File
          </div>

          <div className="d-flex justify-content-center mt-3 " style={{border: "1px dashed gray", borderRadius:"6px", padding:"15px", cursor:"pointer"}}>
            <Upload
              multiple={false}
              beforeUpload={() => false}
              accept=".xls,.xlsx"
              onChange={handleDrop}
            >
              {/* <div className="d-flex justify-content-center">
                <AiOutlineCloudUpload size={25} />
              </div>
              <div className="mb-2" style={{ fontSize: "12px" }}>
                Browse and choose the files you want to upload from your
                computer.
              </div> */}
              <p className="ant-upload-drag-icon d-flex justify-content-center">
                <InboxOutlined style={{fontSize:"27px", color:"blue"}} />
              </p>
              <p className="ant-upload-text d-flex justify-content-center">
                Click or drag file to this area to upload
              </p>
            </Upload>
          </div>
        </Col>

        <Col span={24}>
          <Divider />
        </Col>

        <Col span={12} className="text-right mb-3 px-2">
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </Col>
        <Col span={12} className="text-left mb-3 px-2">
          <Button className="button-29" onClick={handleUpload}>Upload</Button>
        </Col>
      </Row>
    </ModalWrapper>
  );
};

export default UploadStudentsModal;
