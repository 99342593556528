/* eslint-disable import/no-anonymous-default-export */
import { apiDelete, apiGet, apiPatch, apiPost } from "../utils/axios";
import { domain, domainUrl } from "../utils/domainUrl";

export default {
  getMatches: (id)=> apiGet(domainUrl(`/api/v1/fixtures/${id}/matches`)),
  getKataMatchById: (id)=> apiGet(domainUrl(`/api/v1/fixtures/kata-match/${id}`)),
  getMatchesById: (catId, id)=> apiGet(domainUrl(`/api/v1/fixtures/${catId}/match/${id}`)),
  updateKumitiScoreSheet: (catId, id, payload)=> apiPatch(domainUrl(`/api/v1/fixtures/${catId}/matches/${id}`), payload),
  updateWinner: (id, payload)=> apiPatch(domainUrl(`/api/v1/fixtures/update-winner/${id}`), payload),
  undoScore: (id)=> apiPatch(domainUrl(`/api/v1/fixtures/undo-score/${id}`)),
  boutMatchDuration: (catId, id, payload)=> apiPatch(domainUrl(`/api/v1/fixtures/${catId}/bout-duration/${id}`), payload),

  getkataResult: (id)=> apiGet(domainUrl(`/api/v1/fixtures/kata-result/${id}`)),
  getkumiteResult: (id)=> apiGet(domainUrl(`/api/v1/fixtures/kumite-result/${id}`)),

  
  getGroups: (id) => apiGet(domainUrl(`/api/v1/fixtures/${id}/get-groups`)),
  getFixturekataGroup: (id) => apiGet(domainUrl(`/api/v1/fixtures/${id}/kata-get-groups`)),

  downloadFixturePdf: (id, payload) => apiPost(domainUrl(`/api/v1/fixtures/generate-tournament-pdf/${id}`), payload, true),
  generateFixture: (payload) => apiPost(domainUrl(`/api/v1/fixtures/generate-tournament`), payload),
  getMatchesByGroupName: (id,groupName, searchText="") => apiGet(domainUrl(`/api/v1/fixtures/${id}/matches/${groupName}?query=${searchText}`)),
  getKataMatchesByGroupName: (id,groupName) => apiGet(domainUrl(`/api/v1/fixtures/${id}/kata-matches/${groupName}`)),
  getKataMatchesByRankGroupName: (id,groupName,eventId) => apiGet(domainUrl(`/api/v1/fixtures/${eventId}/${id}/kata-matches-rank/${groupName}`)),
  generateKataMatchFixture: (id, payload) => apiPost(domainUrl(`/api/v1/fixtures/generate-fixtures-kata/${id}`), payload),
  getKataScoreSheetData: (id) => apiGet(domainUrl(`/api/v1/fixtures/fixtures-kata/${id}`)),
  createKataScore: (id, payload) => apiPost(domainUrl(`/api/v1/fixtures/fixtures-kata/${id}`,payload)),
  updateKataScore: (id, payload) => apiPatch(domainUrl(`/api/v1/fixtures/update-kata-scoresheet/${id}`),payload),
  updateKataMatchStatus: (matchId,payload) => apiPatch(domainUrl(`/api/v1/fixtures/update-kata-status-match/${matchId}`),payload),
  
  generateEventResult:(eventId) => apiPost(domainUrl(`/api/v1/fixtures/generate-result/${eventId}`)),
  getEventResult:(eventId) => apiGet(domainUrl(`/api/v1/fixtures/generate-result/${eventId}`)),

  deletekataMatches: (payload) => apiDelete(domainUrl(`/api/v1/fixtures/delete-kata-matches`), payload),
  deletekumiteMatches: (payload) => apiDelete(domainUrl(`/api/v1/fixtures/delete-kumite-matches`), payload),

  kataMoveToFinalGroup: (id, payload) => apiPost(domainUrl(`/api/v1/fixtures/kata-move-to-finalgroup/${id}`), payload),
  swapFixtureParticipants:(catId,payload) => apiPatch(domainUrl(`/api/v1/fixtures/swap-participant/${catId}`),payload),
  editFixtureParticipants:(fixtureId,payload) => apiPatch(domainUrl(`/api/v1/fixtures/edit-fixture/${fixtureId}`),payload),
  swapMatchParticipant:(matchId) => apiPatch(domainUrl(`/api/v1/fixtures/swap-participant-in-match/${matchId}`)),

}