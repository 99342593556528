import { Button, Col, Empty, Input, Skeleton, Table } from "antd";
import event from "../../../../services/event";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  capitalizeFirstLetter,
  debounce,
  notify,
  ShowTotal,
} from "../../../../utils/helper";
import { SearchOutlined } from "@ant-design/icons";
import { extractToken } from "../../../../utils/localStorage";
import moment from "moment";
import { useParams } from "react-router-dom";
import UploadEntriesModal from "../UploadEntriesModal";
import AddEntriesModal from "../AddEntriesModal";
import DeleteConfirionModal from "../../../modal/DeleteConfirionModal";

const EventIndividualParticipantsList = forwardRef(
  ({ eventId, selectedRowKeys, setSelectedRowKeys }, ref) => {
    const [eventData, setEventData] = useState([]);
    const [count, setCount] = useState(0);
    const [filterCoachName, setFilterCoachId] = useState([]);
    const [filterInstituteName, setFilterInstituteId] = useState([]);
    const [filterCoach, setFilterCoach] = useState([]);
    const [filterInstitute, setFilterInstitute] = useState([]);
    const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
    const [type, setType] = useState("");
    const [loading, setLoading] = useState(false);
    const [sortingColumn, setSortingColumn] = useState("");
    const [sortingOrder, setSortingOrder] = useState("");
    const [searchText, setSearchText] = useState({
      student: "",
      institute: "",
      coach: "",
      category: "",
    });
    const [openEntriesModal, setOpenEntriesModal] = useState(false);
    const [openUploadEntriesModal, setOpenUploadEntriesModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allEvents, setAllEvents] = useState([]);
    const [givenPassword, setGivenPassword] = useState("");

    const user = extractToken();
    const { EventId } = useParams();

    const getEventById = async (
      searchValue = searchText[type] || "",
      searchType = type || ""
    ) => {
      try {
        setLoading(true);
        const res = await event.getParticipantByEventId(
          eventId,
          pagination?.size,
          pagination?.currentPage,
          sortingColumn,
          sortingOrder,
          searchValue,
          filterCoachName.length > 0 ? filterCoachName[0] : "",
          filterInstituteName.length > 0 ? filterInstituteName[0] : "",
          searchType
        );
        setEventData(res?.data?.response?.records);
        setCount(res?.data?.response?.totalItems);
        setLoading(false);
      } catch (error) { }
    };

    useEffect(() => {
      if (eventId) {
        getEventById();
      }
    }, [
      eventId,
      pagination?.size,
      pagination?.currentPage,
      sortingColumn,
      sortingOrder,
    ]);

    const handleSearch = (e) => {
      setLoading(true);
      const { name, value } = e.target;
      setType(name);
      setSearchText({
        student: name === "student" ? value : "",
        institute: name === "institute" ? value : "",
        coach: name === "coach" ? value : "",
        category: name === "category" ? value : "",
      });

      getEventById(value, name);
    };

    let coachNames = [];
    let instituteNames = [];

    const calculateAge = (dateOfBirth) => {
      const today = new Date();
      const birthDate = new Date(dateOfBirth);

      if (isNaN(birthDate.getTime())) {
        return "Invalid Date";
      }

      let years = today.getFullYear() - birthDate.getFullYear();
      let months = today.getMonth() - birthDate.getMonth();

      if (
        months < 0 ||
        (months === 0 && today.getDate() < birthDate.getDate())
      ) {
        years--;
        months += 12;
      }

      if (today.getDate() < birthDate.getDate()) {
        months--;
        if (months < 0) {
          months = 11;
          years--;
        }
      }

      return `${years}.${months} Years`;
    };

    const columns = [
      {
        title: (
          <div>
            <div style={{ textAlign: "center" }} className="heading mid">
              Participants Name
            </div>

            <div
              className="header-control"
              onClick={(e) => e.stopPropagation()}
              style={{ justifyContent: "center" }}
            >
              <Input
                className="header-search"
                placeholder="Search participants..."
                prefix={<SearchOutlined />}
                style={{ height: "30px", fontSize: "12px", width: "150px" }}
                name="student"
                onChange={(e) => {
                  setType("student");
                  handleSearch(e);
                  // setSearchText(e.target.value);
                }}
                value={searchText.student}
              />
            </div>
          </div>
        ),
        dataIndex: "name",
        className: "ant-table-row-cell-break-word",
        width: 150,
        align: "start",
        sorter: {
          compare: (a, b) => {
            a.namesort.localeCompare(b.namesort.locale);
          },
          multiple: 3,
        },
      },
      {
        title: (
          <div>
            <div style={{ textAlign: "center" }} className="heading mid">
              D.O.B
            </div>

            {/* <div
                className="header-control"
                onClick={(e) => e.stopPropagation()}
                style={{ justifyContent: "center" }}
              >
                <Input
                  className="header-search"
                  placeholder="Search here..."
                  prefix={<SearchOutlined />}
                  style={{ height: "30px", fontSize: "12px" }}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div> */}
          </div>
        ),
        className: "ant-table-row-cell-break-word",
        width: 150,
        dataIndex: "dateOfBirth",
        align: "center",
        sorter: (a, b) => {
          const dateA = new Date(a.dateOfBirth.split("-").reverse().join("-"));
          const dateB = new Date(b.dateOfBirth.split("-").reverse().join("-"));
          return dateA - dateB;
        },
      },
      {
        title: (
          <div>
            <div style={{ textAlign: "center" }} className="heading mid">
              Age
            </div>

            {/* <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
          </div>
        ),
        className: "ant-table-row-cell-break-word",
        width: 150,
        dataIndex: "age",
        align: "center",
        sorter: {
          compare: (a, b) => {
            const convertAgeToMonths = (ageStr) => {
              const ageMatch = ageStr.match(
                /(\d+)\s*years?\s*(\d*)\s*months?/i
              );
              const years = parseInt(ageMatch?.[1] || 0, 10);
              const months = ageMatch?.[2] ? parseInt(ageMatch[2], 10) : 0;
              return years * 12 + months;
            };

            const ageA = convertAgeToMonths(a.age);
            const ageB = convertAgeToMonths(b.age);

            return ageA - ageB;
          },
          multiple: 2,
        },
      },
      {
        title: <div>Weight</div>,
        dataIndex: "weight",
        align: "center",
        width: 120,
        sorter: (a, b) => {
          const weightA = parseFloat(a.weight);
          const weightB = parseFloat(b.weight);
          return weightA - weightB;
        },
      },
      {
        title: (
          <div>
            <div style={{ textAlign: "center" }} className="heading mid">
              Gender
            </div>

            {/* <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
          </div>
        ),
        className: "ant-table-row-cell-break-word",
        width: 100,
        dataIndex: "gender",
        align: "center",
      },
      {
        title: (
          <div>
            <div style={{ textAlign: "center" }} className="heading mid">
              Institute
            </div>

            <div
              className="header-control"
              onClick={(e) => e.stopPropagation()}
              style={{ justifyContent: "center" }}
            >
              <Input
                className="header-search"
                placeholder="Search institute..."
                prefix={<SearchOutlined />}
                style={{ height: "30px", fontSize: "12px", width: "150px" }}
                name="institute"
                onChange={(e) => {
                  setType("institute");
                  handleSearch(e);
                }}
                value={searchText.institute}
              />
            </div>
          </div>
        ),
        className: "ant-table-row-cell-break-word",
        width: 150,
        dataIndex: "institute",
        align: "center",
      },
      {
        title: (
          <div>
            <div style={{ textAlign: "center" }} className="heading mid">
              Coach
            </div>

            <div
              className="header-control"
              onClick={(e) => e.stopPropagation()}
              style={{ justifyContent: "center" }}
            >
              <Input
                className="header-search"
                placeholder="Search coach..."
                prefix={<SearchOutlined />}
                style={{ height: "30px", fontSize: "12px", width: "150px" }}
                name="coach"
                onChange={(e) => {
                  setType("coach");
                  handleSearch(e);
                }}
                value={searchText.coach}
              />
            </div>
          </div>
        ),
        className: "ant-table-row-cell-break-word",
        width: 200,
        dataIndex: "coach",
        align: "center",
        hidden: ![
          "stateHead",
          "stateAdmin",
          "districtHead",
          "districtAdmin",
        ].includes(user?.role),
      },
      {
        title: (
          <div>
            <div className="heading mid">Category</div>

            <div
              className="header-control"
              onClick={(e) => e.stopPropagation()}
              style={{ justifyContent: "center" }}
            >
              <Input
                className="header-search"
                placeholder="Search here..."
                prefix={<SearchOutlined />}
                style={{ height: "30px", fontSize: "12px", width: "150px" }}
                name="category"
                onChange={(e) => {
                  setType("category");
                  handleSearch(e);
                  // setSearchText(e.target.value);
                }}
                value={searchText.category}
              />
            </div>
          </div>
        ),
        dataIndex: "category",
        align: "center",
        width: 150,
        className: "ant-table-row-cell-break-word",
      },
    ].filter((item) => !item.hidden);

    let data = [];
    for (let item of eventData || []) {
      const coachName = item?.coach?.firstName
        ? item?.coach?.firstName +
        " " +
        item?.coach?.lastName
        : "-";
      const instituteName = item?.instituteId?.name
        ? item?.instituteId?.name
        : "-";

      if (coachName) {
        coachNames.push(coachName);
        instituteNames.push(instituteName);
      }

      data.push({
        key: item?._id,
        name: item?.firstName ? (
          <div
            style={{ width: "200px" }}
            className="ellipsis"
          >{`${item?.firstName} ${item?.lastName}`}</div>
        ) : (
          <div style={{ width: "200px" }}>-</div>
        ),
        namesort: item?.firstName && `${item?.firstName} ${item?.lastName}`,
        age: calculateAge(item?.dateOfBirth),
        weight: item?.weight ? (
          <div style={{ width: "120px" }}>{`${item?.weight} kg`}</div>
        ) : (
          <div style={{ width: "120px" }}>-</div>
        ),
        gender: item?.gender ? capitalizeFirstLetter(item?.gender) : "-",
        coach: item?.coach?.firstName
          ? item?.coach?.firstName +
          " " +
          item?.coach?.lastName
          : "-",
        institute: item?.instituteId?.name ? item?.instituteId?.name : "-",
        category: <CategoriesDisplay categories={item?.category} />,
        dateOfBirth: item?.dateOfBirth
          ? moment(item.dateOfBirth).format("DD-MM-YYYY")
          : "-",
      });
    }

    function CategoriesDisplay({ categories }) {
      return categories?.length > 0 ? (
        <div
          dangerouslySetInnerHTML={{
            __html: categories?.map((cat) => cat.name).join(",<br />"),
          }}
        />
      ) : (
        "-"
      );
    }

    useEffect(() => {
      setFilterInstituteId([...filterInstitute]);
      setFilterCoachId([...filterCoach]);
    }, [filterCoach, filterInstitute]);

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
      },
      onSelect: (record, selected, selectedRows) => { },
      onSelectAll: (selected, selectedRows, changeRows) => { },
      handleChange: (a, b, c) => { },
    };
    const handleChangeTable = (a, b, c) => {
      setSortingOrder(c?.order);
      setSortingColumn(c?.column?.dataIndex);
    };

    useImperativeHandle(ref, () => ({
      callChildFunction: getEventById,
    }));

    const handleMapCategoreis = async (eventId) => {
      try {
        const res = await event.mapCategories(eventId);
        notify("success", res?.data?.message);
        //   if (childRef.current) {
        //     childRef.current.callChildFunction();
        // }
      } catch (error) { }
    };

    const getActiveEvent = async () => {
      try {
        const res = await event.getActiveEvent();
        setAllEvents(res?.data);
      } catch (error) { }
    };

    useEffect(() => {
      getActiveEvent();
    }, []);

    const handleDeleteSelectedParticipants = async (eventId, payload) => {
      try {
        const res = await event.deleteEventParticipants(eventId, payload);

        setSelectedRowKeys([]);
        notify("success", res?.data?.message);
        setSearchText({
          student: "",
          institute: "",
          coach: "",
          category: "",
        })
        getEventById("", "");
        setIsModalOpen(false);
      } catch (error) {
        notify("error", error.data.message);
      }
    };


    const hasData = data.length > 0;
    const scroll = hasData ? { y: "calc(100vh - 425px)", x: true } : null;

    return (
      <>
        {openEntriesModal && (
          <AddEntriesModal
            open={openEntriesModal}
            setOpen={setOpenEntriesModal}
            getActiveEvent={getActiveEvent}
            selectedEvent={[EventId]}
            getEventById={getEventById}
          />
        )}
        {openUploadEntriesModal && (
          <UploadEntriesModal
            open={openUploadEntriesModal}
            setOpen={setOpenUploadEntriesModal}
            selectedEvent={[EventId]}
            getEventById={getEventById}
          />
        )}

        <DeleteConfirionModal
          isOpen={isModalOpen}
          setOpen={setIsModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={() =>
            handleDeleteSelectedParticipants(EventId, {
              participants: selectedRowKeys,
              password: givenPassword,
            })
          }
          messageText={
            "Please confirm it by entering your password to take the action."
          }
          password={givenPassword}
          setPassword={setGivenPassword}
        />
        <Col span={24} className="d-flex justify-content-end mb-2">
          <div className="d-flex">
            {[
              "stateHead",
              "stateAdmin",
              "districtHead",
              "districtAdmin",
              "coach",
            ].includes(user?.role) && (
                <>
                  {selectedRowKeys?.length ? (
                    <div className="px-2">
                      <Button
                        style={{ fontSize: "13px" }}
                        type="danger"
                        role="button"
                        onClick={() => setIsModalOpen(true)}
                      >
                        Delete Selected Participant
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {selectedRowKeys?.length === 1 && ( */}
                  <div style={{ marginRight: "7px" }}>
                    <button
                      class="button-29"
                      role="button"
                      onClick={() => handleMapCategoreis(EventId)}
                    >
                      Map Categories
                    </button>
                  </div>
                  <div>
                    <button
                      class="button-29"
                      role="button"
                      onClick={() => setOpenEntriesModal(true)}
                    >
                      Add Entries
                    </button>
                  </div>
                  {/* )} */}
                  {/* {selectedRowKeys?.length === 1 && ( */}
                  <div className="px-2">
                    <button
                      class="button-29"
                      type="danger"
                      role="button"
                      onClick={() => setOpenUploadEntriesModal(true)}
                    >
                      Import Entries
                    </button>
                  </div>
                  {/* )} */}
                </>
              )}
          </div>
        </Col>
        <Col span={24}>
          <Table
            tableLayout="auto"
            scroll={scroll}
            columns={columns}
            dataSource={data}
            rowSelection={rowSelection}
            pagination={{
              defaultPageSize: 10,
              defaultCurrent: 1,
              showSizeChanger: true,
              pageSizeOptions: ["25", "50", "100"],
              total: count,
              onChange: (currentPage, size) => {
                setPagination({ size, currentPage });
              },
              showTotal: (total, range) => (
                <ShowTotal
                  selectedRowKeys={selectedRowKeys}
                  total={total}
                  currentPageItem={range[1] - range[0] + 1}
                  ranged={range}
                />
              ),
            }}
            onChange={handleChangeTable}
            locale={{
              emptyText: loading ? <Skeleton round active={true} /> : <Empty />,
            }}
          />
        </Col>
      </>
    );
  }
);

export default EventIndividualParticipantsList;
