import React from "react";
import {
  Table,
  Avatar,
  Col,
  Row,
  Button,
  Skeleton,
  Empty,
  Input,
  Form,
  Modal,
  Select,
  Card,
  Popover,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { path } from "../../../../utils/path";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { debounce, notify, ShowTotal } from "../../../../utils/helper";
import event from "../../../../services/event";
import users from "../../../../services/users";
import { FaEdit } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
import { extractToken } from "../../../../utils/localStorage";
import { useForm } from "antd/lib/form/Form";
import DeleteConfirionModal from "../../../modal/DeleteConfirionModal";
import { CustomTable } from "../Styled";

const Tatami = () => {
  const [form] = Form.useForm();
  const [formAssignOfficials] = useForm(); // Separate form for assigning officials

  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [count, setCount] = useState("");
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [searchText, setSearchText] = useState("");
  const [modelOpen, setModalOpen] = useState(false);
  const [modalCategoryOpen, setModalCategoryOpen] = useState(false);
  const [officialShow, setOfficialShow] = useState(false);
  const [officials, setOfficials] = useState("");
  const [assignedOfficials, setAssignedOfficials] = useState([]);
  const [assignedCategories, setAssignedCategories] = useState([]);
  const [showCatagories, setShowCatagories] = useState(false);
  const [ringId, setRingId] = useState(null);
  const [selectedId, setSelectedId] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [assignedOfficialsData, setAssignedOfficialsData] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = extractToken();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [givenPassword, setGivenPassword] = useState("")

  const types = [
    { label: "Moderator", value: "moderator" },
    { label: "Judge", value: "judge" },
    { label: "Referee", value: "referee" },
  ];

  const getModerators = async () => {
    try {
      let res = await users.getModerators();
      setOfficials(res.data?.response?.records);
    } catch (err) {}
  };

  const getJudges = async () => {
    try {
      let res = await users.getJudges();
      setOfficials(res.data?.response?.records);
    } catch (err) {}
  };

  const getReferees = async () => {
    try {
      let res = await users.getReferees();
      setOfficials(res.data?.response?.records);
    } catch (err) {}
  };

  const fetchOfficial = (role) => {
    formAssignOfficials.resetFields(["team"]);
    switch (role) {
      case "moderator":
        getModerators();
        break;
      case "judge":
        getJudges();
        break;
      case "referee":
        getReferees();
        break;
      default:
        return;
    }
  };

  const getTatamies = async (searchText = "") => {
    try {
      setLoading(true);
      let res = await event.getTatamies(
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText
      );
      setApiData(res?.data?.response?.records);
      // setAssignedOfficialsData(
      //   res?.data?.response?.records?.length > 0 &&
      //     res?.data?.response?.records.flatMap((data) => data?.official)
      // );
      setCount(res?.data?.response?.totalItems);
      setLoading(false);
    } catch (err) {}
  };

  const handleSearch = debounce((value) => {
    getTatamies(value);
  },500);

  useEffect(() => {
    getTatamies();
  }, [
    pagination?.size,
    pagination?.currentPage,
    sortingColumn,
    sortingOrder,
    searchText,
  ]);

  let data = [];
  for (let item of apiData) {
    data.push({
      key: item?._id,
      name: (
        <div
          style={{
            minWidth: "180px",
            // maxWidth: "180",
            display: "flex",
            gap: "5px",
          }}
        >
          <div
            className="mx-2"
            style={{ minWidth: "160px", display: "flex", alignItems: "center" }}
          >
            {user?.role === "moderator" ? (
              <Link
                // to={`/pages/events/tatami/${item?._id}/tatami-categories`}
                to={{
                  pathname: `/pages/events/tatami/${item?._id}/tatami-categories`,
                  state: { tatamiName: item?.name, officials: item?.official },
                  state: { tatamiName: item?.name, officials: item?.official },
                }}
                style={{ cursor: "pointer" }}
                className="ellipsis"
              >
                <div
                  className="ellipsis"
                  style={{
                    fontWeight: "600",
                    fontSize: "13px",
                    color: "var(--text-primary)",
                    lineHeight: "15px",
                    letterSpacing: "0.9px",
                  }}
                >
                  {item?.name || "-"}
                </div>
              </Link>
            ) : (
              <div
                className="ellipsis"
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  color: "var(--text-primary)",
                  lineHeight: "15px",
                  letterSpacing: "0.9px",
                }}
              >
                {item?.name || "-"}
              </div>
            )}
          </div>
        </div>
      ),
      nameToSort: item?.name || "-",
      address: (
        <div style={{ width: "120px" }}>
          {item?.address ? item?.address : "-"}
        </div>
      ),
      event: <div>{item?.event?.name ? item?.event?.name : "-"}</div>,
      eventToSort:item?.event?.name ? item?.event?.name : "-",
      officials: (
        <>
          {item?.official !== undefined && item?.official?.length > 0 ? (
            <div
              className="cursor-pointer"
              style={{ cursor: "pointer", width: "200px" }}
              onClick={() => {
                setAssignedOfficials(item?.official);
                setOfficialShow(true);
                setRingId(item?._id);
              }}
            >
              <Avatar.Group
                maxCount={3}
                size={28}
                maxStyle={{
                  color: "#fff",
                  backgroundColor: "gray",
                  border: "2px solid var(--dashboard-primay)",
                  fontSize: "12px",
                  lineHeight: "26px",
                }}
              >
                {item?.official?.map((user, userIndex) => (
                  <Avatar
                    style={{
                      backgroundColor: "#1890ff",
                      // border: "2px solid #1890ff",
                      fontSize: "13px",
                    }}
                    //   src={user?.profilePicture}
                    key={userIndex}
                    size={28}
                  >
                    {user?.firstName[0]}
                  </Avatar>
                ))}
              </Avatar.Group>
            </div>
          ) : (
            <div style={{ width: "200px" }}>-</div>
          )}
        </>
      ),
      categories: (
        <Popover
          content={
            item?.category?.length > 0 ? (
              <div>
                {item?.category.map((cat, index) => (
                  <p
                    style={{
                      margin: "5px 0px",
                      backgroundColor: "#1890ff",
                      padding: "2px 6px",
                      borderRadius: "4px",
                      color: "#fff",
                    }}
                    key={index}
                  >
                    {cat?.category?.name}
                  </p>
                ))}
              </div>
            ) : (
              <p>No Categories Available</p>
            )
          }
          trigger="hover"
        >
          <>
            {item?.category !== undefined && item?.category?.length > 0 ? (
              <div
                className="cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setAssignedCategories(item?.category);
                  // setShowCatagories(true);
                  setRingId(item?._id);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {item?.category?.length > 0 ? (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div
                        style={{
                          padding: "2px 6px",
                          borderRadius: "4px",
                          color: "#fff",
                          backgroundColor: "#1890ff",
                          maxWidth: "100px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {" "}
                        {item?.category[0]?.category?.name}
                      </div>
                      {item?.category?.length - 1 ? (
                        <div
                          style={{
                            padding: "2px 6px",
                            borderRadius: "4px",
                            color: "#fff",
                            backgroundColor: "#1890ff",
                          }}
                        >
                          {" "}
                          + {item?.category?.length - 1} ..
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <span style={{ color: "var(--text-primary)" }}> - </span>
                  )}
                </div>
              </div>
            ) : (
              <div style={{ width: "200px" }}>-</div>
            )}
          </>
        </Popover>
      ),

      available: (
        <div style={{ width: "120px" }}>{item?.inPlay ? "No" : "Yes"}</div>
      ),

      action: (
        <div style={{ width: "120px" }}>
          <FaEdit
            onClick={() =>
              history.push(`/pages/events/tatami/${item?._id}/tatami-details`)
            }
            style={{ marginRight: 16, cursor: "pointer" }}
          />
          {/* <FaTrashAlt
            onClick={() => deleteEvent(item?._id)}
            style={{ cursor: "pointer" }}
          /> */}
        </div>
      ),
    });
  }

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      // className: "ant-table-row-cell-break-word",
      width: 120,
      sorter: {
        compare: (a, b) => a.nameToSort.localeCompare(b.nameToSort),
        // multiple: 3,
      },
    },
    // {
    //   title: "ADDRESS",
    //   dataIndex: "address",
    //   // className: "ant-table-row-cell-break-word",
    //   width: 120,
    //   align: "center",
    // },
    {
      title: "EVENT",
      // className: "ant-table-row-cell-break-word",
      dataIndex: "event",
      // width: 150,
      align: "center",
        sorter: {
          compare: (a, b) => a.eventToSort.localeCompare(b.eventToSort),
          multiple: 2,
        },
    },
    {
      title: "OFFICIALS",
      dataIndex: "officials",
      align: "center",
      width: 150,
      // className: "ant-table-row-cell-break-word",
    },
    {
      title: "CATAGORIES",
      dataIndex: "categories",
      align: "center",
      width: 150,
      // className: "ant-table-row-cell-break-word",
    },

    // {
    //   title: "AVAILABLE",
    //   dataIndex: "available",
    //   align: "center",
    //   width: 150,
    //   // className: "ant-table-row-cell-break-word",
    // },
  ];

  if (
    ["stateHead", "stateAdmin", "districtHead", "districtAdmin"].includes(
      user?.role
    )
  ) {
    columns.push({
      title: "ACTION",
      dataIndex: "action",
      width: 150,
      align: "center",
    });
  }

  const handleClick = () => {
    history.push(`/pages/events/create-tatami`);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
  };

  const deleteTatami = async () => {
    try {
      setLoading(true);
      const res = await event.deleteTatami({
        ringIds: selectedRowKeys,
        password: givenPassword
      });
      setLoading(true);
      setSelectedRowKeys([]);
      notify("success", "Tatami Deleted Successfully.");
      getTatamies();
      setIsModalOpen(false)
      setLoading(false);
    } catch (err) { 
      notify("error", err.data.message)
    }
  };

  const assignOfficials = async (values) => {
    formAssignOfficials.resetFields();
    try {
      //   setLoading(true);
      const res = await event.assignOfficials({
        ringIds: selectedRowKeys,
        team: values?.team,
      });
      notify("success", "Officails Assigned Successfully.");
      formAssignOfficials.resetFields();
      // setSelectedId([]);
      setSelectedRowKeys([]);
      setModalOpen(false);
      //   setLoading(false);
      getTatamies();
    } catch (err) {}
  };

  const handleCardClick = (id) => {
    setSelectedId((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const unassignOfficials = async () => {
    if (selectedId?.length == 0) {
      notify("error", "Select atleast one official.");
      return;
    }

    try {
      const res = await event.unassignOfficials({
        ringIds: [ringId],
        team: selectedId,
      });
      notify("success", "Officails Unassigned Successfully.");
      setOfficialShow(false);
      setSelectedId([]);
      setSelectedRowKeys([]);
      getTatamies();
    } catch (err) {}
  };

  const getActiveEvent = async () => {
    try {
      const res = await event.getActiveEvent();
      setAllEvents(res?.data);
      // setSelectedRowKeys([]);
    } catch (error) {}
  };

  useEffect(() => {
    getActiveEvent();
  }, []);

  const getEventCategoryDetailsById = async (eventId) => {
    try {
      const res = await event.getEventCategoryDetailsById(eventId);
      // console.log("tat", res?.data?.response?.records)
      setCategories(res?.data?.response?.records);
    } catch (err) {}
  };
  const fetchByEventIdCategories = (eventId) => {
    // getEventCategoryDetailsById(eventId);
  };

  // will be used to get the eventID based on the selected tatami and pass to get the category details
  const getTatamiById = async (ringId) => {
    try {
      const res = await event.getTatamiById(ringId);
      getEventCategoryDetailsById(res?.data?.event);
    } catch (error) {}
  };

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      getTatamiById(selectedRowKeys[0]);
    }
  }, [selectedRowKeys.length]);

  const assignCatagory = async (values) => {
    let payload = {
      ringId: selectedRowKeys,
      ...values,
    };

    try {
      const res = await event.assignCategories(payload);
      notify("success", "Catagories Assigned To Tatami Successfully.");
      setModalCategoryOpen(false);
      setSelectedRowKeys([]);
      getTatamies();
      form.resetFields();
    } catch (error) {
      notify("error", error.msg);
    }
  };

  const handleCategoryCardClick = (id) => {
    setSelectedCategoryId((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const unassignCatagory = async () => {
    if (selectedCategoryId?.length == 0) {
      notify(
        "error",
        "Select atleast one catagory to remove from the tatami ."
      );
      return;
    }
    try {
      const res = await event.unassignCategories({
        ringId: [ringId],
        categories: selectedCategoryId,
      });
      notify("success", "Catagories Unassigned From Tatami Successfully.");
      setShowCatagories(false);
      setSelectedRowKeys([]);
      getTatamies();
    } catch (error) {
      notify("error", error.msg);
    }
  };

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 330px)", x: true } : null;

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: "Tatami List",
        subtitle: "",
        url: "",
      })
    );
  }, []);

  return (
    <Row>
      <Modal
        open={modelOpen}
        centered
        title="Assign officials"
        // onOk={this.handleOk}
        onCancel={() => setModalOpen(false)}
        footer={false}
      >
        <Form
          form={formAssignOfficials}
          layout="vertical"
          onFinish={assignOfficials}
        >
          <Col className="ml-10">
            <h4 className="list-header text-m text-muted m-0">Role</h4>

            <Form.Item
              style={{ margin: 0 }}
              name="officals"
              rules={[
                {
                  required: true,
                  message: "field is required!",
                },
              ]}
            >
              <Select
                placeholder="Select role"
                // value={role}
                onChange={(e) => {
                  fetchOfficial(e);
                  form.resetFields(["team"]);
                }}
              >
                {types.map((event) => (
                  <Select.Option key={event.value} value={event.value}>
                    {event?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            className="ml-10"
            style={{ marginBottom: "30px", marginTop: "10px" }}
          >
            <h4 className="list-header text-m text-muted mt-0">Officials</h4>

            <Form.Item
              style={{ margin: 0 }}
              name="team"
              rules={[
                {
                  required: true,
                  message: "field is required!",
                },
              ]}
            >
              <Select
                placeholder="Select officials"
                mode="multiple"
                // value={}
              >
                {officials.length > 0 &&
                  (assignedOfficialsData.length > 0
                    ? officials
                        .filter(
                          (official) =>
                            !assignedOfficialsData.some(
                              (assigned) => assigned._id === official._id
                            )
                        )
                        .map((item) => (
                          <Select.Option key={item._id} value={item._id}>
                            {item?.firstName} {item?.lastName}
                          </Select.Option>
                        ))
                    : officials.map((item) => (
                        <Select.Option key={item._id} value={item._id}>
                          {item?.firstName} {item?.lastName}
                        </Select.Option>
                      )))}
              </Select>
            </Form.Item>
          </Col>
          <Row style={{ justifyContent: "center" }}>
            <Col className="text-center">
              <Button
                key="Assign Officails"
                htmlType="submit"
                type="primary"
                //   onClick={handleOfficerAssign}
                className="px-30"
              >
                Assign Officails
              </Button>
              <Button
                onClick={() => {
                  setModalOpen(false);
                  setSelectedRowKeys([]);
                  form.resetFields();
                }}
                className="px-30 ml-10"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        open={officialShow}
        centered
        title="Officials"
        // onOk={this.handleOk}
        onCancel={() => setOfficialShow(false)}
        footer={false}
      >
        <Form layout="vertical" onFinish={unassignOfficials}>
          <Row gutter={[8, 16]} style={{ marginBottom: "20px" }}>
            {assignedOfficials.map((official) => (
              <Col span={12} key={official._id}>
                <Card
                  style={{
                    background: "#e9ecef",
                    cursor: "pointer",
                    borderRadius: "8px",
                    border: selectedId.includes(official._id)
                      ? "2px solid #1890ff"
                      : "1px solid #ddd",
                  }}
                  onClick={() => handleCardClick(official._id)}
                >
                  <div style={{}}>
                    <Avatar
                      style={{
                        backgroundColor: "#1890ff",
                        fontSize: "14px",
                        border: "2px solid #1890ff",
                      }}
                      size={44}
                      src={official?.profilePicture}
                    >
                      {official?.firstName[0]}
                      {official?.lastName[0]}
                    </Avatar>
                  </div>
                  <div>
                    <div>
                      {" "}
                      {official?.firstName} {official?.lastName}
                    </div>
                    <div>
                      {" "}
                      <span>Role : {official?.role}</span>{" "}
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col className="text-center">
              <Button
                key="Unassign Officails"
                htmlType="submit"
                type="primary"
                //   onClick={this.handleOk}
                className="px-30"
              >
                Unassign Officails
              </Button>
              <Button
                key="Cancel"
                onClick={() => {
                  setOfficialShow(false);
                  setSelectedId([]);
                }}
                className="px-30 ml-10"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        open={modalCategoryOpen}
        centered
        title="Assign category"
        // onOk={this.handleOk}
        onCancel={() => setModalCategoryOpen(false)}
        footer={false}
      >
        <Form form={form} layout="vertical" onFinish={assignCatagory}>
          <Col
            className="ml-10"
            style={{ marginBottom: "30px", marginTop: "10px" }}
          >
            <h4 className="list-header text-m text-muted mt-0">Categories</h4>

            <Form.Item
              style={{ margin: 0 }}
              name="categories"
              rules={[
                {
                  required: true,
                  message: "field is required!",
                },
              ]}
            >
              <Select
                placeholder="Select an category"
                mode="multiple"
                // value={}
              >
                {categories.length > 0 &&
                  categories?.map((item) => (
                    <Select.Option
                      key={item?.category?._id}
                      value={item?.category?._id}
                    >
                      {item?.category?.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Row style={{ justifyContent: "center" }}>
            <Col className="text-center">
              <Button
                key="Add Categories"
                htmlType="submit"
                type="primary"
                //   onClick={handleOfficerAssign}
                className="px-30"
              >
                Assign Categories
              </Button>
              <Button
                key="Cancel"
                onClick={() => {
                  setModalCategoryOpen(false);
                  setSelectedRowKeys([]);
                  form.resetFields();
                }}
                className="px-30 ml-10"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        open={showCatagories}
        centered
        title="Categories"
        // onOk={this.handleOk}
        onCancel={() => setShowCatagories(false)}
        footer={false}
      >
        <Form layout="vertical" onFinish={unassignCatagory}>
          <Row gutter={[8, 16]} style={{ marginBottom: "20px" }}>
            {assignedCategories.map((category) => (
              <Col span={12} key={category?.category?._id}>
                <Card
                  style={{
                    background: "#e9ecef",
                    cursor: "pointer",
                    borderRadius: "8px",
                    border: selectedCategoryId.includes(category?.category._id)
                      ? "2px solid #1890ff"
                      : "1px solid #ddd",
                  }}
                  onClick={() =>
                    handleCategoryCardClick(category?.category._id)
                  }
                >
                  <div style={{}}>
                    <Avatar
                      style={{
                        backgroundColor: "#1890ff",
                        fontSize: "14px",
                        border: "2px solid #1890ff",
                      }}
                      size={44}
                      src={category?.profilePicture}
                    >
                      {category?.category?.name[0]}
                    </Avatar>
                  </div>
                  <div>
                    <div> {category?.category?.name}</div>
                    <div>
                      {" "}
                      {/* <span>Role : {official?.role}</span>{" "} */}
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col className="text-center">
              <Button
                key="submit"
                htmlType="submit"
                type="primary"
                //   onClick={this.handleOk}
                className="px-30"
              >
                Remove Catagory
              </Button>
              <Button
                key="submit"
                onClick={() => {
                  setShowCatagories(false);
                  setSelectedCategoryId([]);
                }}
                className="px-30 ml-10"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <DeleteConfirionModal
      isOpen={isModalOpen}
      setOpen={setIsModalOpen}
      onClose={()=>setIsModalOpen(false)}
      onConfirm={deleteTatami}
      messageText={'Please confirm it by entering your password to take the action.'}
      password={givenPassword}
      setPassword={setGivenPassword}
      />

      <Col
        span={24}
        className="header-control"
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {selectedRowKeys.length > 0 ? (
            <Button
              type="danger"
              class="button-29"
              role="button"
              style={{ marginRight: "5px" }}
              onClick={() => setIsModalOpen(true)}
            >
              DELETE TATAMI
            </Button>
          ) : null}
          {selectedRowKeys.length > 0 && selectedRowKeys.length < 2 && (
            <Button
              type="primary"
              className="px-25"
              style={{ marginRight: "5px" }}
              onClick={() => setModalOpen(!modelOpen)}
            >
              ASSIGN OFFICIALS
            </Button>
          )}

          {/* {selectedRowKeys.length > 0 && selectedRowKeys.length < 2 && (
            <Button
              type="primary"
              className="px-25"
              style={{ marginRight: "5px" }}
              onClick={() => setModalCategoryOpen(!modelOpen)}
            >
              ASSIGN CATEGORY
            </Button>
          )} */}
          {[
            "stateHead",
            "stateAdmin",
            "districtHead",
            "districtAdmin",
          ].includes(user?.role) && (
            <Button type="primary" className="px-25" onClick={handleClick}>
              CREATE NEW TATAMI
            </Button>
          )}
        </div>
        <Input
          className="header-search"
          placeholder="Search here..."
          prefix={<SearchOutlined />}
          style={{ width: "300px" }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Col>
      <Col span={24}>
        <CustomTable>
          <Table
            tableLayout="auto"
            columns={columns}
            dataSource={data}
            scroll={scroll}
            rowSelection={rowSelection}
            title={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                {/* {selectedRowKeys.length > 0 ? (
                  <DeleteOutlined
                    onClick={deleteTatami}
                    style={{
                      fontSize: "18px",
                      color: selectedRowKeys.length > 0 ? "red" : "gray",
                      cursor:
                        selectedRowKeys.length > 0 ? "pointer" : "not-allowed",
                    }}
                    disabled={selectedRowKeys.length === 0}
                  />
                ) : null} */}
              </div>
            )}
            locale={{
              emptyText: loading ? <Skeleton round active={true} /> : <Empty />,
            }}
            pagination={{
              defaultPageSize: 10,
              defaultCurrent: 1,
              showSizeChanger: true,
              pageSizeOptions: ["10", "25", "50", "100"],
              total: count,
              onChange: (currentPage, size) => {
                setPagination({ size, currentPage });
              },
              showTotal: (total, range) => (
                <ShowTotal
                  selectedRowKeys={selectedRowKeys}
                  total={total}
                  currentPageItem={range[1] - range[0] + 1}
                  ranged={range}
                />
              ),
            }}
            onChange={handleChangeTable}
          />
        </CustomTable>
      </Col>
    </Row>
  );
};

export default Tatami;
