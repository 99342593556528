import React, { useState, useCallback, useEffect } from "react";
// import { ReactFlow } from '@xyflow/react';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle,
  MiniMap,
  Controls,
  Background,
} from "reactflow";
import fixtures from "../../../../services/fixtures";
import { notify } from "../../../../utils/helper";

const EventMatchFixture = ({
  groupMatches,
  groupMatchesRound1,
  catId,
  getMatchesByGroupName,
  selectedGroup,
}) => {

  let redConnectingPathStyle = {
    strokeWidth: 2,
    stroke: "red",
  };
  let blueConnectingPathStyle = {
    strokeWidth: 2,
    stroke: "blue",
  };
  const initialNodes = [
    {
      id: "1",
      position: { x: 0, y: 0 },
      data: { label: "Player 1" },
      type: "rightLabelNode",
      playerId: "1",
    },
    {
      id: "2",
      position: { x: 0, y: 70 },
      data: { label: "Player 2" },
      type: "rightLabelNode",
      playerId: "2",
    },
    {
      id: "3",
      position: { x: 0, y: 140 },
      data: { label: "Player 3" },
      type: "rightLabelNode",
      playerId: "3",
    },
    {
      id: "4",
      position: { x: 0, y: 210 },
      data: { label: "Player 4" },
      type: "rightLabelNode",
      playerId: "4",
    },
    {
      id: "5",
      position: { x: 0, y: 280 },
      data: { label: "Player 5" },
      type: "rightLabelNode",
      playerId: "5",
    },
    {
      id: "6",
      position: { x: 0, y: 350 },
      data: { label: "Player 6" },
      type: "rightLabelNode",
      playerId: "6",
    },
    {
      id: "7",
      position: { x: 0, y: 420 },
      data: { label: "Player 7" },
      type: "rightLabelNode",
      playerId: "7",
    },
    {
      id: "8",
      position: { x: 0, y: 490 },
      data: { label: "Player 8" },
      type: "rightLabelNode",
      playerId: "8",
    },
    {
      id: "9",
      position: { x: 0, y: 560 },
      data: { label: "Player 9" },
      type: "rightLabelNode",
      playerId: "9",
    },
    {
      id: "10",
      position: { x: 0, y: 630 },
      data: { label: "Player 10" },
      type: "rightLabelNode",
      playerId: "10",
    },
    {
      id: "11",
      position: { x: 0, y: 700 },
      data: { label: "Player 11" },
      type: "rightLabelNode",
      playerId: "11",
    },
    {
      id: "12",
      position: { x: 0, y: 770 },
      data: { label: "Player 12" },
      type: "rightLabelNode",
      playerId: "12",
    },
    {
      id: "13",
      position: { x: 0, y: 840 },
      data: { label: "Player 13" },
      type: "rightLabelNode",
      playerId: "13",
    },
    {
      id: "14",
      position: { x: 0, y: 910 },
      data: { label: "Player 14" },
      type: "rightLabelNode",
      playerId: "14",
    },
    {
      id: "15",
      position: { x: 0, y: 980 },
      data: { label: "Player 15" },
      type: "rightLabelNode",
      playerId: "15",
    },
    {
      id: "16",
      position: { x: 0, y: 1050 },
      data: { label: "Player 16" },
      type: "rightLabelNode",
      playerId: "16",
    },

    // Round 2
    {
      id: "17",
      position: { x: 200, y: 35 },
      data: { label: "" },
      type: "rightLabelNode",
    },
    {
      id: "18",
      position: { x: 200, y: 175 },
      data: { label: "" },
      type: "rightLabelNode",
    },
    {
      id: "19",
      position: { x: 200, y: 315 },
      data: { label: "" },
      type: "rightLabelNode",
    },
    {
      id: "20",
      position: { x: 200, y: 455 },
      data: { label: "" },
      type: "rightLabelNode",
    },
    {
      id: "21",
      position: { x: 200, y: 595 },
      data: { label: "" },
      type: "rightLabelNode",
    },
    {
      id: "22",
      position: { x: 200, y: 735 },
      data: { label: "" },
      type: "rightLabelNode",
    },
    {
      id: "23",
      position: { x: 200, y: 875 },
      data: { label: "" },
      type: "rightLabelNode",
    },
    {
      id: "24",
      position: { x: 200, y: 1015 },
      data: { label: "" },
      type: "rightLabelNode",
    },

    // Round 3
    {
      id: "25",
      position: { x: 400, y: 110 },
      data: { label: "" },
      type: "rightLabelNode",
    },
    {
      id: "26",
      position: { x: 400, y: 390 },
      data: { label: "" },
      type: "rightLabelNode",
    },
    {
      id: "27",
      position: { x: 400, y: 670 },
      data: { label: "" },
      type: "rightLabelNode",
    },
    {
      id: "28",
      position: { x: 400, y: 950 },
      data: { label: "" },
      type: "rightLabelNode",
    },

    // Round 4
    {
      id: "29",
      position: { x: 600, y: 250 },
      data: { label: "" },
      type: "rightLabelNode",
    },
    {
      id: "30",
      position: { x: 600, y: 810 },
      data: { label: "" },
      type: "rightLabelNode",
    },

    // Final
    {
      id: "31",
      position: { x: 900, y: 540 },
      data: { label: "Winner" },
      type: "rightLabelNode",
    },
  ];

  const initialEdges = [
    // Round 1 to Round 2
    {
      id: "e1-17",
      source: "1",
      target: "17",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e2-17",
      source: "2",
      target: "17",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },
    {
      id: "e3-18",
      source: "3",
      target: "18",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e4-18",
      source: "4",
      target: "18",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },
    {
      id: "e5-19",
      source: "5",
      target: "19",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e6-19",
      source: "6",
      target: "19",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },
    {
      id: "e7-20",
      source: "7",
      target: "20",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e8-20",
      source: "8",
      target: "20",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },
    {
      id: "e9-21",
      source: "9",
      target: "21",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e10-21",
      source: "10",
      target: "21",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },
    {
      id: "e11-22",
      source: "11",
      target: "22",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e12-22",
      source: "12",
      target: "22",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },
    {
      id: "e13-23",
      source: "13",
      target: "23",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e14-23",
      source: "14",
      target: "23",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },
    {
      id: "e15-24",
      source: "15",
      target: "24",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e16-24",
      source: "16",
      target: "24",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },

    // Round 2 to Round 3
    {
      id: "e17-25",
      source: "17",
      target: "25",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e18-25",
      source: "18",
      target: "25",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },
    {
      id: "e19-26",
      source: "19",
      target: "26",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e20-26",
      source: "20",
      target: "26",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },
    {
      id: "e21-27",
      source: "21",
      target: "27",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e22-27",
      source: "22",
      target: "27",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },
    {
      id: "e23-28",
      source: "23",
      target: "28",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e24-28",
      source: "24",
      target: "28",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },

    // Round 3 to Round 4
    {
      id: "e25-29",
      source: "25",
      target: "29",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e26-29",
      source: "26",
      target: "29",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },
    {
      id: "e27-30",
      source: "27",
      target: "30",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e28-30",
      source: "28",
      target: "30",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },

    // Round 4 to Final
    {
      id: "e29-31",
      source: "29",
      target: "31",
      type: "smoothstep",
      style: redConnectingPathStyle,
    },
    {
      id: "e30-31",
      source: "30",
      target: "31",
      type: "smoothstep",
      style: blueConnectingPathStyle,
    },
  ];


  const [selectedNodes, setSelectedNodes] = useState([]);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState(initialEdges);
  // console.log('groupMatches-dia',groupMatches);
  // console.log('selectedGroup-dia',selectedGroup);


  const ParticipantsNameNode = ({ data, id }) => {
    // console.log("data :", data);

    const handleChange = (event) => {
      data.onChange(id, event.target.value);
    };

    // Handle player selection
    const handleSelect = () => {
      if (id === 31 && data?.round === 5) {
        return;
      } else if (id <= 16 && data?.round === 1) {
        setSelectedNodes((prev) => {
          if (prev.includes(id)) {
            return prev.filter((nodeId) => nodeId !== id); // Deselect if already selected
          }
          return [...prev, id]; // Add to selection if not selected
        });
      } else if (id >= 17 && id <= 24 && data?.round === 2) {
        setSelectedNodes((prev) => {
          if (prev.includes(id)) {
            return prev.filter((nodeId) => nodeId !== id);
          }
          return [...prev, id];
        });
      } else if (id >= 25 && id <= 28 && data?.round === 3) {
        setSelectedNodes((prev) => {
          if (prev.includes(id)) {
            return prev.filter((nodeId) => nodeId !== id);
          }
          return [...prev, id];
        });
      } else if (id >= 29 && id <= 30 && data?.round === 4) {
        setSelectedNodes((prev) => {
          if (prev.includes(id)) {
            return prev.filter((nodeId) => nodeId !== id);
          }
          return [...prev, id];
        });
      } else {
        return;
      }
    };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          border: selectedNodes.includes(id) ? "2px solid blue" : "none",
        }}
        onClick={handleSelect}
      >
        <input
          style={{
            width: 150,
            height: 40,
            textAlign: "center",
            border: "1px solid #000 ",
            borderRadius: 4,
            fontWeight: "bold",
            background: "#fff",
          }}
          value={data.label}
          onChange={handleChange}
          disabled={
            id <= 16 && data?.round === 1
              ? false
              : id >= 17 && id <= 24 && data?.round === 2
              ? false
              : id >= 25 && id <= 28 && data?.round === 3
              ? false
              : id >= 29 && id <= 30 && data?.round === 4
              ? false
              : true || data?.label == "bye"  
          }
        />
        <Handle type="source" position="right" style={{ background: "#555" }} />
        <Handle type="target" position="left" style={{ background: "#555" }} />
      </div>
    );
  };

  const nodeTypes = {
    rightLabelNode: ParticipantsNameNode,
  };

  useEffect(() => {
    const allapidata = groupMatches.map((obj) => {
      if (obj?.round === 1) {
        if (obj?.type === "match" && obj?.position?.second <= 16) {
          return {
            participant1: {
              name:
                obj?.participant1?.firstName + " " + obj?.participant1?.lastName,
              position: obj?.position?.second,
              participantId: obj?.participant1?._id,
              round: obj?.round,
            },
            participant2: {
              name:
                obj?.participant2?.firstName + " " + obj?.participant2?.lastName,
              position: obj?.position?.first,
              participantId: obj?.participant2?._id,
              round: obj?.round,
            },
          };
        }
        if (
          ["bye", null, undefined].includes(obj?.type) &&
          obj?.position?.second <= 16
        ) {
          return {
            participant1: {
              name: obj?.participant1
                ? obj?.participant1?.firstName + " " + obj?.participant1?.lastName
                : "bye",
              position: obj?.position?.second,
              participantId: obj?.participant1?._id,
              round: obj?.round,
            },
            participant2: {
              name: obj?.participant2
                ? obj?.participant2?.firstName + " " + obj?.participant2?.lastName
                : "bye",
              position: obj?.position?.first,
              participantId: obj?.participant2?._id,
              round: obj?.round,
            },
          };
        }
      }
      if (obj?.round === 2) {
        if (obj?.type === "match") {
          return {
            participant1: {
              name:
                obj?.participant1?.firstName + " " + obj?.participant1?.lastName,
              position: obj?.position?.second + 16,
              participantId: obj?.participant1?._id,
              round: obj?.round,
            },
            participant2: {
              name:
                obj?.participant2?.firstName + " " + obj?.participant2?.lastName,
              position: obj?.position?.first + 16,
              participantId: obj?.participant2?._id,
              round: obj?.round,
            },
          };
        }
        if (["bye", null, undefined].includes(obj?.type)) {
          return {
            participant1: {
              name: obj?.participant1
                ? obj?.participant1?.firstName + " " + obj?.participant1?.lastName
                : "bye",
              position: obj?.position?.second + 16,
              participantId: obj?.participant1?._id,
              round: obj?.round,
            },
            participant2: {
              name: obj?.participant2
                ? obj?.participant2?.firstName + " " + obj?.participant2?.lastName
                : "bye",
              position: obj?.position?.first + 16,
              participantId: obj?.participant2?._id,
              round: obj?.round,
            },
          };
        }
      }
      if (obj?.round === 3) {
        if (obj?.type === "match") {
          return {
            participant1: {
              name:
                obj?.participant1?.firstName + " " + obj?.participant1?.lastName,
              position:
                obj?.position?.second === 3
                  ? 27
                  : obj?.position?.second === 1
                  ? 25
                  : obj?.position?.second === 2
                  ? 26
                  : obj?.position?.second === 4
                  ? 28
                  : 0,
              participantId: obj?.participant1?._id,
              round: obj?.round,
            },
            participant2: {
              name:
                obj?.participant2?.firstName + " " + obj?.participant2?.lastName,
              position:
                obj?.position?.first === 4
                  ? 28
                  : obj?.position?.first === 2
                  ? 26
                  : obj?.position?.first === 1
                  ? 25
                  : obj?.position?.first === 3
                  ? 27
                  : 0,
              participantId: obj?.participant2?._id,
              round: obj?.round,
            },
          };
        }
        if (obj?.type === "bye") {
          return {
            participant1: {
              name: obj?.participant1
                ? obj?.participant1?.firstName + " " + obj?.participant1?.lastName
                : "bye",
              // position: obj?.position?.second
              position:
                obj?.position?.second === 3
                  ? 27
                  : obj?.position?.second === 1
                  ? 25
                  : obj?.position?.second === 2
                  ? 26
                  : obj?.position?.second === 4
                  ? 28
                  : 0,
              participantId: obj?.participant1?._id,
              round: obj?.round,
            },
            participant2: {
              name: obj?.participant2
                ? obj?.participant2?.firstName + " " + obj?.participant2?.lastName
                : "bye",
              // position: obj?.position?.first
              position:
                obj?.position?.first === 4
                  ? 28
                  : obj?.position?.first === 2
                  ? 26
                  : obj?.position?.first === 1
                  ? 25
                  : obj?.position?.first === 3
                  ? 27
                  : 0,
              participantId: obj?.participant2?._id,
              round: obj?.round,
            },
          };
        }
      }
  
      if (obj?.round === 4) {
        if (obj?.type === "match") {
          return {
            participant1: {
              name:
                obj?.participant1?.firstName + " " + obj?.participant1?.lastName,
              position:
                obj?.position?.second === 1
                  ? 29
                  : obj?.position?.second === 2
                  ? 30
                  : 0,
              participantId: obj?.participant1?._id,
              round: obj?.round,
            },
            participant2: {
              name:
                obj?.participant2?.firstName + " " + obj?.participant2?.lastName,
              position:
                obj?.position?.first === 1
                  ? 29
                  : obj?.position?.first === 2
                  ? 30
                  : 0,
              participantId: obj?.participant2?._id,
              round: obj?.round,
            },
          };
        }
        if (obj?.type === "bye") {
          return {
            participant1: {
              name: obj?.participant1
                ? obj?.participant1?.firstName + " " + obj?.participant1?.lastName
                : "bye",
              position:
                obj?.position?.second === 1
                  ? 29
                  : obj?.position?.second === 2
                  ? 30
                  : 0,
              participantId: obj?.participant1?._id,
              round: obj?.round,
            },
            participant2: {
              name: obj?.participant2
                ? obj?.participant2?.firstName + " " + obj?.participant2?.lastName
                : "bye",
              position:
                obj?.position?.first === 1
                  ? 29
                  : obj?.position?.first === 2
                  ? 39
                  : 0,
              participantId: obj?.participant2?._id,
              round: obj?.round,
            },
          };
        }
      }
  
      if (obj?.round === 5) {
        if (obj?.type === "match") {
          return {
            participant1: {
              name:
                obj?.participant1?.firstName + " " + obj?.participant1?.lastName,
              position: obj?.position?.second === 1 ? 31 : 0,
              round: obj?.round,
            },
            participant2: {
              name:
                obj?.participant2?.firstName + " " + obj?.participant2?.lastName,
              position: obj?.position?.first === 1 ? 31 : 0,
              round: obj?.round,
            },
          };
        }
      }
      return null;
    });
  
    let dataToshow = allapidata.flatMap((obj) => [
      obj?.participant1,
      obj?.participant2,
    ]);
  
  
  
    const updatedNodes = initialNodes.map((node) => {
      const match = dataToshow.find((data) => {
        if (data) {
          return data?.position === parseInt(node?.id);
        }
        return false;
      });
      // Only update the node if a match is found for the postion and id
      if (match) {
        if (match?.name) {
          node.data.label = match?.name;
          node.data.round = match?.round;
          node.playerId = match?.participantId;
        }
      }
      return node;
    });

    setNodes(updatedNodes);
  }, [groupMatches]);



  // Function to handle name changes
  const handleNameChange = (nodeId, newName) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === nodeId
          ? { ...node, data: { ...node.data, label: newName } }
          : node
      )
    );
  };

  const handleSwapFixtureParticipants = async (catId, payload) => {
    try {
      const res = await fixtures?.swapFixtureParticipants(catId, payload);
      // getMatchesByGroupName(catId, selectedGroup);
      // setNodes(updatedNodes);
      notify("success", res?.data?.message); 
      getMatchesByGroupName(catId,selectedGroup);
    } catch (error) {}
  };

  // Function to swap player names when two are selected
  const swapSelectedPlayers = () => {
    if (selectedNodes.length === 2) {
      const [firstNodeId, secondNodeId] = selectedNodes;

      const firstNode = nodes.find((node) => node.id === firstNodeId);
      const secondNode = nodes.find((node) => node.id === secondNodeId);

      if (
        firstNode &&
        secondNode &&
        firstNode?.data?.label !== "bye" &&
        secondNode?.data?.label !== "bye"
      ) {
        handleSwapFixtureParticipants(catId, {
          participant1: firstNode?.playerId,
          participant2: secondNode?.playerId,
        });
        // Swap the names
        // setNodes((nds) =>
        //   nds.map((node) => {
        //     if (node.id === firstNodeId) {
        //       return {
        //         ...node,
        //         data: { ...node.data, label: secondNode.data.label },
        //       };
        //     }
        //     if (node.id === secondNodeId) {
        //       return {
        //         ...node,
        //         data: { ...node.data, label: firstNode.data.label },
        //       };
        //     }
        //     return node;
        //   })
        // );
      }

      // Reset the selection after swapping
      setSelectedNodes([]);
    }
  };

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) => addEdge({ ...params, type: "bracketEdge" }, eds)),
    [setEdges]
  );

  // Swap players whenever two are selected
  useEffect(() => {
    if (selectedNodes.length === 2) {
      swapSelectedPlayers();
    }
  }, [selectedNodes]); // Trigger effect when two players are selected


  return (
    <div style={{ height: "120vh", width: "100vw", paddingLeft: "35px" }}>
      <div style={{ height: "100%", width: "65%" }}>
        <ReactFlow
          nodes={nodes.map((node) => ({
            ...node,
            data: { ...node.data, onChange: handleNameChange },
          }))}
          edges={edges}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          //   defaultEdges={defaultEdges}
          nodesDraggable={false}
          nodesConnectable={false}
          //   elementsSelectable={false}
          // panOnDrag={false}
          // zoomOnScroll={false}
          // zoomOnDoubleClick={false}
          fitView
          style={{ width: "100%", height: "100%" }}
        >
          <Controls />
          <MiniMap />
          <Background color="#ccc" variant="dots" gap={12} size={4} />
        </ReactFlow>
      </div>
    </div>
  );
};

export default EventMatchFixture;
