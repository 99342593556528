import {
  Divider,
  Table,
  Tag,
  Row,
  Col,
  Form,
  Drawer,
  Select,
  Input,
  Space,
  Button,
  Skeleton,
  Empty,
} from "antd";
import React, { useEffect, useState } from "react";
import { FaEye, FaEdit, FaTrashAlt } from "react-icons/fa";
import { useHistory, Link } from "react-router-dom";
import event from "../../../services/event";
import { BsArrowRight } from "react-icons/bs";
import moment from "moment";
import { debounce, notify, ShowTotal } from "../../../utils/helper";
import AddEntriesModal from "./AddEntriesModal";
import { extractToken } from "../../../utils/localStorage";
import UploadEntriesModal from "./UploadEntriesModal";
import { SearchOutlined } from "@ant-design/icons";
import Filter from "../../../utils/Filter";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../store/actions/bredcrumb";
import DeleteConfirionModal from "../../modal/DeleteConfirionModal";
import { CustomTable } from "./Styled";

const { Option } = Select;

const EventManagement = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openAssignDrawer, setOpenAssignDrawer] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [openEntriesModal, setOpenEntriesModal] = useState(false);
  const [openUploadEntriesModal, setOpenUploadEntriesModal] = useState(false);
  const [selectedEventStatus, setSelectedEventStatus] = useState("");
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [count, setCount] = useState("");
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const [filterStatus, setFilterStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const user = extractToken();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [givenPassword, setGivenPassword] = useState("")
  const [selectedID, setSelectedID] = useState(null);

  const getAllEvent = async (searchText = "") => {
    try {
      setLoading(true);
      const res = await event.getAllEvent(
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText,
        filterStatus
      );
      setAllEvents(res?.data?.response?.records);
      setSelectedRowKeys([]);
      setCount(res?.data?.response?.totalItems);
      setLoading(false);
    } catch (error) {}
  };

  const handleSearch = debounce((value) => {
    getAllEvent(value);
  },500);
  useEffect(() => {
    getAllEvent();
  }, [
    pagination?.size,
    pagination?.currentPage,
    sortingColumn,
    sortingOrder,
    searchText,
    filterStatus,
  ]);

  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
  };

  const getActiveEvent = async () => {
    try {
      const res = await event.getActiveEvent();
      setAllEvents(res?.data);
      setSelectedRowKeys([]);
    } catch (error) {}
  };

  const changeEventStatus = async () => {
    try {
      const res = await event.statusChange(selectedRowKeys[0], {
        registrationStatus: selectedEventStatus,
      });
      notify("success", "Status has been changed.");
      setOpenAssignDrawer(false);
      getAllEvent();
    } catch (error) {}
  };

  const deleteEvent = async (id) => {
    try {
      const res = await event.deleteEvent({
        eventIds: id ? [id] : selectedRowKeys,
        password: givenPassword
      });
      setSelectedRowKeys([]);
      notify("success", "Event Deleted Successfully.");
      getAllEvent();
      setIsModalOpen(false)
    } catch (error) {
      notify("error", error.data.message)
    }
  };

  const eventColumns = [
    // {
    //   title: "S.No",
    //   dataIndex: "sno",
    //   key: "sno",
    // },
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      width: 200,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      align: "center",
      width: 250,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      align: "center",
      width: 120,
    },
    {
      title: "Participants",
      dataIndex: "participants",
      key: "participants",
      align: "center",
      width: 120,
    },
    {
      title: "Organizer",
      dataIndex: "organizer",
      key: "organizer",
      align: "center",
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: 80,
    },
  ];

  let data = [];
  for (let item of allEvents || []) {
    data.push({
      key: item?._id,
      eventName: (
        <div style={{ width: "200px" }}>
          <Link to={`/pages/events/all-events/${item?._id}`} class="ellipsis">
            {item?.name}
          </Link>
        </div>
      ),
      duration: (
        <div style={{ width: "250px" }}>
          <span>
            {moment(item?.startDate).format("ddd DD MMM YYYY") || "-"}
          </span>
          <span style={{ padding: "0 5px" }}>
            <BsArrowRight />
          </span>
          <span>{moment(item?.endDate).format("ddd DD MMM YYYY") || "-"}</span>
        </div>
      ),
      location: item?.district ? (
        <div style={{ width: "200px" }}>{item?.district}</div>
      ) : (
        <div style={{ width: "120px" }}> - </div>
      ),
      participants: item?.participants ? (
        <div style={{ width: "200px" }}>{item?.participants}</div>
      ) : (
        <div style={{ width: "120px" }}> - </div>
      ),
      organizer: item?.organizerName ? (
        <div style={{ width: "150px" }}>{item?.organizerName}</div>
      ) : (
        <div style={{ width: "120px" }}> - </div>
      ),
      status: <Tag>{item?.registrationStatus}</Tag>,
      actions: (
        <div style={{ width: "80px" }}>
          <FaEdit
            onClick={() => history.push(`/pages/events/update/${item?._id}`)}
            style={{ marginRight: 16, cursor: "pointer" }}
          />
          <FaTrashAlt
            onClick={() => {
              setSelectedID(item?._id)
              setIsModalOpen(true)
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
    });
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
    handleChange: (a, b, c) => {},
  };

  useEffect(() => {
    getAllEvent();
    getActiveEvent();
    dispatch(
      setBreadcrumb({
        title: "All Events",
        subtitle: "",
        url: "",
      })
    );
  }, []);

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 325px)", x: true } : null;

  return (
    <>
      <AssignTeamDrawer
        visible={openAssignDrawer}
        onClose={() => setOpenAssignDrawer(false)}
        changeEventStatus={changeEventStatus}
        setSelectedEventStatus={setSelectedEventStatus}
      />
      {openEntriesModal && (
        <AddEntriesModal
          open={openEntriesModal}
          setOpen={setOpenEntriesModal}
          getActiveEvent={getActiveEvent}
          selectedEvent={selectedRowKeys}
        />
        
      )}
      <DeleteConfirionModal
      isOpen={isModalOpen}
      setOpen={setIsModalOpen}
      onClose={()=>setIsModalOpen(false)}
      onConfirm={() => deleteEvent(selectedID)}
      messageText={'Please confirm it by entering your password to take the action.'}
      password={givenPassword}
      setPassword={setGivenPassword}
      />
      
      {openUploadEntriesModal && (
        <UploadEntriesModal
          open={openUploadEntriesModal}
          setOpen={setOpenUploadEntriesModal}
          selectedEvent={selectedRowKeys}
        />
      )}
      <Row gutter={[0, 24]}>
        <Col
          span={12}
          className="header-control"
          style={{ justifyContent: "left" }}
        >
          <Filter
            show={show}
            setShow={setShow}
            data={[
              {
                title: "Status",
                list: ["active", "closed"],
                filterList: filterStatus,
                setFilterList: setFilterStatus,
              },
            ]}
          />
          <Input
            className="header-search"
            placeholder="Search here..."
            prefix={<SearchOutlined />}
            style={{ width: "300px" }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
        <Col span={12} className="d-flex justify-content-end">
          {[
            "stateHead",
            "stateAdmin",
            "districtHead",
            "districtAdmin",
            "coach",
          ].includes(user?.role) && (
            <>
              {selectedRowKeys?.length === 1 && (
                <div style={{ width: "125px" }}>
                  <button
                    class="button-29"
                    role="button"
                    onClick={() => setOpenEntriesModal(true)}
                  >
                    Add Entries
                  </button>
                </div>
              )}
              {selectedRowKeys?.length === 1 && (
                <div className="px-2">
                  <button
                    class="button-29"
                    role="button"
                    onClick={() => setOpenUploadEntriesModal(true)}
                  >
                    Import Entries
                  </button>
                </div>
              )}
            </>
          )}

          {[
            "stateHead",
            "stateAdmin",
            "districtHead",
            "districtAdmin",
            "coach",
          ].includes(user?.role) && (
            <>
              {selectedRowKeys?.length === 1 && (
                <div className="px-2">
                  <button
                    class="button-29"
                    role="button"
                    onClick={() => setOpenAssignDrawer(true)}
                  >
                    Change Status
                  </button>
                </div>
              )}
              {selectedRowKeys?.length > 1 && (
                <div className="px-2">
                  <Button
                  style={{fontSize:"13px"}}
                    type="danger"
                    role="button"
                    onClick={() => {
                      setSelectedID(null)
                      setIsModalOpen(true)
                    }}
                  >
                    Delete Event
                  </Button>
                </div>
              )}
              {selectedRowKeys?.length === 0 && (
                <div className="px-2">
                  <button
                    class="button-29"
                    role="button"
                    onClick={() => history.push(`/pages/create/events`)}
                  >
                    Create Event
                  </button>
                </div>
              )}
            </>
          )}
        </Col>

        <Col span={24}>
          <CustomTable>
            <Table
              columns={eventColumns}
              dataSource={data}
              tableLayout="auto"
              scroll={scroll}
              rowSelection={rowSelection}
              pagination={{
                defaultPageSize: 10,
                defaultCurrent: 1,
                showSizeChanger: true,
                pageSizeOptions: ["10", "25", "50", "100"],
                total: count,
                onChange: (currentPage, size) => {
                  setPagination({ size, currentPage });
                },
                showTotal: (total, range) => (
                  <ShowTotal
                    selectedRowKeys={selectedRowKeys}
                    total={total}
                    currentPageItem={range[1] - range[0] + 1}
                    ranged={range}
                  />
                ),
              }}
              onChange={handleChangeTable}
              locale={{
                emptyText: loading ? (
                  <Skeleton
                    round
                    active={true}
                    paragraph={{
                      rows: 10,
                    }}
                  />
                ) : (
                  <Empty />
                ),
              }}
            />
          </CustomTable>
        </Col>
      </Row>
    </>
  );
};

export default EventManagement;

const AssignTeamDrawer = ({
  visible,
  onClose,
  changeEventStatus,
  setSelectedEventStatus,
}) => {
  const [form] = Form.useForm();

  return (
    <Drawer
      title="Change Status"
      placement="right"
      onClose={onClose}
      open={visible}
      width={400}
    >
      <Form form={form} layout="vertical" onFinish={changeEventStatus}>
        <Form.Item
          name="status"
          label="Select Status"
          rules={[{ required: true, message: "Please select a status" }]}
        >
          <Select
            placeholder="Select Status"
            onChange={(e) => setSelectedEventStatus(e)}
          >
            <Option value="active">Active</Option>
            <Option value="closed">Closed</Option>
          </Select>
        </Form.Item>

        {/* <Form.Item
          name="referee"
          label="Assign Referee"
          rules={[{ required: true, message: "Please assign a referee" }]}
        >
          <Select placeholder="Select a referee">
            <Option value="referee1">Referee 1</Option>
            <Option value="referee2">Referee 2</Option>
            <Option value="referee3">Referee 3</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="coach"
          label="Assign Coach"
          rules={[{ required: true, message: "Please assign a coach" }]}
        >
          <Select placeholder="Select a coach">
            <Option value="coach1">Coach 1</Option>
            <Option value="coach2">Coach 2</Option>
            <Option value="coach3">Coach 3</Option>
          </Select>
        </Form.Item>

        <Form.Item name="teamMembers" label="Assign Team Members">
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Add team members"
            tokenSeparators={[","]}
          >
            <Option value="teamMember1">Team Member 1</Option>
            <Option value="teamMember2">Team Member 2</Option>
            <Option value="teamMember3">Team Member 3</Option>
          </Select>
        </Form.Item> */}

        {/* <Form.Item name="notes" label="Notes">
          <Input.TextArea rows={4} placeholder="Add any notes" />
        </Form.Item> */}

        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" className="button-29" htmlType="submit">
              Change Status
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
