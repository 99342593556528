import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import BgProfile from "../../images/karate_picture_02.jpg";
import { Row, Col, Card, Avatar, Button } from "antd";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { BsGlobe2 } from "react-icons/bs";
import profilavatar from "../../images/face-1.jpg";
import convesionImg from "../../images/face-3.jpg";
import convesionImg2 from "../../images/face-4.jpg";
import convesionImg3 from "../../images/face-5.jpeg";

import logo1 from "../../images/logo-slack.svg";
import ava1 from "../../images/logo-spotify.svg";
import ava2 from "../../images/logo-xd.svg";
import ava3 from "../../images/logo-asana.svg";
import ava4 from "../../images/logo-invision.svg";
import team from "../../images/team-3.jpg";

import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../store/actions/bredcrumb";
import event from "../../../services/event";
import moment from "moment";

const UpcomingEvents = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  function onChange(checked) {}

  const [eventdata, setEventdata] = useState([]);

  const getEventByStatus = async (status) => {
    try {
      const response = await event.getEventByStatus(status);
      const records = response?.data?.response?.records || [];
      setEventdata(records);
    } catch (error) {}
  };

  const truncateName = (title, maxLength) => {
    return title.length > maxLength ? title.slice(0, maxLength) + "..." : title;
  };

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: "Events",
        subtitle: "Upcoming Events",
        url: "",
      })
    );
    getEventByStatus("draft");
  }, []);

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Row className="UpcomingEvent-title">
        <Col>
          <h5 className="font-semibold">Upcoming Events in India</h5>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        {eventdata.map((item, index) => (
          <Col span={24} lg={8} className="mb-24" key={index}>
            <Card
              bodyStyle={{ paddingTop: "0px" }}
              bordered={false}
              style={{ cursor: "pointer" }}
              className="card-project-2 header-solid"
              title={[
                <Row gutter={[24, 24]} key={0}>
                  <Col>
                    {
                      <Avatar
                        src={item.bannerImage}
                        style={{
                          width: "54px",
                          height: "54px",
                          lineHeight: "54px",
                          borderRadius: "8px",
                          padding: "16px",
                        }}
                      />
                    }
                  </Col>
                  <Col style={{ marginBottom: "-5px" }}>
                    <h6 className="font-semibold mb-10">
                      {truncateName(item.name, 25)}
                    </h6>
                    <h2
                      style={{ fontSize: ".95em", margin: "-6px 0 12px 0px" }}
                    >
                      Organizer: {item.organizerName}
                    </h2>
                  </Col>
                </Row>,
              ]}
            >
              <hr />
              <div className="UpcomingEvents-contant">
                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                  <strong>Event Type:</strong> {item.eventType}
                </p>
                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                  <strong>Level:</strong> {item.eventLevel}
                </p>
                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                  <strong>Duration:</strong>{" "}
                  {moment(item.startDate).format("DD/MM/YYYY")} -{" "}
                  {moment(item.endDate).format("DD/MM/YYYY")}
                </p>
                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                  <strong>Registration Deadline:</strong>{" "}
                  {moment(item.regisrationLastDate).format("DD/MM/YYYY")}
                </p>
                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                  <strong>Contact:</strong> {item.contact.name} ,{" "}
                  <strong>Phone:</strong> {item.contact.phone}
                </p>
                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                  <strong>Location:</strong> {item.district}, {item.state}-{" "}
                  {item.pinCode}
                </p>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
                className="UpcomingEvents-SocialMedia"
              >
                <a
                  href={`https://${item.social.website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="SocialMedia"
                >
                  <BsGlobe2 />
                </a>{" "}
                <a
                  href={item.social.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: "12px" }}
                  className="SocialMedia"
                >
                  <FaFacebookF />
                </a>{" "}
                <a
                  href={`https://twitter.com/${item.social.twitter}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: "12px" }}
                  className="SocialMedia"
                >
                  <FaTwitter />
                </a>
                <Button
                  type="primary"
                  size="small"
                  style={{ marginLeft: "93px" }}
                >
                  <Link to={`/pages/events/all-events/${index}`}>
                    VIEW DETAILS
                  </Link>
                </Button>
              </div>
            </Card>
          </Col>
        ))}
        ;
        {/* <Col span={24} lg={8} className="mb-24">
          <Card
            className="crm-bar-line header-solid h-full"
            bodyStyle={{
              padding: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <a href="#" className="text-center text-muted font-bold">
              <h3
                className="font-semibold text-muted mb-0"
                style={{ fontSize: "30px" }}
              >
                +
              </h3>
              <h5 className="font-semibold text-muted">New project</h5>
            </a>
          </Card>
        </Col> */}
      </Row>
    </>
  );
};

export default UpcomingEvents;
