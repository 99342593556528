import {
  Button,
  Col,
  Empty,
  Input,
  Row,
  Skeleton,
  Spin,
  Table,
  Tag,
} from "antd";
import ModalWrapper from "../../../modal/ModalWrapper";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import event from "../../../../services/event";
import {
  capitalizeFirstLetter,
  notify,
  ShowTotal,
} from "../../../../utils/helper";
import moment from "moment";
import { RedoOutlined, SearchOutlined } from "@ant-design/icons";
const AddCategoriesModal = ({ open, setOpen, getEventCategoryDetailsById,eventCategoryData }) => {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [maxPageSize, setMaxPageSize] = useState(1);
  const [count, setCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const tableRef = useRef(null);
  const [dataOnPageIncreament, setDataOnPageIncreament] = useState(false);
  const [isSeacrching, setIsSeacrching] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const query = useParams();

  useEffect(() => {
    if (count % 10 === 0) {
      setMaxPageSize(count / 10);
    }
    if (count % 10 !== 0) {
      setMaxPageSize(Math.floor(count / 10 + 1));
    }
  }, [count]);

  const getCategories = async (searchText = "") => {
    try {
      setLoading(true);
      let res = await event.getCategories(
        size,
        page,
        sortingColumn,
        sortingOrder,
        searchText
      );
      const newRecords = res?.data?.response?.records || [];
      const totalItems = res?.data?.response?.totalItems || 0;
      setCount(totalItems);
      if (page == 1 && !dataOnPageIncreament) {
        setApiData(newRecords);
      } else if (page <= maxPageSize && dataOnPageIncreament) {
        setApiData((prevData) => [...prevData, ...newRecords]);
      } else if (page == 1 && isSeacrching) {
        setApiData(newRecords);
      }
      setHasMore(searchText !== "" ? false : newRecords.length > 0);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, [pagination?.size, page, sortingColumn, sortingOrder]);

  useEffect(() => {
    const filterApiData = () => {
      const filtered = apiData.filter(
        (item) => !eventCategoryData.some((cat) => cat?.category?._id === item._id)
      );
      setFilteredData(filtered);
    };
    filterApiData();
  }, [apiData, eventCategoryData]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
    setApiData([]);
    setPage(1);
    setDataOnPageIncreament(false);
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      //   width: 120,
      sorter: {
        compare: (a, b) => a.nameToSort.localeCompare(b.nameToSort),
        multiple: 3,
      },
    },
    // {
    //   title: "TYPE",
    //   dataIndex: "type",
    //   //   width: 80,
    //   align: "center",
    // },

    // {
    //   title: "GENDER",
    //   dataIndex: "gender",
    //   //   width: 150,
    //   align: "center",
    // },
    {
      title: "AGE GROUP",
      dataIndex: "age",
      //   width: 70,
      align: "center",
    },
    {
      title: "TEAM",
      dataIndex: "team",
      //   width: 80,
      align: "center",
    },
  ];

  let data = [];
  for (let item of filteredData) {
    data.push({
      key: item?._id,
      name: (
        <div
          style={{
            minWidth: "180px",
            // maxWidth: "180",
            display: "flex",
            gap: "5px",
          }}
        >
          <div className="mx-2" style={{ minWidth: "160px" }}>
            <div
              className="ellipsis"
              style={{
                fontWeight: "600",
                fontSize: "13px",
                color: "var(--text-primary)",
                lineHeight: "15px",
                letterSpacing: "0.9px",
              }}
            >
              {item?.name || "-"}
            </div>
            <div style={{ paddingTop: "10px" }}>
              <Tag
                style={{
                  background: `${"#006A4E"} `,
                  color: "#ffffff",
                  border: "none",
                  borderRadius: "6px",
                  padding: "0 10px",
                  fontWeight: ` 600`,
                }}
              >
                {item?.type ? capitalizeFirstLetter(item?.type) : "-"}
              </Tag>
              <Tag
                style={{
                  background: `${"#006A4E"} `,
                  color: "#ffffff",
                  border: "none",
                  borderRadius: "6px",
                  padding: "0 10px",
                  fontWeight: ` 600`,
                }}
              >
                {item?.gender ? capitalizeFirstLetter(item?.gender) : "-"}
              </Tag>
            </div>
          </div>
        </div>
      ),
      nameToSort: item?.name || "-",
      type: (
        <div style={{}}>
          {item?.type ? capitalizeFirstLetter(item?.type) : "-"}
        </div>
      ),
      description: (
        <div
          style={{
            width: "300px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {item?.description ? capitalizeFirstLetter(item?.description) : "-"}
        </div>
      ),

      gender: (
        <div style={{ width: "150px" }}>
          {item?.gender ? capitalizeFirstLetter(item?.gender) : "-"}
        </div>
      ),
      age: (
        <div style={{ width: "100px" }}>
          {item?.age?.min
            ? item?.age?.min + " to " + item?.age?.max + " years"
            : "-"}
        </div>
      ),

      team: <div style={{ width: "100px" }}>{item?.team ? "Yes" : "No"}</div>,

      startDate: (
        <div style={{ width: "200px" }}>
          {moment(item?.startDate).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),

      endDate: (
        <div style={{ width: "200px" }}>
          {moment(item?.endDate).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),
    });
  }

  const handleSearch = (value) => {
    setApiData([]);
    getCategories(value);
    setIsSeacrching(true);
    setPage(1);
  };

  const handleAddCategories = async () => {
    try {
      const res = await event.addCategoriesInEvent({
        event: [query?.EventId],
        category: selectedRowKeys,
      });
      notify("success", "Categories Added Successfully.");
      getEventCategoryDetailsById(query?.EventId);
      setOpen(!open);
    } catch (err) {}
  };

  // Detect scroll event
  //   const handleScroll = (e) => {
  //     const { scrollTop, scrollHeight, clientHeight } = e.target;
  //     if (scrollHeight - scrollTop === clientHeight && hasMore) {
  //       if (page <= maxPageSize) {
  //         setPage((prevPage) => prevPage + 1);
  //       }
  //       getCategories();
  //     }
  //   };

  useEffect(() => {
    const tableBody = tableRef.current?.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore]);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 20 && !loading && hasMore) {
      if (page <= maxPageSize) {
        setDataOnPageIncreament(true);
        setIsSeacrching(false);
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  const components = {
    body: {
      wrapper: ({ children, ...restProps }) => (
        <tbody {...restProps}>
          {children}
          {hasMore && (
            <tr>
              <td colSpan={columns.length} style={{ textAlign: "center" }}>
                <Spin
                  indicator={<RedoOutlined style={{ fontSize: 32 }} spin />}
                />
              </td>
            </tr>
          )}
        </tbody>
      ),
    },
  };

  return (
    <ModalWrapper
      title="Add Categories"
      open={open}
      setOpen={setOpen}
      footer={null}
      width={700}
    >
      <Row>
        <Col
          span={24}
          className="header-control"
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Input
            className="header-search"
            placeholder="Search here..."
            prefix={<SearchOutlined />}
            style={{ width: "200px", padding: "0px 10px", borderRadius: "6px" }}
            onChange={(e) => {
              handleSearch(e.target.value);
              setSearchText(e.target.value);
            }}
            // value={searchText}
          />

          {selectedRowKeys.length > 0 && (
            <div>
              <Button type="primary" onClick={handleAddCategories}>
                Add Categories
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: "5px" }}
                onClick={() => setSelectedRowKeys([])}
              >
                Cancel
              </Button>
            </div>
          )}
        </Col>

        <Col span={24}>
          {/* <div
            // style={{ height: 400, overflowY: "auto" }}
            // onScroll={handleScroll}
          > */}
          <div ref={tableRef}>
            <Table
              tableLayout="auto"
              components={components}
              columns={columns}
              dataSource={data}
              rowSelection={rowSelection}
              onChange={handleChangeTable}
              locale={{
                emptyText: loading ? (
                  <Skeleton round active={true} />
                ) : (
                  <Empty />
                ),
              }}
              pagination={false}
              scroll={{ y: 400 }}
              //   onScroll={handleScroll}
            />
            {/* {hasMore && (
              <div style={{ textAlign: "center" }}>
                <Spin
                  indicator={<RedoOutlined style={{ fontSize: 32 }} spin />}
                />
              </div>
            )} */}
          </div>
        </Col>
      </Row>
    </ModalWrapper>
  );
};

export default AddCategoriesModal;
