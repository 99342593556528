import { Col, Drawer, Row, Typography, Empty } from "antd";
import React, { useEffect, useState } from "react";
import fixtures from "../../../../../services/fixtures";
import { FaMedal } from "react-icons/fa";
import { useParams } from "react-router-dom";

const { Title } = Typography;

const KataFinalResultDrawer = () => {
  const [results, setResults] = useState([]);
  const [ranks, setRanks] = useState({
    First: "",
    Second: "",
    Third: "",
    Four: "",
    Category: "",
  });

  const query = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    if (params?.type === "kata") {
      let kataRank = {
        First: null,
        Second: null,
        Third: null,
        Four: null,
        Category: null,
      };
      results.forEach((item) => {
        switch (item.rank) {
          case 1:
            kataRank.First = item.player.firstName;
            break;
          case 2:
            kataRank.Second = item.player.firstName;
            break;
          case 3:
            kataRank.Third = item.player.firstName;
            break;
          case 4:
            kataRank.Four = item.player.firstName;
            break;
        }
        kataRank.Category = item.category;
      });
      setRanks(kataRank);
    }
    if (params?.type === "kumite") {
      let kumiteRank = {
        Category: "",
        First: "",
        Second: "",
        Third: "",
        Four: "",
      };
      results?.results?.forEach((item) => {
        if (item.first) {
          kumiteRank.First = item.first.firstName;
        }
        if (item.second) {
          kumiteRank.Second = item.second.firstName;
        }
        if (item.third) {
          if (kumiteRank.Third == "") {
            kumiteRank.Third = item.third.firstName;
          } else {
            kumiteRank.Four = item.third.firstName;
          }
        }
        if (item.category) {
          kumiteRank.Category = item.category;
        }
      });
      setRanks(kumiteRank);
    }
  }, [results]);


  const getkataResult = async () => {
    try {
      const res = await fixtures.getkataResult(query?.catId);
      setResults(res?.data);
    } catch (error) {}
  };
  const getkumiteResult = async () => {
    try {
      const res = await fixtures.getkumiteResult(query?.catId);
      setResults(res?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (params?.type === "kata") {
      getkataResult();
    }
    if (params?.type === "kumite") {
      getkumiteResult();
    }
  }, []);

  let hasResults = false;

  if (params?.type === "kata") {
    hasResults = results && results?.length > 0;
  } else if (params?.type === "kumite") {
    hasResults =
      results &&
      Array.isArray(results?.results) &&
      results?.results?.length > 1;
  }

  return (
    <Drawer
      title=""
      placement="bottom"
      // onClose={() => setOpen(false)}
      open={true}
      width="100%"
      height="100vh"
      closable={false}
      style={{ padding: 0 }}
      bodyStyle={{ padding: 0, backgroundColor: "#282c34" }}
      keyboard={false}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          textAlign: "center",
          padding: "50px",
          backgroundColor: "#282c34",
          height: "100%",
        }}
      >
        {hasResults ? (
          <>
            <Col span={24} style={{ marginBottom: "48px" }}>
              <Title level={2} style={{ color: "#fff" }}>
                {params?.type} Final Result ( {ranks.Category} )
              </Title>
            </Col>

            <Row style={{ width: "100%" }}>
              <Col span={8} className="Kata-Second-Winner">
                <p style={{ color: "#fff", fontSize: "2em" }}>{ranks.Second}</p>
                <FaMedal
                  style={{
                    color: "#C0C0C0",
                    fontSize: "135px",
                  }}
                  className="Medal"
                />

                <Title level={2} style={{ color: "#fff" }}>
                  2nd
                </Title>
              </Col>

              <Col span={8} className="Kata-First-Winner">
                <p style={{ color: "#fff", fontSize: "2em" }}>{ranks.First}</p>
                <FaMedal
                  style={{
                    color: "#FFD700",
                    fontSize: "180px",
                  }}
                  className="Medal"
                />
                <Title level={1} style={{ color: "#fff" }}>
                  1st
                </Title>
              </Col>

              <Col span={8} className="Kata-Third-Winner">
                <div>
                  <p style={{ color: "#fff", fontSize: "2em" }}>
                    {ranks.Third}
                  </p>
                  <FaMedal
                    style={{
                      color: "#CD7F32",
                      fontSize: "90px",
                    }}
                    className="Medal"
                  />
                  <Title level={2} style={{ color: "#fff" }}>
                    3rd
                  </Title>
                </div>
                {ranks?.Four && (
                  <div>
                    <p style={{ color: "#fff", fontSize: "2em" }}>
                      {ranks.Four}
                    </p>
                    <FaMedal
                      style={{
                        color: "#CD7F32",
                        fontSize: "90px",
                      }}
                      className="Medal"
                    />
                    <Title level={2} style={{ color: "#fff" }}>
                      3rd
                    </Title>
                  </div>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <Col span={24} style={{ color: "#fff" }}>
            <Empty description={false} />
            <Title level={2} style={{ color: "#fff" }}>
              No Results Available
            </Title>
            <p style={{ color: "#aaa", fontSize: "1.5em" }}>
              There are currently no results to display. Please check back
              later.
            </p>
          </Col>
        )}
      </Row>
    </Drawer>
  );
};

export default KataFinalResultDrawer;
