import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Select,
  Skeleton,
  Empty,
  Button,
  Input
} from "antd";
import moment from "moment";
import { useParams, useHistory, Link } from "react-router-dom";
import { notify, ShowTotal, debounce } from "../../../../utils/helper";
import expense from "../../../../services/expense";
import CreateExpenseModal from "./CreateExpenseModal";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { SearchOutlined } from "@ant-design/icons";
import UpdateExpenseModal from "./UpdateExpenseModal";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
import DeleteConfirionModal from "../../../modal/DeleteConfirionModal";
import { CustomTable } from "../Styled";

const { Option } = Select;

const Expense = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [allExpense, setAllExpense] = useState([]);
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [selectedExpense, setSelectedExpense] = useState({})
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState("");
  const history = useHistory();
  const query = useParams();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [givenPassword, setGivenPassword] = useState("");
  const [selectedID, setSelectedID] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");

  const getAllExpense = async (searchText = "") => {
    try {
      setLoading(true);
      const res = await expense.getExpenseList(
        pagination?.size,
        pagination?.currentPage,
        searchText,
        sortingColumn,
        sortingOrder,
      );
      setAllExpense(res?.data);
      setCount(res?.data?.length)
      setLoading(false);
    } catch (error) {}
  };


  const deleteExpense = async (id) => {
    try {
      const res = await expense.deleteExpenses({
        expense: id ? [id] : selectedRowKeys,
        password: givenPassword,
      });
      notify("success", "Expense Deleted Successfully.");
      setSelectedRowKeys([]);
      getAllExpense();
      setIsModalOpen(false)
    } catch (error) {
      notify("error", error.data.message)
    }
  };

  const handleDownload = async (id, fileName) => {
    try {
      const res = await expense.downloadAttachment(id, { fileName: fileName });

      window.open(res?.data, "_blank");

      notify("success", "File downloaded successfully.");
    } catch (err) {}
  };

  const handleSearch = debounce((value) => {
    getAllExpense(value);
  },500);

  useEffect(() => {
    getAllExpense();
  }, [
    pagination?.size,
    pagination?.currentPage,
    sortingColumn,
    sortingOrder,
  ])
  
  const expenseColumns = [
    {
      title: "Expense Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      sorter: {
        compare: (a, b) => a.nameToSort.localeCompare(b.nameToSort),
        // multiple: 3,
      },
    },
    {
      title: "Expense Cost",
      dataIndex: "cost",
      key: "cost",
      align: "center",
      sorter: {
        compare: (a, b) => a.cost.localeCompare(b.cost),
        // multiple: 3,
      },
    },
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      align: "center",
      sorter: {
        compare: (a, b) => a.eventName.localeCompare(b.eventName),
        // multiple: 3,
      },
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: {
        compare: (a, b) => a.createdAt.localeCompare(b.createdAt),
        // multiple: 3,
      },
    },

    {
      title: "Attachment",
      dataIndex: "attachment",
      key: "attachment",
      align: "center",
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      align: "center",
      width: 250,
    },
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      width: 100,
    },
  ];

  let data = [];
  for (let item of allExpense?.response?.records || []) {
    data.push({
      key: item?._id,
      name: <Link>{item?.name}</Link>,
      nameToSort: item?.name || "-",
      eventName:item?.event?.name ?item?.event?.name:"-",
      cost: `\u20B9 ${(item?.cost ? item.cost.toFixed(2) : '0.00')}`,      
      createdAt: moment(item?.createdAt).format("DD-MMM-YYYY"),
      comments: item?.description ? item?.description:"-",
      attachment: item?.attachment ? (
        <a onClick={() => handleDownload(item?._id, item?.attachment)}>
          View Attachment
        </a>
      ) : (
        "No Attachment"
      ),
      actions: (
        <span>
          <FaEdit
            onClick={() => {
              setIsUpdateModalVisible(true)
              setSelectedExpense(item)
            }
            } 
            size={15}
            style={{ marginRight: 8, cursor: "pointer" }}
          />
          <MdDelete
            onClick={() => {
              setSelectedID(item?._id)
              setIsModalOpen(true)
            }}
            size={18}
            style={{ cursor: "pointer" }}
          />
        </span>
      ),
    });
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
    handleChange: (a, b, c) => {},
  };

  const onChange = (pagination, filters, sorter, extra) => {
    setSortingOrder(sorter?.order);
    setSortingColumn(sorter?.column?.dataIndex);
  };

  useEffect(() => {
    getAllExpense();
    dispatch(
      setBreadcrumb({
        title: "All Expenses",
        subtitle: "",
        url: "",
      })
    );
  }, []);

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 330px)", x: true } : null;

  return (
    <>
      {isCreateModalVisible && (
        <CreateExpenseModal
          open={isCreateModalVisible}
          setOpen={setIsCreateModalVisible}
          getAllExpense={getAllExpense}
        />
      )}

      {isUpdateModalVisible && (
        <UpdateExpenseModal
          open={isUpdateModalVisible}
          setOpen={setIsUpdateModalVisible}
          getAllExpense={getAllExpense}
          selectedExpense={selectedExpense}
        />
      )}
      <DeleteConfirionModal
                isOpen={isModalOpen}
                setOpen={setIsModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={()=>deleteExpense(selectedID)}
                messageText={
                  "Please confirm it by entering your password to take the action."
                }
                password={givenPassword}
                setPassword={setGivenPassword}
              />

      <Row gutter={[0, 24]}>
      <Col span={24} className="header-control d-flex justify-content-between align-items-center">
        <Col span={12} className="d-flex justify-content-end">
          {selectedRowKeys?.length > 0 && (
            <div style={{ width: "150px" }}>
              <Button
                style={{fontSize:"13px"}}
                type="danger"
                role="button"
                onClick={() => {
                  setSelectedID(null)
                  setIsModalOpen(true)
                }}
              >
                Delete Expense
              </Button>
            </div>
          )}
          <div style={{ width: "150px" }}>
            <button
              class="button-29"
              role="button"
              onClick={() => setIsCreateModalVisible(true)}
            >
              Add Expense
            </button>
          </div>
        </Col>
        <Input
          className="header-search"
          placeholder="Search here..."
          prefix={<SearchOutlined  style={{fontSize:"18px", color:"gray"}}/>}
          style={{ width: "300px" }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Col>

        <Col span={24}>
        <CustomTable>
          <Table
            columns={expenseColumns}
            dataSource={data}
            onChange={onChange}
            rowSelection={rowSelection}
            scroll={scroll}
            locale={{
              emptyText: loading ? <Skeleton round active={true} /> : <Empty />,
            }}
            pagination={{
              defaultPageSize: 10,
              defaultCurrent: 1,
              showSizeChanger: true,
              pageSizeOptions: ["25", "50", "100"],
              total: count,
              onChange: (currentPage, size) => {
                setPagination({ size, currentPage });
              },
          
              showTotal: (total, range) => (
                <ShowTotal
                  selectedRowKeys={selectedRowKeys}
                  total={total}
                  currentPageItem={range[1] - range[0] + 1}
                  ranged={range}
                />
              ),
            }}
          />
          </CustomTable>
        </Col>
      </Row>
    </>
  );
};

export default Expense;
